import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  loading: true,
  pageOffset: 0,
  pageSize: 20,
  logs: [],
  sort: [],
  filter: [],
  totalPages: 0,
  isDownloadingCsv: false
};
export default (overrideState => createProxy(initialState, overrideState));