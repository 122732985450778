export const channels = ['email', 'slack', 'sms', 'webhook'];
export const channels2Platform = {
  sms: {
    title: 'connectivity.sms',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some;
      return (_some = data == null ? void 0 : data.some(({
        enabled
      }) => enabled)) != null ? _some : false;
    },
    image: 'sms'
  },
  email: {
    title: 'common.email',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some2;
      return (_some2 = data == null ? void 0 : data.some(({
        enabled
      }) => enabled)) != null ? _some2 : false;
    },
    image: 'email'
  },
  slack: {
    title: 'connectivity.slack',
    events: data => {
      var _slackSubscriptions;
      return (data == null ? void 0 : (_slackSubscriptions = data.slackSubscriptions) == null ? void 0 : _slackSubscriptions.length) || 0;
    },
    isActive: data => !!(data != null && data.slackSubscriptions.some(({
      isActive
    }) => isActive)),
    image: 'slack'
  },
  webhook: {
    title: 'connectivity.webhook',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some3;
      return (_some3 = data == null ? void 0 : data.some(({
        isActive
      }) => isActive)) != null ? _some3 : false;
    },
    image: 'webhook'
  }
};