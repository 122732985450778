import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["eventKey"],
      _excluded2 = ["eventKey"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class ConnectivityApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getSlackConfiguration = () => {
      return this.get(`${urls.integrations.configurations.v1}/slack`);
    };

    this.getSlackScope = () => {
      return this.get(`${urls.integrations.configurations.v1}/slack/applications`);
    };

    this.getSlackChannels = () => {
      return this.get(`${urls.integrations.configurations.v1}/slack/channels`);
    };

    this.postSlackConfiguration = data => {
      if (data.id) {
        return this.patch(`${urls.integrations.configurations.v1}/slack/subscription/${data.id}`, data);
      }

      return this.post(`${urls.integrations.configurations.v1}/slack/subscriptions`, data);
    };

    this.deleteSlackConfiguration = data => {
      return this.delete(`${urls.integrations.configurations.v1}/slack/subscriptions/${data.id}`);
    };

    this.postSlackCode = code => {
      return this.post(`${urls.integrations.configurations.v1}/slack/applications/registrations`, {
        code
      });
    };

    this.getEmailConfiguration = () => {
      return this.get(`${urls.integrations.configurations.v1}/emails`);
    };

    this.postEmailConfiguration = _ref => {
      let {
        eventKey
      } = _ref,
          data = _objectWithoutPropertiesLoose(_ref, _excluded);

      return this.post(`${urls.integrations.configurations.v1}/emails/${eventKey}`, data);
    };

    this.patchEmailConfiguration = ({
      eventKey,
      enabled
    }) => {
      return this.patch(`${urls.integrations.configurations.v1}/emails/${eventKey}`, {
        enabled
      });
    };

    this.deleteEmailSubscriptions = (eventKey, subscriptionId) => {
      return this.delete(`${urls.integrations.configurations.v1}/emails/${eventKey}/subscriptions/${subscriptionId}`);
    };

    this.putEmailSubscriptions = (subscriptionId, eventKey, data) => {
      return this.put(`${urls.integrations.configurations.v1}/emails/${eventKey}/subscriptions/${subscriptionId}`, data);
    };

    this.deleteEmailConfiguration = eventKey => {
      return this.delete(`${urls.integrations.configurations.v1}/emails/${eventKey}`);
    };

    this.getSMSConfiguration = () => {
      return this.get(`${urls.integrations.configurations.v2}/sms`);
    };

    this.postSMSConfiguration = _ref2 => {
      let {
        eventKey
      } = _ref2,
          data = _objectWithoutPropertiesLoose(_ref2, _excluded2);

      return this.post(`${urls.integrations.configurations.v2}/sms/${eventKey}`, data);
    };

    this.patchSMSConfiguration = ({
      eventKey,
      enabled
    }) => {
      return this.patch(`${urls.integrations.configurations.v2}/sms/${eventKey}`, {
        enabled
      });
    };

    this.deleteSMSSubscriptions = (eventKey, subscriptionId) => {
      return this.delete(`${urls.integrations.configurations.v2}/sms/${eventKey}/subscriptions/${subscriptionId}`);
    };

    this.putSMSSubscriptions = (subscriptionId, eventKey, data) => {
      return this.put(`${urls.integrations.configurations.v2}/sms/${eventKey}/subscriptions/${subscriptionId}`, data);
    };

    this.deleteSMSConfiguration = eventKey => {
      return this.delete(`${urls.integrations.configurations.v2}/sms/${eventKey}`);
    };

    this.getWebhooksConfigurations = () => {
      return this.get(urls.webhooks.v1);
    };

    this.postWebhooksConfiguration = data => {
      if (data._id) {
        return this.patch(`${urls.webhooks.v1}/${data._id}`, data);
      } else {
        return this.post(`${urls.webhooks.v1}/custom`, data);
      }
    };

    this.deleteWebhooksConfiguration = id => {
      return this.delete(`${urls.webhooks.v1}/${id}`);
    };

    this.getWebhookLog = (id, offset = 0, limit = 10) => {
      const query = new URLSearchParams({
        id,
        offset: `${offset}`,
        limit: `${limit}`
      });
      return this.get(`${urls.webhooks.v1}/logs/?${query.toString()}`);
    };

    this.postWebhookTest = data => {
      return this.post(`${urls.webhooks.v1}/test`, data);
    };

    this.postWebhookRetry = id => {
      return this.post(`${urls.webhooks.v1}/logs/${id}/retries`);
    };

    this.getCategories = () => {
      return this.get(`${urls.events.configurations.v1}/categories`);
    };

    this.getChannelMaps = channels => {
      return this.get(urls.events.configurations.v1, {
        channels
      });
    };
  }

}
export default new ConnectivityApi('default');