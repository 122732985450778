import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["email"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class PaymentMethodsApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getPaymentMethods = async () => {
      return this.get(urls.subscriptions.billing.paymentMethods.v1);
    };

    this.getPaymentMethod = async paymentMethodId => {
      return this.get(`${urls.subscriptions.billing.paymentMethods.v1}/${paymentMethodId}`);
    };

    this.updatePaymentMethodBillingDetails = async (paymentMethodId, _ref) => {
      let {
        email
      } = _ref,
          address = _objectWithoutPropertiesLoose(_ref, _excluded);

      return this.patch(`${urls.subscriptions.billing.paymentMethods.v1}/${paymentMethodId}/billing-details`, {
        email,
        address
      });
    };
  }

}