import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setSessionsState = state => {
    Object.assign(store.auth.sessionsState, state);
  };
  const resetSessionsState = () => {
    deepResetState(store, ['auth', 'sessionsState'], initialState);
  };
  const loadUserSessions = async () => {
    setSessionsState({
      loading: true,
      error: null
    });
    try {
      const sessions = await api.auth.getCurrentUserSessions();
      setSessionsState({
        sessions,
        loading: false
      });
    } catch (e) {
      setSessionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadCurrentUserSession = async () => {
    setSessionsState({
      loading: true,
      error: null
    });
    try {
      const session = await api.auth.getCurrentUserSession();
      setSessionsState({
        currentSession: session,
        loading: false
      });
    } catch (e) {
      setSessionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const deleteUserSession = async payload => {
    const {
      id
    } = payload;
    setSessionsState({
      loading: true,
      error: null
    });
    try {
      await api.auth.deleteSessionForUser(id);
      await loadUserSessions();
    } catch (e) {
      setSessionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const deleteAllUserSessions = async () => {
    setSessionsState({
      loading: true,
      error: null
    });
    try {
      await api.auth.deleteAllSessionsForUser();
      await loadUserSessions();
    } catch (e) {
      setSessionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const revokeUserSessions = async payload => {
    const {
      callback,
      userId
    } = payload;
    setSessionsState({
      loading: true,
      error: null
    });
    try {
      await api.auth.revokeSessionsForUser(userId);
      setSessionsState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSessionsState({
        error: errorHandler(e),
        loading: false
      });
      callback == null ? void 0 : callback(false);
    }
  };
  return {
    setSessionsState,
    resetSessionsState,
    loadUserSessions,
    loadCurrentUserSession,
    deleteUserSession,
    deleteAllUserSessions,
    revokeUserSessions
  };
});