import { initialState } from './state';
import { deepResetState, errorHandler } from '../../helpers';
export default ((store, api, sharedActions) => {
  const setVendorPublicConfigState = state => {
    Object.assign(store.subscriptions.vendorPublicConfig, state);
  };
  const resetVendorPublicConfigState = () => {
    deepResetState(store, ['subscriptions', 'vendorPublicConfig'], initialState);
  };
  const setVendorPublicConfigError = error => {
    Object.assign(store.subscriptions.vendorPublicConfig, {
      error,
      loading: false,
      fetching: false
    });
  };
  const setVendorPublicConfigLoading = loading => {
    Object.assign(store.subscriptions.vendorPublicConfig, {
      error: null,
      loading
    });
  };
  const loadVendorPublicConfiguration = async () => {
    setVendorPublicConfigLoading(true);
    try {
      // @ts-ignore TODO: fix rest api types
      const vendorPublicConfig = await api.subscriptions.getVendorPublicConfigurations();
      setVendorPublicConfigState({
        fetching: false,
        loading: false,
        vendorPublicConfig
      });
    } catch (e) {
      setVendorPublicConfigError(errorHandler(e));
    }
  };
  return {
    setVendorPublicConfigState,
    resetVendorPublicConfigState,
    setVendorPublicConfigError,
    setVendorPublicConfigLoading,
    loadVendorPublicConfiguration
  };
});