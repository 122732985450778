import { initialState } from './state';
import { errorHandler, delay, deepResetState } from '../../helpers';
import { ContextHolder } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const setUnlockAccountState = state => {
    Object.assign(store.auth.unlockAccountState, state);
  };
  const resetUnlockAccountState = () => {
    deepResetState(store, ['auth', 'activateAccountState'], initialState);
  };
  const unlockAccount = async _payload => {
    const {
      token
    } = _payload;
    const onRedirectTo = ContextHolder.for(store.root.appName).onRedirectTo;
    const routes = store.auth.routes;
    setUnlockAccountState({
      loading: true
    });
    try {
      await api.users.unlockMe({
        token
      });
      setUnlockAccountState({
        loading: false,
        error: undefined,
        unlockAccountSuccess: true
      });
      await delay(1000);
      onRedirectTo(routes.loginUrl);
    } catch (e) {
      setUnlockAccountState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  return {
    setUnlockAccountState,
    resetUnlockAccountState,
    unlockAccount
  };
});