import { initialState } from './state';
import { deepResetState } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setAuditsMetadataState = state => {
    Object.assign(store.auditLogs.auditsMetadataState, state);
  };
  const resetAuditsMetadataState = () => {
    deepResetState(store, ['auditLogs', 'auditsMetadataState'], initialState);
  };
  const loadAuditsMetadata = async () => {
    setAuditsMetadataState({
      loading: true,
      error: null
    });
    try {
      const {
        properties
      } = await api.metadata.getAuditsMetadata();
      setAuditsMetadataState({
        columns: properties,
        loading: false
      });
    } catch (e) {
      setAuditsMetadataState({
        error: e,
        loading: false
      });
    }
  };
  return {
    setAuditsMetadataState,
    resetAuditsMetadataState,
    loadAuditsMetadata
  };
});