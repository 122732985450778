import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dataFilters"],
      _excluded2 = ["templateId", "dataFilters"],
      _excluded3 = ["dataFilters"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class ReportsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getReports = async body => {
      return this.get(`${urls.reports.service.v2}/tenant-reports`, body);
    };

    this.getReport = async body => {
      return this.get(`${urls.reports.service.v2}/${body.id}/tenant-reports`);
    };

    this.renderReport = async _ref => {
      let {
        dataFilters
      } = _ref,
          body = _objectWithoutPropertiesLoose(_ref, _excluded);

      const html = await this.get(`${urls.reports.trigger.v1}/preview-report`, _extends({}, body, {
        dataFilters: btoa(JSON.stringify(dataFilters || {})),
        responseType: 'html'
      }), {
        responseType: 'plain'
      });
      return {
        html
      };
    };

    this.scheduleReport = async _ref2 => {
      let {
        templateId,
        dataFilters
      } = _ref2,
          body = _objectWithoutPropertiesLoose(_ref2, _excluded2);

      return this.post(`${urls.reports.service.v2}/${templateId}/tenant-reports`, _extends({}, body, {
        dataFilters: btoa(JSON.stringify(dataFilters || {}))
      }));
    };

    this.downloadReport = async _ref3 => {
      let {
        dataFilters
      } = _ref3,
          body = _objectWithoutPropertiesLoose(_ref3, _excluded3);

      const blob = await this.get(`${urls.reports.trigger.v1}/preview-report`, _extends({}, body, {
        dataFilters: btoa(JSON.stringify(dataFilters || {}))
      }), {
        responseType: 'blob'
      });
      let contentType = 'text/html';
      let contentExt = 'html';

      if (body.responseType === 'pdf') {
        contentType = 'application/pdf';
        contentExt = 'pdf';
      } else if (body.responseType === 'image') {
        contentType = 'image/jpeg';
        contentExt = 'jpg';
      }

      const newBlob = new Blob([blob], {
        type: contentType
      });
      const fileURL = URL.createObjectURL(newBlob);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `report_${body.name || ''}.${contentExt}`);
      tempLink.click();
      setTimeout(tempLink.remove.bind(tempLink));
    };

    this.sendReport = async body => {
      return this.post(`${urls.reports.trigger.v1}/tenant-reports`, body);
    };
  }

}
export default new ReportsApi('default');