import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  loaders: {},
  pageOffset: 0,
  pageSize: 20,
  errors: {},
  users: [],
  allUsers: [],
  roles: [],
  permissions: [],
  totalPages: 0,
  shouldShowSubTenantUsersIfReseller: false,
  allUsersQueryParams: {
    _filter: null,
    _offset: 0,
    _limit: 20,
    _order: 'DESC',
    _sortBy: 'name'
  },
  addUserDialogState: {
    loading: false,
    open: false
  },
  deleteUserDialogState: {
    loading: false,
    open: false
  },
  lockUserDialogState: {
    loading: false,
    open: false
  },
  enableUserDialogState: {
    loading: false,
    open: false
  },
  disableUserDialogState: {
    loading: false,
    open: false
  }
};
export default (overrideState => createProxy(initialState, overrideState));