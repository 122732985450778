import _extends from "@babel/runtime/helpers/esm/extends";
// update node is sub account access state - recursive function to update the subAccount access state of a node in the tree
export const updateNodeSubAccountAccessType = (treeNode, id, subAccountAccessType) => {
  var _treeNode$children$ma, _treeNode$children;
  if (treeNode.tenantId === id) {
    return _extends({}, treeNode, {
      subAccountAccessType: subAccountAccessType
    });
  }
  return _extends({}, treeNode, {
    children: (_treeNode$children$ma = treeNode == null ? void 0 : (_treeNode$children = treeNode.children) == null ? void 0 : _treeNode$children.map(child => updateNodeSubAccountAccessType(child, id, subAccountAccessType))) != null ? _treeNode$children$ma : []
  });
};