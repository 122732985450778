import { buildVendorActions as originalBuildVendorActions } from '../../vendor';
import { mockActionsExpect } from '../helpers';
import { delay } from '../../helpers';
export const buildVendorActions = (store, api, actions) => {
  const originalActions = originalBuildVendorActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setVendorState', 'resetVendorState']);
  mockedActions.loadVendorPublicInfo = async () => {
    try {
      mockedActions.setVendorState({
        whiteLabelMode: false
      });
      await delay();
    } catch (e) {
      console.error('failed to getVendorPublicInfo - ', e);
    }
  };
  mockedActions.loadVendorPublicConfiguration = mockedActions.loadVendorPublicInfo;
  return mockedActions;
};