import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class VendorPublicConfigApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getVendorPublicConfigurations = async () => {
      return this.get(urls.subscriptions.billing.configurations.vendorPublicConfigurations.v1);
    };
  }

}