import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  apiTokenType: null,
  searchValue: '',
  showAddTokenDialog: false,
  createdByUserIdColumn: 'show',
  deleteTokenDialog: {
    open: false,
    clientId: ''
  },
  successDialog: {
    open: false,
    secret: '',
    clientId: ''
  },
  loaders: {},
  apiTokensDataTenant: [],
  apiTokensDataUser: [],
  roles: [],
  permissions: [],
  errors: {}
};
export default (overrideState => createProxy(initialState, overrideState));