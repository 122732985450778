import { buildAuthActions } from './auth-mocks';
import { buildAuditsActions } from './audits-mocks';
import { buildConnectivityActions } from './connectivity-mocks';
import { buildSubscriptionsActions } from './subscriptions-mocks';
import { buildVendorActions } from './vendor-mocks';
const buildMockActions = (store, api, actions, snapshotAuthState) => {
  const {
    authActions,
    authStateActions
  } = buildAuthActions(store, api, actions, snapshotAuthState);
  const connectivityActions = buildConnectivityActions(store, api, actions);
  const [subscriptionsActions, subscriptionsStateActions] = buildSubscriptionsActions(store, api, actions);
  const vendorActions = buildVendorActions(store, api, actions);
  const auditsActions = buildAuditsActions(store, api, actions);
  return {
    authActions,
    authStateActions,
    connectivityActions,
    subscriptionsActions,
    subscriptionsStateActions,
    vendorActions,
    auditsActions
  };
};
export default buildMockActions;