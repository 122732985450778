import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setPlansState = state => {
    Object.assign(store.subscriptions.plans, state);
  };
  const resetPlansState = () => {
    deepResetState(store, ['subscriptions', 'plans'], initialState);
  };
  const setPlansError = error => {
    setPlansState({
      loading: false,
      fetching: false,
      error
    });
  };
  const loadPlans = async () => {
    setPlansState({
      loading: true
    });
    try {
      const products = await api.subscriptions.getSubscriptionPlans();
      const plans = products.map(item => {
        var _item$price, _item$price2;
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          price: ((_item$price = item.price) == null ? void 0 : _item$price.amount) || 0,
          currency: ((_item$price2 = item.price) == null ? void 0 : _item$price2.currency) || 'usd',
          recurringInterval: 'month',
          slug: item.slug
        };
      });
      setPlansState({
        fetching: false,
        loading: false,
        plans
      });
    } catch (e) {
      setPlansError(errorHandler(e));
    }
  };
  return {
    setPlansState,
    resetPlansState,
    loadPlans
  };
});