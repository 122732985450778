// TODO: import from after adding teams State

export let GroupsStateKeys;
(function (GroupsStateKeys) {
  GroupsStateKeys["CREATE_GROUP"] = "CREATE_GROUP";
  GroupsStateKeys["GET_GROUP"] = "GET_GROUP";
  GroupsStateKeys["GET_GROUPS"] = "GET_GROUPS";
  GroupsStateKeys["DELETE_GROUP"] = "DELETE_GROUP";
  GroupsStateKeys["UPDATE_GROUP"] = "UPDATE_GROUP";
  GroupsStateKeys["ADD_USERS_TO_GROUP"] = "ADD_USERS_TO_GROUP";
  GroupsStateKeys["DELETE_USERS_FROM_GROUP"] = "DELETE_USERS_FROM_GROUP";
  GroupsStateKeys["ADD_ROLES_TO_GROUP"] = "ADD_ROLES_TO_GROUP";
  GroupsStateKeys["DELETE_ROLES_FROM_GROUP"] = "DELETE_ROLES_FROM_GROUP";
  GroupsStateKeys["GET_GROUP_CONFIGURATION"] = "GET_GROUP_CONFIGURATION";
  GroupsStateKeys["UPDATE_GROUP_CONFIGURATION"] = "UPDATE_GROUP_CONFIGURATION";
  GroupsStateKeys["GET_TEAM_USERS"] = "GET_TEAM_USERS";
})(GroupsStateKeys || (GroupsStateKeys = {}));
//TODO: import from rest-api
export let GroupRelations;
(function (GroupRelations) {
  GroupRelations["roles"] = "roles";
  GroupRelations["users"] = "users";
  GroupRelations["rolesAndUsers"] = "rolesAndUsers";
})(GroupRelations || (GroupRelations = {}));