import { PaginationOrderEnum, SortByEnum } from '@frontegg/rest-api';
import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  loaders: {},
  errors: {},
  accounts: {
    tenantId: '',
    children: []
  },
  accountsQueryParams: {
    _limit: 50,
    _offset: 0,
    _filter: '',
    _subTenantId: ''
  },
  searchAccountsData: [],
  rootAccount: {
    tenantId: ''
  },
  selectedAccount: {
    tenantId: '',
    userJwt: {
      accessToken: '',
      expiresIn: 0,
      expires: ''
    },
    users: [],
    usersQueryParams: {
      _offset: 0,
      _limit: 10,
      _order: PaginationOrderEnum.DESC,
      _sortBy: SortByEnum.name
    },
    usersPageOffset: 0,
    accountSettings: {}
  }
};
export default (overrideState => createProxy(initialState, overrideState));