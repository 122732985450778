import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"];
import { SecurityCenterStateKeys } from './interfaces';
import { GetUsersFilterPreset } from '@frontegg/rest-api';
import { deepResetState, errorHandler } from '../../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * This function gets users as we got from getUsersV3,
   * and returns the combined object of those users with their roles.
   * @param users - array of users
   * @private
   */
  const __getUsersWithRoles = async users => {
    const usersIds = users.map(user => user.id);
    const allRoles = await api.roles.getRoles();
    const usersRoles = await api.users.getUsersRoles({
      ids: usersIds
    });
    const usersWithRoles = users.map(user => {
      var _usersRoles$find, _ref;
      const userRolesIds = usersRoles == null ? void 0 : (_usersRoles$find = usersRoles.find(role => role.userId === user.id)) == null ? void 0 : _usersRoles$find.roleIds;
      return _extends({}, user, {
        roles: (_ref = userRolesIds == null ? void 0 : userRolesIds.map(roleId => {
          var _allRoles$find;
          return (_allRoles$find = allRoles == null ? void 0 : allRoles.find(role => role.id === roleId)) != null ? _allRoles$find : [];
        })) != null ? _ref : []
      });
    });
    return usersWithRoles;
  };

  /**
   * This function is doing the logic needed in order to display a table in a generic way.
   * It calls getUsersV3 based on the params, combined the response of users with roles,
   * and load it to the correct state by the updateStateKey.
   * @param key - key for loaders / errors
   * @param tableState - the state of the specific table
   * @param updateStateKey - the key of the specific table to be updated in the state
   * @param _offset - page offset
   * @param _limit - limit per page
   * @param _preset - preset to be send according to the table type
   * @param _email - email input as searched
   *
   * @private
   */
  const __loadUsersTableSecurityCenter = async payload => {
    var _ref2, _tableState$queryPara, _ref3, _tableState$queryPara2;
    const {
      key,
      tableState,
      updateStateKey,
      _offset: offset,
      _limit: limit,
      _preset,
      _email,
      _maxInactiveSeconds
    } = payload;
    setSecurityCenterStateError({
      key,
      value: false
    });
    setSecurityCenterStateLoader({
      key,
      value: true
    });
    const _offset = (_ref2 = offset != null ? offset : tableState == null ? void 0 : (_tableState$queryPara = tableState.queryParams) == null ? void 0 : _tableState$queryPara._offset) != null ? _ref2 : 0;
    const _limit = (_ref3 = limit != null ? limit : tableState == null ? void 0 : (_tableState$queryPara2 = tableState.queryParams) == null ? void 0 : _tableState$queryPara2._limit) != null ? _ref3 : 10;
    const tableStateQueryParams = _extends({
      _offset,
      _limit,
      _includeSubTenants: false,
      _preset,
      _maxInactiveSeconds
    }, !!_email && {
      _email
    });
    try {
      const {
        items: usersItems,
        _metadata: {
          totalItems,
          totalPages
        }
      } = await api.users.getUsersV3(tableStateQueryParams);
      const partialTableObj = {
        totalUsersItems: totalItems,
        totalUsersPages: totalPages,
        usersPageOffset: _offset,
        queryParams: _extends({
          _offset,
          _limit,
          _maxInactiveSeconds
        }, !!_email && {
          _email
        })
      };
      if (usersItems.length) {
        const usersWithRoles = await __getUsersWithRoles(usersItems);
        actions.setSecurityCenterState({
          [updateStateKey]: _extends({
            users: usersWithRoles
          }, partialTableObj)
        });
      } else {
        setSecurityCenterState({
          [updateStateKey]: _extends({
            users: []
          }, partialTableObj)
        });
      }
    } catch (e) {
      setSecurityCenterStateError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setSecurityCenterStateLoader({
        key,
        value: false
      });
    }
  };
  const setSecurityCenterState = state => {
    Object.assign(store.auth.securityCenterState, state);
  };
  const resetSecurityCenterState = () => {
    deepResetState(store, ['auth', 'securityCenterState'], initialState);
  };
  const setSecurityCenterStateLoader = payload => {
    Object.assign(store.auth.securityCenterState, {
      loaders: _extends({}, store.auth.securityCenterState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setSecurityCenterStateError = payload => {
    Object.assign(store.auth.securityCenterState, {
      errors: _extends({}, store.auth.securityCenterState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.securityCenterState.loaders, {
        [payload.key]: false
      })
    });
  };
  const loadRecommendations = async () => {
    const key = SecurityCenterStateKeys.RECOMMENDATIONS;
    setSecurityCenterStateLoader({
      key,
      value: true
    });
    try {
      var _scoring$score;
      const {
        recommendations,
        scoring
      } = await api.securityCenter.getRecommendations();
      setSecurityCenterState({
        recommendations: recommendations.items,
        score: (_scoring$score = scoring == null ? void 0 : scoring.score) != null ? _scoring$score : 0
      });
    } catch (e) {
      setSecurityCenterStateError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setSecurityCenterStateLoader({
        key,
        value: false
      });
    }
  };
  const loadInsights = async () => {
    const key = SecurityCenterStateKeys.INSIGHTS;
    setSecurityCenterStateLoader({
      key,
      value: true
    });
    try {
      const {
        insights
      } = await api.securityCenter.getInsights();
      setSecurityCenterState({
        insights: insights.items
      });
    } catch (e) {
      setSecurityCenterStateError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setSecurityCenterStateLoader({
        key,
        value: false
      });
    }
  };
  const sendResetBreachedPasswordEmail = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    const key = SecurityCenterStateKeys.SEND_BREACHED_PASSWORD_EMAIL;
    setSecurityCenterStateError({
      key,
      value: false
    });
    setSecurityCenterStateLoader({
      key,
      value: true
    });
    try {
      await api.auth.forgotPassword(payload);
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSecurityCenterStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSecurityCenterStateLoader({
        key,
        value: false
      });
    }
  };
  const sendBulkResetBreachedPasswordEmails = async payload => {
    const {
      callback
    } = payload;
    const key = SecurityCenterStateKeys.SEND_BULK_RESET_BREACHED_PASSWORD_EMAILS;
    setSecurityCenterStateError({
      key,
      value: false
    });
    setSecurityCenterStateLoader({
      key,
      value: true
    });
    try {
      await api.users.sendResetBreachedPasswordEmails();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSecurityCenterStateError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setSecurityCenterStateLoader({
        key,
        value: false
      });
    }
  };
  const loadBreachedPasswordUsers = async payload => {
    var _store$auth$securityC;
    const key = SecurityCenterStateKeys.BREACHED_PASSWORD_USERS_TABLE;
    const breachedPasswordUsersTable = (_store$auth$securityC = store.auth.securityCenterState) == null ? void 0 : _store$auth$securityC.breachedPasswordUsersTable;
    await __loadUsersTableSecurityCenter(_extends({
      key,
      updateStateKey: 'breachedPasswordUsersTable',
      _preset: GetUsersFilterPreset.BREACHED_PASSWORDS,
      tableState: breachedPasswordUsersTable
    }, payload));
  };
  const loadUnenrolledMfaUsers = async payload => {
    var _store$auth$securityC2;
    const key = SecurityCenterStateKeys.UNENROLLED_MFA_USERS_TABLE;
    const unenrolledMfaUsersTable = (_store$auth$securityC2 = store.auth.securityCenterState) == null ? void 0 : _store$auth$securityC2.unenrolledMfaUsersTable;
    await __loadUsersTableSecurityCenter(_extends({
      key,
      updateStateKey: 'unenrolledMfaUsersTable',
      _preset: GetUsersFilterPreset.MFA_UNENROLLED,
      tableState: unenrolledMfaUsersTable
    }, payload));
  };
  const loadInactiveUsers = async payload => {
    var _store$auth$securityC3;
    const key = SecurityCenterStateKeys.INACTIVE_USERS_TABLE;
    const inactiveUsersTable = (_store$auth$securityC3 = store.auth.securityCenterState) == null ? void 0 : _store$auth$securityC3.inactiveUsersTable;
    await __loadUsersTableSecurityCenter(_extends({
      key,
      updateStateKey: 'inactiveUsersTable',
      _preset: GetUsersFilterPreset.INACTIVE,
      tableState: inactiveUsersTable
    }, payload));
  };
  return {
    setSecurityCenterState,
    resetSecurityCenterState,
    setSecurityCenterStateLoader,
    setSecurityCenterStateError,
    loadRecommendations,
    loadInsights,
    sendResetBreachedPasswordEmail,
    sendBulkResetBreachedPasswordEmails,
    loadBreachedPasswordUsers,
    loadUnenrolledMfaUsers,
    loadInactiveUsers
  };
});