import _extends from "@babel/runtime/helpers/esm/extends";
import { mockActionsExpect } from '../../helpers';
import { invoicesMock } from '../dummy';
import { delay } from '../../../helpers';
import { buildInvoicesActions } from '../../../subscriptions/Billing/Invoices';
export default ((store, api, actions) => {
  const originalActions = buildInvoicesActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setBillingInvoiceState', 'resetBillingInvoiceState']);
  mockedActions.loadBillingInvoices = async () => {
    mockedActions.setBillingInvoiceState({
      loading: true
    });
    await delay(500);
    const selectPlanTitle = 'Premium';
    mockedActions.setBillingInvoiceState({
      loading: false,
      fetching: false,
      invoices: invoicesMock.map(invoice => _extends({}, invoice, {
        selectedPlan: selectPlanTitle,
        paymentDate: new Date(Date.parse(invoice.paymentDate)),
        totalAmount: +((invoice.totalAmount || 0) / 100).toFixed(2)
      }))
    });
  };
  mockedActions.loadInvoices = mockedActions.loadBillingInvoices;
  return mockedActions;
});