import { RolesStateKeys } from '../../auth';
import { errorHandler } from '../../helpers';
import { buildRolesActions } from '../../auth/RolesState';
import { rolesAdminViewerDemo } from '../dummy';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildRolesActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setRolesState', 'setRolesStateError', 'setRolesStateLoader', 'resetRolesState']);
  mockedActions.loadRolesAndPermissions = async payload => {
    var _payload$silentLoadin;
    const key = RolesStateKeys.LOAD_ROLES;
    mockedActions.setRolesStateLoader({
      key,
      value: !((_payload$silentLoadin = payload == null ? void 0 : payload.silentLoading) != null ? _payload$silentLoadin : false)
    });
    try {
      mockedActions.setRolesState({
        roles: rolesAdminViewerDemo
      });
    } catch (e) {
      mockedActions.setRolesStateError({
        key,
        value: errorHandler(e)
      });
    } finally {
      mockedActions.setRolesStateLoader({
        key,
        value: false
      });
    }
  };
  return mockedActions;
});