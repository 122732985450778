import _extends from "@babel/runtime/helpers/esm/extends";
import buildAcceptInvitationActions from './acceptInvitationActions.mocks';
import buildAccountSettingsActions from './accountSettingsActions.mocks';
import buildActivateAccountActions from './activateAccountActions.mocks';
import buildAllAccountsActions from './allAccountsActions.mocks';
/* contains reducers only no need to mock */
import { buildAllAccountsDialogActions } from '../../auth/MSP';
import buildApiTokensActions from './apiTokensActions.mocks';
import buildApplicationsActions from './applicationsActions.mocks';
import buildCustomLoginActions from './customLoginActions.mocks';
import buildEntitlementsActions from './entitlementsActions.mocks';
import buildForgotPasswordActions from './forgotPasswordActions.mocks';
import buildGroupsActions from './groupsActions.mocks';
/* contains reducers only no need to mock */
import { buildGroupsDialogsActions } from '../../auth/GroupsDialogsState';
import buildImpersonateActions from './impersonateActions.mocks';
import buildLoginActions from './loginActions.mocks';
import buildMfaActions from './mfaActions.mocks';
import buildPasskeysActions from './passkeysActions.mocks';
import buildProfileActions from './profileActions.mocks';
import buildProvisioningActions from './provisioningActions.mocks';
import buildResetPhoneNumberActions from './resetPhoneNumberActions.mocks';
import buildRolesActions from './rolesActions.mocks';
import buildRestrictionsActions from './security/restrictionsActions.mocks';
import buildSecurityCenterActions from './security/securityCenterActions.mocks';
import buildSecurityPolicyActions from './security/securityPolicyActions.mocks';
import buildSessionsPolicyActions from './security/sessionsPolicyActions.mocks';
import buildSessionsActions from './sessionsActions.mocks';
import buildSignUpActions from './signUpActions.mocks';
import buildSmsActions from './smsActions.mocks';
import buildSocialLoginActions from './socialLoginActions.mocks';
import buildSsoActions from './ssoActions.mocks';
import buildStepUpActions from './stepUpActions.mocks';
import buildTeamActions from './teamActions.mocks';
import buildTenantsActions from './tenantsActions.mocks';
import buildUnlockAccountActions from './unlockAccountActions.mocks';
import { deepResetState, isProxy } from '../../helpers';
export const buildAuthActions = (store, api, actions, snapshotAuthState) => {
  const acceptInvitationActions = buildAcceptInvitationActions(store, api, actions);
  const accountSettingsActions = buildAccountSettingsActions(store, api, actions);
  const unlockAccountActions = buildUnlockAccountActions(store, api, actions);
  const activateAccountActions = buildActivateAccountActions(store, api, actions);
  const allAccountsActions = buildAllAccountsActions(store, api, actions);
  const allAccountsDialogActions = buildAllAccountsDialogActions(store, api, actions);
  const apiTokensActions = buildApiTokensActions(store, api, actions);
  const applicationsActions = buildApplicationsActions(store, api, actions);
  const customLoginActions = buildCustomLoginActions(store, api, actions);
  const entitlementsActions = buildEntitlementsActions(store, api, actions);
  const forgotPasswordActions = buildForgotPasswordActions(store, api, actions);
  const groupsActions = buildGroupsActions(store, api, actions);
  const groupsDialogsActions = buildGroupsDialogsActions(store, api, actions);
  const impersonateActions = buildImpersonateActions(store, api, actions);
  const loginActions = buildLoginActions(store, api, actions);
  const mfaActions = buildMfaActions(store, api, actions);
  const passkeysActions = buildPasskeysActions(store, api, actions);
  const profileActions = buildProfileActions(store, api, actions);
  const provisioningActions = buildProvisioningActions(store, api, actions);
  const resetPhoneNumberActions = buildResetPhoneNumberActions(store, api, actions);
  const restrictionsActions = buildRestrictionsActions(store, api, actions);
  const rolesActions = buildRolesActions(store, api, actions);
  const securityCenterActions = buildSecurityCenterActions(store, api, actions);
  const securityPolicyActions = buildSecurityPolicyActions(store, api, actions);
  const sessionsActions = buildSessionsActions(store, api, actions);
  const sessionsPolicyActions = buildSessionsPolicyActions(store, api, actions);
  const signUpActions = buildSignUpActions(store, api, actions);
  const smsActions = buildSmsActions(store, api, actions);
  const socialLoginActions = buildSocialLoginActions(store, api, actions);
  const ssoActions = buildSsoActions(store, api, actions);
  const stepUpActions = buildStepUpActions(store, api, actions);
  const teamActions = buildTeamActions(store, api, actions);
  const tenantsActions = buildTenantsActions(store, api, actions);
  const authStateActions = {
    acceptInvitationActions,
    accountSettingsActions,
    unlockAccountActions,
    activateAccountActions,
    allAccountsActions,
    allAccountsDialogActions,
    apiTokensActions,
    applicationsActions,
    customLoginActions,
    entitlementsActions,
    forgotPasswordActions,
    groupsActions,
    groupsDialogsActions,
    impersonateActions,
    loginActions,
    mfaActions,
    passkeysActions,
    profileActions,
    provisioningActions,
    resetPhoneNumberActions,
    restrictionsActions,
    rolesActions,
    securityCenterActions,
    securityPolicyActions,
    sessionsActions,
    sessionsPolicyActions,
    signUpActions,
    smsActions,
    socialLoginActions,
    ssoActions,
    stepUpActions,
    teamActions,
    tenantsActions
  };
  const setAuthState = state => {
    Object.keys(state).forEach(key => {
      const authKey = key;
      if (isProxy(store.auth[authKey])) {
        Object.assign(store.auth[authKey], state[authKey]);
      } else {
        store.auth[authKey] = state[authKey];
      }
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const resetAuthState = () => {
    deepResetState(store, ['auth'], snapshotAuthState);
  };
  const setUser = user => {
    setAuthState({
      user
    });
  };
  const authActions = _extends({
    setState: setAuthState,
    setAuthState,
    resetAuthState,
    setUser
  }, acceptInvitationActions, accountSettingsActions, activateAccountActions, unlockAccountActions, allAccountsActions, allAccountsDialogActions, apiTokensActions, applicationsActions, customLoginActions, entitlementsActions, forgotPasswordActions, groupsActions, groupsDialogsActions, impersonateActions, loginActions, mfaActions, passkeysActions, profileActions, provisioningActions, resetPhoneNumberActions, restrictionsActions, rolesActions, securityCenterActions, securityPolicyActions, sessionsActions, sessionsPolicyActions, signUpActions, smsActions, socialLoginActions, ssoActions, stepUpActions, teamActions, tenantsActions);
  return {
    authActions,
    authStateActions
  };
};