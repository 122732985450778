import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "userId"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { TeamStateKeys } from '../interfaces';
import { errorHandler } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const loadAllSubTenantsUsers = async payload => {
    var _payload$_limit, _payload$_offset, _payload$_filter, _payload$_sortBy, _payload$_order;
    const {
      silentLoading,
      callback
    } = payload;
    const teamState = store.auth.teamState;
    const qParam = teamState.allUsersQueryParams;
    const _limit = (_payload$_limit = payload._limit) != null ? _payload$_limit : qParam._limit;
    const _offset = (_payload$_offset = payload._offset) != null ? _payload$_offset : qParam._offset;
    const _filter = (_payload$_filter = payload._filter) != null ? _payload$_filter : qParam._filter;
    const _sortBy = (_payload$_sortBy = payload._sortBy) != null ? _payload$_sortBy : qParam._sortBy;
    const _order = (_payload$_order = payload._order) != null ? _payload$_order : qParam._order;
    const allUsersQueryParams = {
      _limit: _limit || 20,
      _offset: _offset || 0,
      _filter: _filter || '',
      _sortBy: _sortBy || 'name',
      _order: _order || 'DESC'
    };
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: !silentLoading
    });
    actions.setTeamState({
      allUsersQueryParams
    });
    try {
      const [
      // @ts-ignore // TODO: fix type in rest-api
      {
        items: users,
        // @ts-ignore // TODO: fix type in rest-api
        _metadata: {
          totalPages,
          totalItems
        }
      },
      // @ts-ignore // TODO: fix type in rest-api
      {
        items: roles
      },
      // @ts-ignore // TODO: fix type in rest-api
      {
        items: permissions
      }] = await Promise.all([api.subTenants.loadAllUsers(_extends({}, allUsersQueryParams)), api.teams.loadAvailableRoles(), api.teams.loadAvailablePermissions()]);
      actions.setTeamState({
        allUsers: users,
        totalPages,
        totalItems,
        roles,
        permissions
      });
      callback == null ? void 0 : callback(users);
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.USERS,
        value: errorHandler(e)
      });
      actions.setTeamState({
        totalPages: 0,
        users: []
      });
      callback == null ? void 0 : callback(null, e);
    }
    actions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: false
    });
  };
  const setUserRolesForSubTenants = async payload => {
    const {
        callback,
        userId
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    const teamState = store.auth.teamState;
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    try {
      await api.subTenants.setUserRolesForSubTenants(userId, body);
      const updatedUser = teamState.allUsers.find(user => user.id === userId);
      let newTenants = [];
      if (updatedUser) {
        newTenants = updatedUser.tenants.map(tenant => {
          var _body$subTenantsRoles;
          return _extends({}, tenant, {
            roles: ((_body$subTenantsRoles = body.subTenantsRoles.find(roleUpdate => roleUpdate.tenantId === tenant.tenantId)) == null ? void 0 : _body$subTenantsRoles.roleIds.map(roleId => {
              const role = teamState.roles.find(({
                id
              }) => roleId === id);
              return role;
            }).filter(role => role)) || tenant.roles
          });
        });
      }
      actions.setTeamState({
        allUsers: [...teamState.allUsers.filter(user => user.id !== userId), ...(updatedUser ? [_extends({}, updatedUser, {
          tenants: newTenants
        })] : [])],
        addUserDialogState: {
          open: false,
          loading: false
        }
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      actions.setTeamState({
        addUserDialogState: _extends({}, teamState.addUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const deleteUserFromSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded2);
    const teamState = store.auth.teamState;
    actions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: true
      })
    });
    try {
      await api.subTenants.removeUserFromTenantAndSubTenants(body);
      callback == null ? void 0 : callback(true);
      actions.setTeamState({
        allUsers: teamState.allUsers.filter(user => user.id !== body.userId && user.tenants.length === body.subTenants.length),
        deleteUserDialogState: {
          open: false,
          loading: false
        }
      });
    } catch (e) {
      actions.setTeamState({
        deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const addUserToSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    const teamState = store.auth.teamState;
    actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    try {
      await api.subTenants.addUserToTenantAndSubTenants(body);
      const {
        // @ts-ignore // TODO: fix type in rest-api
        items: users,
        // @ts-ignore // TODO: fix type in rest-api
        _metadata: {
          totalPages,
          totalItems
        }
      } = await api.subTenants.loadAllUsers({
        _limit: 20,
        _offset: 0,
        _filter: '',
        _sortBy: 'name',
        _order: 'DESC'
      });
      actions.setTeamState({
        allUsers: users,
        totalPages,
        totalItems,
        addUserDialogState: {
          open: false,
          loading: false
        }
      });
      callback == null ? void 0 : callback(null);
    } catch (e) {
      actions.setTeamState({
        addUserDialogState: _extends({}, teamState.addUserDialogState, {
          loading: false,
          error: errorHandler(e)
        })
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  return {
    loadAllSubTenantsUsers,
    setUserRolesForSubTenants,
    deleteUserFromSubTenants,
    addUserToSubTenants
  };
});