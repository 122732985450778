import _extends from "@babel/runtime/helpers/esm/extends";
import { GroupsStateKeys } from '../../auth';
import { delay } from '../../helpers';
import { buildGroupsActions } from '../../auth/GroupsState';
import { allGroupsDummy } from '../dummy';
import { GroupManagedByEnum } from '@frontegg/rest-api';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildGroupsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setGroupsState', 'resetUsersGroupsState', 'setUsersGroupsLoader', 'setUsersGroupsError']);
  mockedActions.loadGroups = async () => {
    const key = GroupsStateKeys.GET_GROUPS;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    const groups = allGroupsDummy;
    mockedActions.setGroupsState({
      groups
    });
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.createGroup = async payload => {
    var _payload$callback;
    const key = GroupsStateKeys.CREATE_GROUP;
    const {
      groups
    } = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: [_extends({}, payload, {
        id: `${payload.name}ID`,
        managedBy: GroupManagedByEnum.FRONTEGG
      }), ...(groups != null ? groups : [])]
    });
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.updateGroup = async payload => {
    var _state$groups, _payload$name2, _payload$color, _payload$description, _payload$callback2;
    const key = GroupsStateKeys.UPDATE_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups = state.groups) == null ? void 0 : _state$groups.map(group => {
        var _payload$name;
        return group.id === payload.groupId ? _extends({}, payload, {
          id: payload.groupId,
          name: (_payload$name = payload == null ? void 0 : payload.name) != null ? _payload$name : '',
          managedBy: GroupManagedByEnum.FRONTEGG
        }) : group;
      }),
      selectedGroup: _extends({}, state == null ? void 0 : state.selectedGroup, {
        name: (_payload$name2 = payload == null ? void 0 : payload.name) != null ? _payload$name2 : '',
        color: (_payload$color = payload == null ? void 0 : payload.color) != null ? _payload$color : '',
        description: (_payload$description = payload == null ? void 0 : payload.description) != null ? _payload$description : ''
      })
    });
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.deleteGroup = async payload => {
    var _state$groups2, _payload$callback3;
    const key = GroupsStateKeys.DELETE_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups2 = state.groups) == null ? void 0 : _state$groups2.filter(group => group.id !== payload.groupId)
    });
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.addRolesToGroup = async payload => {
    var _state$groups3, _payload$callback4;
    const key = GroupsStateKeys.ADD_ROLES_TO_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups3 = state.groups) == null ? void 0 : _state$groups3.map(group => {
        var _group$roles;
        return group.id === payload.groupId ? _extends({}, group, {
          roles: [...((_group$roles = group == null ? void 0 : group.roles) != null ? _group$roles : []), ...payload.roleIds.map(role => ({
            id: role,
            name: role
          }))]
        }) : group;
      })
    });
    payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.deleteRolesFromGroup = async payload => {
    var _state$groups4, _payload$callback5;
    const key = GroupsStateKeys.DELETE_ROLES_FROM_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups4 = state.groups) == null ? void 0 : _state$groups4.map(group => {
        var _group$roles2;
        return group.id === payload.groupId ? _extends({}, group, {
          roles: group == null ? void 0 : (_group$roles2 = group.roles) == null ? void 0 : _group$roles2.filter(role => !payload.roleIds.includes(role.id))
        }) : group;
      })
    });
    payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.addUsersToGroup = async payload => {
    var _state$groups5, _payload$callback6;
    const key = GroupsStateKeys.ADD_USERS_TO_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups5 = state.groups) == null ? void 0 : _state$groups5.map(group => {
        var _group$users;
        return group.id === payload.groupId ? _extends({}, group, {
          users: [...((_group$users = group == null ? void 0 : group.users) != null ? _group$users : []), ...payload.userIds.map(user => ({
            id: user,
            name: user
          }))]
        }) : group;
      })
    });
    payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.deleteUsersFromGroup = async payload => {
    var _state$groups6, _payload$callback7;
    const key = GroupsStateKeys.DELETE_USERS_FROM_GROUP;
    const state = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    await delay(500);
    mockedActions.setGroupsState({
      groups: state == null ? void 0 : (_state$groups6 = state.groups) == null ? void 0 : _state$groups6.map(group => {
        var _group$users2;
        return group.id === payload.groupId ? _extends({}, group, {
          users: group == null ? void 0 : (_group$users2 = group.users) == null ? void 0 : _group$users2.filter(user => !payload.userIds.includes(user.id))
        }) : group;
      })
    });
    payload == null ? void 0 : (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.getGroupConfiguration = async () => {
    const key = GroupsStateKeys.GET_GROUP_CONFIGURATION;
    const {
      groupsConfiguration
    } = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    if (groupsConfiguration) {
      mockedActions.setGroupsState({
        groupsConfiguration: _extends({}, groupsConfiguration)
      });
    }
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  mockedActions.updateGroupConfiguration = async payload => {
    const key = GroupsStateKeys.UPDATE_GROUP_CONFIGURATION;
    const {
      groupsConfiguration
    } = store.auth.groupsState;
    mockedActions.setUsersGroupsLoader({
      key,
      value: true
    });
    if (groupsConfiguration) {
      mockedActions.setGroupsState({
        groupsConfiguration: _extends({}, groupsConfiguration, payload)
      });
    }
    mockedActions.setUsersGroupsLoader({
      key,
      value: false
    });
  };
  return mockedActions;
});