import _extends from "@babel/runtime/helpers/esm/extends";
import { buildApiTokensActions } from '../../auth/ApiTokensState';
import { apiTokensDataDemo, apiTokensDataTenantDemo } from '../dummy';
import { v4 as uuidv4 } from 'uuid';
import { delay } from '../../helpers';
import { ApiStateKeys } from '../../auth';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildApiTokensActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setApiTokensState', 'resetApiTokensState', 'setApiTokensLoader', 'setApiTokensError']);
  mockedActions.addTenantApiToken = async payload => {
    const {
      description,
      roleIds,
      callback
    } = payload;
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: true
    });
    const {
      apiTokensDataTenant
    } = store.auth.apiTokensState;
    const newToken = _extends({}, apiTokensDataTenantDemo, {
      description,
      roleIds,
      clientId: `CLIENT_ID_${uuidv4()}`
    });
    mockedActions.setApiTokensState({
      showAddTokenDialog: false
    });
    await delay(200);
    mockedActions.setApiTokensState({
      apiTokensDataTenant: [...apiTokensDataTenant, newToken],
      successDialog: {
        open: true,
        secret: newToken.secret,
        clientId: newToken.clientId
      }
    });
    await delay(200);
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: false
    });
    callback == null ? void 0 : callback(null);
  };
  mockedActions.deleteTenantApiToken = async payload => {
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: true
    });
    await delay(200);
    const apiTokensDataTenant = [apiTokensDataTenantDemo];
    mockedActions.setApiTokensState({
      apiTokensDataTenant: apiTokensDataTenant.filter(i => i.clientId !== payload),
      deleteTokenDialog: {
        open: false,
        clientId: payload
      }
    });
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: false
    });
  };
  mockedActions.loadApiTokens = async payload => {
    var _payload$callback;
    if (!(payload != null && payload.silentLoading)) {
      mockedActions.setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    const apiTokensDataUser = [apiTokensDataDemo];
    const apiTokensDataTenant = [apiTokensDataTenantDemo];
    mockedActions.setApiTokensState({
      apiTokensDataUser,
      apiTokensDataTenant
    });
    await delay(200);
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    });
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  };
  mockedActions.loadUserApiTokens = async payload => {
    var _payload$callback2;
    if (!(payload != null && payload.silentLoading)) {
      mockedActions.setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    const apiTokensDataUser = [apiTokensDataDemo];
    mockedActions.setApiTokensState({
      apiTokensDataUser
    });
    await delay(200);
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    });
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, true);
  };
  mockedActions.loadTenantApiTokens = async payload => {
    var _payload$callback3;
    if (!(payload != null && payload.silentLoading)) {
      mockedActions.setApiTokensLoader({
        key: ApiStateKeys.LOAD_API_TOKENS,
        value: true
      });
    }
    const apiTokensDataTenant = [apiTokensDataTenantDemo];
    mockedActions.setApiTokensState({
      apiTokensDataTenant
    });
    await delay(200);
    mockedActions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    });
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  };
  return mockedActions;
});