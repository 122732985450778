import { mockActionsExpect } from '../../helpers';
import { subscriptionResponseMock } from '../dummy';
import { delay } from '../../../helpers';
import { buildSubscriptionActions } from '../../../subscriptions/Billing/Subscription';
export default ((store, api, actions) => {
  const originalActions = buildSubscriptionActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setBillingSubscriptionState', 'resetBillingSubscriptionState', 'setCancellationLoading', 'setCancellationError', 'setRenewalLoading', 'setRenewalError']);
  mockedActions.loadSubscription = async () => {
    mockedActions.setBillingSubscriptionState({
      loading: true
    });
    await delay(500);
    mockedActions.setBillingSubscriptionState({
      subscription: subscriptionResponseMock,
      fetching: false,
      loading: false,
      error: null
    });
  };
  return mockedActions;
});