// import type { ISignUpCompletePayload, IUserVerifiedPayload } from './auth';

/**
 * Available actions
 */
export let GTMEventAction;

/**
 * Mapping between event action to event payload
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
(function (GTMEventAction) {
  GTMEventAction["SIGNUP_COMPLETED"] = "signup_completed";
  GTMEventAction["USER_VERIFIED"] = "user_verified";
})(GTMEventAction || (GTMEventAction = {}));
/**
 * Report GTM event by onFronteggEvent
 * onFronteggEvent is coming by the customer GTM tag
 * @param action action (like signup_completed)
 * @param payload
 */
export function reportGTMEvent(action, payload) {
  var _window$onFronteggEve, _window;
  (_window$onFronteggEve = (_window = window).onFronteggEvent) == null ? void 0 : _window$onFronteggEve.call(_window, action, payload);
}