import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["routes"];
export * from './AcceptInvitationState/interfaces';
export * from './AccountSettingsState/interfaces';
export * from './ActivateAccountState/interfaces';
export * from './UnlockAccountState/interfaces';
export * from './ApiTokensState/interfaces';
export * from './ApplicationsState/interfaces';
export * from './CustomLoginState/interfaces';
export * from './Entitlements/interfaces';
export * from './Entitlements/helpers';
export * from './ForgotPasswordState/interfaces';
export * from './GroupsState/interfaces';
export * from './GroupsDialogsState/interfaces';
export * from './ImpersonateState/interfaces';
export * from './LoginState/interfaces';
export * from './MfaState/interfaces';
export * from './MSP/interfaces';
export * from './PasskeysState/interfaces';
export * from './ProfileState/interfaces';
export * from './ProvisioningState/interfaces';
export * from './ResetPhoneNumberState/interfaces';
export * from './RolesState/interfaces';
export * from './Security/RestrictionsState/interfaces';
export * from './Security/SecurityCenterState/interfaces';
export * from './Security/SecurityPolicyState/interfaces';
export * from './Security/SessionsPolicyState/interfaces';
export * from './SessionsState/interfaces';
export * from './SignUpState/interfaces';
export * from './SmsState/interfaces';
export * from './SocialLoginState/interfaces';
export * from './SSOState/interfaces';
export * from './StepUpState/interfaces';
export * from './TeamState/interfaces';
export * from './TenantsState/interfaces';
export * from './interfaces';
import { createAcceptInvitationState, buildAcceptInvitationActions } from './AcceptInvitationState';
import { createAccountSettingsState, buildAccountSettingsActions } from './AccountSettingsState';
import { createActivateAccountState, buildActivateAccountActions } from './ActivateAccountState';
import { createUnlockAccountState, buildUnlockAccountActions } from './UnlockAccountState';
import { createApiTokensState, buildApiTokensActions } from './ApiTokensState';
import { createApplicationsState, buildApplicationsActions } from './ApplicationsState';
import { createCustomLoginState, buildCustomLoginActions } from './CustomLoginState';
import { buildForgotPasswordActions, createForgotPasswordState } from './ForgotPasswordState';
import { createGroupsState, buildGroupsActions } from './GroupsState';
import { createGroupsDialogsState, buildGroupsDialogsActions } from './GroupsDialogsState';
import { createImpersonateState, buildImpersonateActions } from './ImpersonateState';
import { createLoginState, buildLoginActions } from './LoginState';
import { createMfaState, buildMfaActions } from './MfaState';
import { createAllAccountsState, buildAllAccountsActions } from './MSP';
import { createAllAccountsDialogState, buildAllAccountsDialogActions } from './MSP';
import { createPasskeysState, buildPasskeysActions } from './PasskeysState';
import { createProfileState, buildProfileActions } from './ProfileState';
import { createProvisioningState, buildProvisioningActions } from './ProvisioningState';
import { createResetPhoneNumberState, buildResetPhoneNumberActions } from './ResetPhoneNumberState';
import { createRolesState, buildRolesActions } from './RolesState';
import { createRestrictionsState, buildRestrictionsActions } from './Security/RestrictionsState';
import { createSecurityCenterState, buildSecurityCenterActions } from './Security/SecurityCenterState';
import { createSecurityPolicyState, buildSecurityPolicyActions } from './Security/SecurityPolicyState';
import { createSessionsPolicyState, buildSessionsPolicyActions } from './Security/SessionsPolicyState';
import { createSessionsState, buildSessionsActions } from './SessionsState';
import { createSignUpState, buildSignUpActions } from './SignUpState';
import { createSmsState, buildSmsActions } from './SmsState';
import { createSocialLoginState, buildSocialLoginActions } from './SocialLoginState';
import { createSSOState, buildSSOActions } from './SSOState';
import { createStepUpState, buildStepUpActions } from './StepUpState';
import { createTeamState, buildTeamActions } from './TeamState';
import { createTenantsState, buildTenantsActions } from './TenantsState';
import { defaultFronteggRoutes } from './LoginState/consts';
import { deepResetState, isProxy } from '../helpers';
import { buildEntitlementsActions } from './Entitlements';
import { createProxy } from '../toolkit/proxy';
export const createAuthState = _overrideState => {
  const _ref = _overrideState != null ? _overrideState : {},
    {
      routes
    } = _ref,
    overrideState = _objectWithoutPropertiesLoose(_ref, _excluded);
  return createProxy(_extends({
    routes: _extends({}, defaultFronteggRoutes, routes),
    onRedirectTo: () => void 0,
    isAuthenticated: false,
    isLoading: true,
    isSSOAuth: false,
    user: null,
    error: undefined,
    userIp: undefined
  }, overrideState, {
    // nested states
    acceptInvitationState: createAcceptInvitationState(overrideState == null ? void 0 : overrideState.acceptInvitationState),
    accountSettingsState: createAccountSettingsState(overrideState == null ? void 0 : overrideState.accountSettingsState),
    activateAccountState: createActivateAccountState(overrideState == null ? void 0 : overrideState.activateAccountState),
    unlockAccountState: createUnlockAccountState(overrideState == null ? void 0 : overrideState.unlockAccountState),
    apiTokensState: createApiTokensState(overrideState == null ? void 0 : overrideState.apiTokensState),
    applicationsState: createApplicationsState(overrideState == null ? void 0 : overrideState.applicationsState),
    customLoginState: createCustomLoginState(overrideState == null ? void 0 : overrideState.customLoginState),
    forgotPasswordState: createForgotPasswordState(overrideState == null ? void 0 : overrideState.forgotPasswordState),
    groupsState: createGroupsState(overrideState == null ? void 0 : overrideState.groupsState),
    groupsDialogsState: createGroupsDialogsState(overrideState == null ? void 0 : overrideState.groupsDialogsState),
    impersonateState: createImpersonateState(overrideState == null ? void 0 : overrideState.impersonateState),
    loginState: createLoginState(overrideState == null ? void 0 : overrideState.loginState),
    mfaState: createMfaState(overrideState == null ? void 0 : overrideState.mfaState),
    allAccountsState: createAllAccountsState(overrideState == null ? void 0 : overrideState.allAccountsState),
    allAccountsDialogsState: createAllAccountsDialogState(overrideState == null ? void 0 : overrideState.allAccountsDialogsState),
    passkeysState: createPasskeysState(overrideState == null ? void 0 : overrideState.passkeysState),
    profileState: createProfileState(overrideState == null ? void 0 : overrideState.profileState),
    provisioningState: createProvisioningState(overrideState == null ? void 0 : overrideState.provisioningState),
    resetPhoneNumberState: createResetPhoneNumberState(overrideState == null ? void 0 : overrideState.resetPhoneNumberState),
    rolesState: createRolesState(overrideState == null ? void 0 : overrideState.rolesState),
    restrictionsState: createRestrictionsState(overrideState == null ? void 0 : overrideState.restrictionsState),
    securityCenterState: createSecurityCenterState(overrideState == null ? void 0 : overrideState.securityCenterState),
    securityPolicyState: createSecurityPolicyState(overrideState == null ? void 0 : overrideState.securityPolicyState),
    sessionsPolicyState: createSessionsPolicyState(overrideState == null ? void 0 : overrideState.sessionsPolicyState),
    sessionsState: createSessionsState(overrideState == null ? void 0 : overrideState.sessionsState),
    signUpState: createSignUpState(overrideState == null ? void 0 : overrideState.signUpState),
    smsState: createSmsState(overrideState == null ? void 0 : overrideState.smsState),
    socialLoginState: createSocialLoginState(overrideState == null ? void 0 : overrideState.socialLoginState),
    ssoState: createSSOState(overrideState == null ? void 0 : overrideState.ssoState),
    stepUpState: createStepUpState(overrideState == null ? void 0 : overrideState.stepUpState),
    teamState: createTeamState(overrideState == null ? void 0 : overrideState.teamState),
    tenantsState: createTenantsState(overrideState == null ? void 0 : overrideState.tenantsState)
  }));
};
export const buildAuthActions = (store, api, actions, snapshotAuthState) => {
  const setAuthState = state => {
    Object.keys(state).forEach(key => {
      const authKey = key;
      if (isProxy(store.auth[authKey])) {
        Object.assign(store.auth[authKey], state[authKey]);
      } else {
        store.auth[authKey] = state[authKey];
      }
    });
  };
  const resetAuthState = () => {
    deepResetState(store, ['auth'], snapshotAuthState);
  };
  const setUser = user => {
    setAuthState({
      user
    });
  };
  const acceptInvitationActions = buildAcceptInvitationActions(store, api, actions);
  const accountSettingsActions = buildAccountSettingsActions(store, api, actions);
  const activateAccountActions = buildActivateAccountActions(store, api, actions);
  const unlockAccountActions = buildUnlockAccountActions(store, api, actions);
  const apiTokensActions = buildApiTokensActions(store, api, actions);
  const applicationsActions = buildApplicationsActions(store, api, actions);
  const customLoginActions = buildCustomLoginActions(store, api, actions);
  const entitlementsActions = buildEntitlementsActions(store, api, actions);
  const forgotPasswordActions = buildForgotPasswordActions(store, api, actions);
  const groupsActions = buildGroupsActions(store, api, actions);
  const groupsDialogsActions = buildGroupsDialogsActions(store, api, actions);
  const impersonateActions = buildImpersonateActions(store, api, actions);
  const loginActions = buildLoginActions(store, api, actions);
  const mfaActions = buildMfaActions(store, api, actions);
  const allAccountsActions = buildAllAccountsActions(store, api, actions);
  const allAccountsDialogActions = buildAllAccountsDialogActions(store, api, actions);
  const passkeysActions = buildPasskeysActions(store, api, actions);
  const profileActions = buildProfileActions(store, api, actions);
  const provisioningActions = buildProvisioningActions(store, api, actions);
  const resetPhoneNumberActions = buildResetPhoneNumberActions(store, api, actions);
  const rolesActions = buildRolesActions(store, api, actions);
  const restrictionsActions = buildRestrictionsActions(store, api, actions);
  const securityCenterActions = buildSecurityCenterActions(store, api, actions);
  const securityPolicyActions = buildSecurityPolicyActions(store, api, actions);
  const sessionsPolicyActions = buildSessionsPolicyActions(store, api, actions);
  const sessionsActions = buildSessionsActions(store, api, actions);
  const signUpActions = buildSignUpActions(store, api, actions);
  const smsActions = buildSmsActions(store, api, actions);
  const socialLoginActions = buildSocialLoginActions(store, api, actions);
  const ssoActions = buildSSOActions(store, api, actions);
  const stepUpActions = buildStepUpActions(store, api, actions);
  const teamActions = buildTeamActions(store, api, actions);
  const tenantsActions = buildTenantsActions(store, api, actions);
  const stateActions = {
    acceptInvitationActions,
    accountSettingsActions,
    activateAccountActions,
    unlockAccountActions,
    apiTokensActions,
    applicationsActions,
    customLoginActions,
    entitlementsActions,
    forgotPasswordActions,
    groupsActions,
    groupsDialogsActions,
    impersonateActions,
    loginActions,
    mfaActions,
    allAccountsActions,
    allAccountsDialogActions,
    passkeysActions,
    profileActions,
    provisioningActions,
    resetPhoneNumberActions,
    rolesActions,
    restrictionsActions,
    securityCenterActions,
    securityPolicyActions,
    sessionsPolicyActions,
    sessionsActions,
    signUpActions,
    smsActions,
    socialLoginActions,
    ssoActions,
    stepUpActions,
    teamActions,
    tenantsActions
  };
  return [_extends({
    setAuthState,
    /** @deprecated use setAuthState instead */
    setState: setAuthState,
    resetAuthState,
    setUser
  }, Object.values(stateActions).reduce((acc, actions) => _extends({}, acc, actions), {})), stateActions];
};