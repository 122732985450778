import _extends from "@babel/runtime/helpers/esm/extends";
import { createInformationState, buildInformationActions } from './Information';
import { createInvoicesState, buildInvoicesActions } from './Invoices';
import { createPaymentMethodState, buildPaymentMethodActions } from './PaymentMethod';
import { createSubscriptionState, buildSubscriptionActions } from './Subscription';
import { createProxy } from '../../toolkit/proxy';
export const createBillingState = overrideState => {
  return createProxy({
    information: createInformationState(overrideState == null ? void 0 : overrideState.information),
    invoices: createInvoicesState(overrideState == null ? void 0 : overrideState.invoices),
    paymentMethod: createPaymentMethodState(overrideState == null ? void 0 : overrideState.paymentMethod),
    subscription: createSubscriptionState(overrideState == null ? void 0 : overrideState.subscription)
  });
};
export const buildBillingActions = (store, api, actions) => {
  const informationActions = buildInformationActions(store, api, actions);
  const paymentMethodActions = buildPaymentMethodActions(store, api, actions);
  const invoicesActions = buildInvoicesActions(store, api, actions);
  const subscriptionActions = buildSubscriptionActions(store, api, actions);
  return [_extends({}, informationActions, paymentMethodActions, invoicesActions, subscriptionActions), {
    information: informationActions,
    invoices: invoicesActions,
    paymentMethod: paymentMethodActions,
    subscription: subscriptionActions
  }];
};