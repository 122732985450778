import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class EntitlementsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.loadEntitlementsV2 = async () => {
      return this.get(urls.entitlements.v2);
    };
  }

}
export default new EntitlementsApi('default');