import { mockActionsExpect } from '../helpers';
import { delay } from '../../helpers';
import { buildConfigActions } from '../../subscriptions/Config';
import { PaymentProvider } from '../../subscriptions/interfaces';
export default ((store, api, actions) => {
  const originalActions = buildConfigActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setPaymentConfigState', 'resetPaymentConfigState']);
  mockedActions.loadPaymentConfiguration = async () => {
    mockedActions.setPaymentConfigState({
      loading: true
    });
    await delay(500);
    mockedActions.setPaymentConfigState({
      loading: false,
      fetching: false,
      config: {
        paymentProvider: PaymentProvider.STRIPE,
        apiKey: ''
      }
    });
  };
  return mockedActions;
});