import { base64urlEncode } from './encoders';
export function base64ToFormData(base64, key = 'file') {
  const b64toBlob = (base64, contentType) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, {
      type: contentType
    });
  };
  const matchResult = base64.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/);
  if (matchResult == null) {
    return null;
  }

  // Get the content type of the image
  const contentType = `image/${matchResult[1]}`;
  // get the real base64 content of the file
  const data = matchResult[2];

  // Convert it to a blob to upload
  const blob = b64toBlob(data, contentType);

  // Create a FormData and append the file with "image" as parameter name
  const formDataToUpload = new FormData();
  formDataToUpload.append(key, new File([blob], key, {
    type: contentType
  }));
  return formDataToUpload;
}
export const publicKeyCredentialToJSON = pubKeyCred => {
  if (pubKeyCred instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const arr = [];
    for (const i of pubKeyCred) {
      arr.push(publicKeyCredentialToJSON(i));
    }
    return arr;
  } else if (pubKeyCred instanceof ArrayBuffer) {
    return base64urlEncode(pubKeyCred);
  } else if (pubKeyCred instanceof Object) {
    const obj = {};
    for (const key in pubKeyCred) {
      // @ts-ignore
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }
    return obj;
  }
  return pubKeyCred;
};
export const readFileAsText = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});