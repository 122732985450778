import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["id"];
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setProvisioningState = state => {
    Object.assign(store.auth.provisioningState, state);
  };
  const resetProvisioningState = () => {
    deepResetState(store, ['auth', 'provisioningState'], initialState);
  };

  /**
   * @private
   */
  const __loadCountForConnection = async connection => {
    const {
      count
    } = await api.directory.countConfigurationUsers(connection.id);
    return _extends({}, connection, {
      count
    });
  };
  const loadProvisionConnections = async () => {
    setProvisioningState({
      loading: true,
      error: null
    });
    try {
      const connections = await api.directory.getConfigs();
      const connectionsWithCount = await Promise.all(connections.map(connection => __loadCountForConnection(connection)));
      setProvisioningState({
        connections: connectionsWithCount,
        loading: false
      });
    } catch (e) {
      setProvisioningState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const createProvisionConnection = async payload => {
    const {
        callback
      } = payload,
      newConnectionBody = _objectWithoutPropertiesLoose(payload, _excluded);
    setProvisioningState({
      loading: true,
      error: null
    });
    try {
      const newConnectionResponse = await api.directory.createConfiguration(newConnectionBody);
      const connections = await api.directory.getConfigs();
      const connectionsWithCount = await Promise.all(connections.map(connection => __loadCountForConnection(connection)));
      setProvisioningState({
        connections: connectionsWithCount,
        loading: false
      });
      callback == null ? void 0 : callback(newConnectionResponse);
    } catch (e) {
      setProvisioningState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const deleteProvisionConnection = async payload => {
    const {
      id,
      callback
    } = payload;
    setProvisioningState({
      loading: true,
      error: null
    });
    try {
      await api.directory.deleteConfiguration(id);
      const connections = await api.directory.getConfigs();
      const connectionsWithCount = await Promise.all(connections.map(connection => __loadCountForConnection(connection)));
      setProvisioningState({
        connections: connectionsWithCount,
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setProvisioningState({
        error: errorHandler(e),
        loading: false
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  const updateProvisionConnection = async payload => {
    const {
        id
      } = payload,
      updateRequest = _objectWithoutPropertiesLoose(payload, _excluded2);
    setProvisioningState({
      loading: true,
      error: null
    });
    try {
      await api.directory.updateConfiguration(id, updateRequest);
      const connections = await api.directory.getConfigs();
      const connectionsWithCount = await Promise.all(connections.map(connection => __loadCountForConnection(connection)));
      setProvisioningState({
        connections: connectionsWithCount,
        loading: false
      });
    } catch (e) {
      setProvisioningState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  return {
    setProvisioningState,
    resetProvisioningState,
    __loadCountForConnection,
    loadProvisionConnections,
    createProvisionConnection,
    deleteProvisionConnection,
    updateProvisionConnection
  };
});