import { errorHandler } from '../../helpers';
import { buildForgotPasswordActions } from '../../auth/ForgotPasswordState';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildForgotPasswordActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setForgotPasswordState', 'resetForgotPasswordState']);
  mockedActions.loadPasswordConfig = async payload => {
    mockedActions.setForgotPasswordState({
      loading: true
    });
    try {
      mockedActions.setForgotPasswordState({
        loading: false,
        passwordConfig: {
          allowPassphrases: true,
          maxLength: 128,
          minLength: 6,
          minPhraseLength: 6,
          minOptionalTestsToPass: 1
        }
      });
    } catch (e) {
      mockedActions.setForgotPasswordState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  return mockedActions;
});