import { FeatureFlags } from '@frontegg/rest-api';
import { MFAStep } from '../../MfaState/interfaces';
import { LoginFlow, LoginStep } from '../interfaces';
export default function (store, api, sharedActions) {
  const actions = sharedActions;

  /**
   * TODO: must be moved to a separate file
   * @param flags
   * @private
   */
  const getFeatureFlags = async flags => {
    const appName = store.root.appName;
    return FeatureFlags.getFeatureFlags(flags, appName);
  };

  /**
   * Additional steps for after MFA authentication with authenticator app handler for step up flow
   */
  const postHandleVerifyMFAResponseForStepUp = async () => {
    return await actions.afterStepUpAuthNavigation();
  };

  /**
   * Additional steps for after MFA authentication with authenticator app handler for login flow
   * @param isAuthenticated
   */
  const postHandleVerifyMFAResponseForLogin = async isAuthenticated => {
    const loginState = store.auth.loginState;
    const mfaStep = store.auth.mfaState.step;
    const [securityCenterLoginFlows] = await getFeatureFlags(['security-center-show-login-flows']);
    if (loginState.flow === LoginFlow.Login) {
      if (securityCenterLoginFlows && loginState.isBreachedPassword && !isAuthenticated) {
        actions.setLoginState({
          step: LoginStep.breachedPassword,
          loading: false
        });
      } else {
        const shouldShowPrompt = await actions.__shouldShowPromptPasskeys();
        if (mfaStep === MFAStep.smsVerifyCode && shouldShowPrompt) {
          actions.setLoginState({
            step: LoginStep.promptPasskeys,
            loading: false
          });
        } else {
          await actions.afterAuthNavigation();
        }
      }
    }
  };

  /**
   * Handle after MFA authentication with authenticator app
   * @param payload.user
   * @param payload.tenants
   * @param payload.activeTenant
   * @param payload
   * @param isStepUp
   *
   * When using this saga, you should wrap it with try/catch block and handle according to your logic
   */
  const handleVerifyMFAResponse = async (payload, isStepUp = false) => {
    const {
      user,
      tenants,
      activeTenant
    } = payload;
    actions.setUser(user);
    actions.setTenantsState({
      tenants,
      activeTenant,
      loading: false
    });
    if (user.id) {
      localStorage.setItem('userId', user.id);
    }
    const isAuthenticated = !!user.accessToken;
    actions.setAuthState({
      isAuthenticated
    });
    if (isStepUp) {
      return await postHandleVerifyMFAResponseForStepUp();
    }
    return await postHandleVerifyMFAResponseForLogin(isAuthenticated);
  };
  return {
    postHandleVerifyMFAResponseForStepUp,
    postHandleVerifyMFAResponseForLogin,
    handleVerifyMFAResponse,
    getFeatureFlags
  };
}