import _extends from "@babel/runtime/helpers/esm/extends";
import usersActions from './users.actions';
import subTenantsActions from './sub-tenants.actions';
import activationLinkActions from './activation-link.actions';
import invitationLinkActions from './invitation-link.actions';
import { deepResetState } from '../../../helpers';
import { initialState } from '../state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setTeamState = payload => {
    Object.assign(store.auth.teamState, payload);
  };
  const resetTeamState = () => {
    deepResetState(store, ['auth', 'teamState'], initialState);
  };
  const setTeamLoader = payload => {
    Object.assign(store.auth.teamState, {
      loaders: _extends({}, store.auth.teamState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setTeamError = payload => {
    Object.assign(store.auth.teamState, {
      errors: _extends({}, store.auth.teamState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.teamState.loaders, {
        [payload.key]: false
      })
    });
  };
  const openAddUserDialog = payload => {
    setTeamState({
      addUserDialogState: _extends({
        open: true,
        loading: false,
        error: false
      }, payload)
    });
  };
  const closeAddUserDialog = payload => {
    var _store$auth$teamState, _store$auth$teamState2;
    (_store$auth$teamState = (_store$auth$teamState2 = store.auth.teamState.addUserDialogState).onClose) == null ? void 0 : _store$auth$teamState.call(_store$auth$teamState2, payload);
    setTeamState({
      addUserDialogState: {
        loading: false,
        error: false,
        open: false
      }
    });
  };
  const openDeleteUserDialog = payload => {
    setTeamState({
      deleteUserDialogState: _extends({
        open: true,
        loading: false,
        error: false
      }, payload)
    });
  };
  const closeDeleteUserDialog = payload => {
    var _store$auth$teamState3, _store$auth$teamState4;
    (_store$auth$teamState3 = (_store$auth$teamState4 = store.auth.teamState.deleteUserDialogState).onClose) == null ? void 0 : _store$auth$teamState3.call(_store$auth$teamState4, payload);
    setTeamState({
      deleteUserDialogState: {
        loading: false,
        error: false,
        open: false
      }
    });
  };
  const lockUser = async payload => {};
  // openLockUserDialog: (payload?: ISetDeleteUserDialog) => void;
  // closeLockUserDialog: (payload?: any) => void;

  return _extends({
    setTeamLoader,
    setTeamError,
    setTeamState,
    resetTeamState,
    openAddUserDialog,
    closeAddUserDialog,
    openDeleteUserDialog,
    closeDeleteUserDialog,
    lockUser
  }, usersActions(store, api, sharedActions), subTenantsActions(store, api, sharedActions), activationLinkActions(store, api, sharedActions), invitationLinkActions(store, api, sharedActions));
});