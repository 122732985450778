import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class SubscriptionApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getSubscriptions = async () => {
      return this.get(urls.subscriptions.billing.subscriptions.v1);
    };

    this.getSubscription = async subscriptionId => {
      return this.get(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}`);
    };

    this.cancelSubscription = async subscriptionId => {
      return this.put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}/cancellations/`, {});
    };

    this.renewSubscription = async subscriptionId => {
      return this.put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}/renewals/`, {});
    };

    this.updateSubscription = async (subscriptionId, {
      paymentMethodId,
      planId
    }) => {
      return this.put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}`, {
        paymentMethodId,
        planId
      });
    };
  }

}