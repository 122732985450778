import { createProxy } from '../../toolkit/proxy';
export const initialState = {
  addGroupDialogState: {
    loading: false,
    open: false
  },
  editGroupDialogState: {
    loading: false,
    open: false
  },
  deleteGroupDialogState: {
    loading: false,
    open: false
  },
  addMembersToGroupDialogState: {
    loading: false,
    open: false,
    userIds: []
  },
  deleteMemberFromGroupDialogState: {
    loading: false,
    open: false
  }
};
export default (overrideState => createProxy(initialState, overrideState));