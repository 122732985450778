import { ACR_VALUE, SHOULD_STEP_UP_KEY } from '../consts';
import { setAfterAuthRedirectUrlForStepUp } from '../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * Step up for hosted login apps
   * @param payload.maxAge
   */
  const stepUpHostedLogin = async payload => {
    var _store$auth$tenantsSt, _store$auth$tenantsSt2;
    const params = {
      acr_values: ACR_VALUE
    };
    const {
      maxAge
    } = payload || {};
    if (maxAge !== undefined) {
      params['max_age'] = maxAge.toString();
    }
    const alias = (_store$auth$tenantsSt = store.auth.tenantsState) == null ? void 0 : (_store$auth$tenantsSt2 = _store$auth$tenantsSt.activeTenant) == null ? void 0 : _store$auth$tenantsSt2.alias;
    if (alias) {
      params['organization'] = alias;
    }
    setAfterAuthRedirectUrlForStepUp();
    window.localStorage.setItem(SHOULD_STEP_UP_KEY, 'true');
    await actions.__requestHostedLoginAuthorize(params);
  };
  return {
    stepUpHostedLogin
  };
});