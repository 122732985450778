// All Accounts State keys
export let AllAccountsStateKeys;

// All Accounts State types
(function (AllAccountsStateKeys) {
  AllAccountsStateKeys["GET_ACCOUNTS"] = "GET_ACCOUNTS";
  AllAccountsStateKeys["SEARCH_ACCOUNTS"] = "SEARCH_ACCOUNTS";
  AllAccountsStateKeys["SET_ROOT_ACCOUNT_DATA"] = "SET_ROOT_ACCOUNT_DATA";
  AllAccountsStateKeys["SET_SELECTED_SUB_ACCOUNT_DATA"] = "SET_SELECTED_SUB_ACCOUNT_DATA";
  AllAccountsStateKeys["LOAD_NUMBER_OF_USERS_FOR_ACCOUNT"] = "LOAD_NUMBER_OF_USERS_FOR_ACCOUNT";
  AllAccountsStateKeys["UPDATE_SUB_ACCOUNT_ACCESS_FOR_USER"] = "UPDATE_SUB_ACCOUNT_ACCESS_FOR_USER";
  AllAccountsStateKeys["CREATE_SUB_ACCOUNT"] = "CREATE_SUB_ACCOUNT";
  AllAccountsStateKeys["UPDATE_SUB_ACCOUNT"] = "UPDATE_SUB_ACCOUNT";
  AllAccountsStateKeys["DELETE_SUB_ACCOUNT"] = "DELETE_SUB_ACCOUNT";
  AllAccountsStateKeys["GET_ACCOUNT_USERS"] = "GET_ACCOUNT_USERS";
  AllAccountsStateKeys["ADD_USERS_TO_ACCOUNT"] = "ADD_USERS_TO_ACCOUNT";
  AllAccountsStateKeys["DELETE_USERS_FROM_ACCOUNT"] = "DELETE_USERS_FROM_ACCOUNT";
  AllAccountsStateKeys["SET_USER_ROLES_FOR_SUB_ACCOUNT"] = "SET_USER_ROLES_FOR_SUB_ACCOUNT";
  AllAccountsStateKeys["UPDATE_SUB_ACCOUNT_SETTINGS"] = "UPDATE_SUB_ACCOUNT_SETTINGS";
  AllAccountsStateKeys["UPDATE_SUB_ACCOUNT_MANAGEMENT"] = "UPDATE_SUB_ACCOUNT_MANAGEMENT";
  AllAccountsStateKeys["UPDATE_SUB_ACCOUNT_HIERARCHY_SETTINGS"] = "UPDATE_SUB_ACCOUNT_HIERARCHY_SETTINGS";
  AllAccountsStateKeys["GET_USER_JWT"] = "GET_USER_JWT";
  AllAccountsStateKeys["GET_TEAM_USERS"] = "GET_TEAM_USERS";
})(AllAccountsStateKeys || (AllAccountsStateKeys = {}));