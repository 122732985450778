import _extends from "@babel/runtime/helpers/esm/extends";
import { getMfaStepForEnrolledUsers, getMfaStepForNotEnrolledUsers } from '../helpers';
import { LoginStep, LoginFlow } from '../interfaces';
import { MFAStep } from '../../MfaState/interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const getMfaRequiredState = async user => {
    let step = LoginStep.loginWithTwoFactor;
    const mfaState = {
      step: MFAStep.verify,
      loading: false,
      saving: false
    };
    const loginState = store.auth.loginState;
    const {
      isAllowedToRemember,
      mfaDeviceExpiration
    } = await api.auth.checkIfAllowToRememberMfaDevice(user.mfaToken);
    const isEnrolled = !('mfaEnrolled' in user && !user.mfaEnrolled);
    if (isEnrolled) {
      Object.assign(mfaState, {
        mfaDevices: user.mfaDevices,
        step: getMfaStepForEnrolledUsers(user.mfaDevices)
      });
    } else {
      Object.assign(mfaState, {
        step: getMfaStepForNotEnrolledUsers(user.mfaStrategies),
        qrCode: user.qrCode,
        recoveryCode: user.recoveryCode,
        loading: false,
        mfaToken: user.mfaToken,
        mfaStrategies: user.mfaStrategies
      });
      step = LoginStep.forceTwoFactor;
    }
    let quickLoginState = {};
    const quickLoginToRegister = localStorage.getItem('register-quick-login');
    if (quickLoginToRegister) {
      quickLoginState = {
        quickLoginToRegister,
        flow: LoginFlow.RegisterQuickLogin
      };
    }
    return {
      user: undefined,
      isAuthenticated: false,
      mfaState,
      loginState: _extends({}, loginState, quickLoginState, {
        mfaToken: user.mfaToken,
        mfaRequired: user.mfaRequired,
        loading: false,
        error: undefined,
        step,
        tenantsLoading: true,
        email: user.userEmail,
        tenants: [],
        allowRememberMfaDevice: isAllowedToRemember,
        mfaDeviceExpiration,
        isBreachedPassword: user.isBreachedPassword
      })
    };
  };
  return {
    getMfaRequiredState
  };
});