import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "events"];
import { ImpersonateStep } from './interfaces';
import { errorHandler, delay, deepResetState } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setImpersonateState = state => {
    Object.assign(store.auth.impersonateState, state);
  };
  const resetImpersonateState = () => {
    deepResetState(store, ['auth', 'impersonateState'], initialState);
  };
  const impersonate = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    setImpersonateState({
      loading: true
    });
    try {
      // TODO: check why this this response not used for setting user accessToken
      //       https://github.com/frontegg/admin-box/blob/8ac579241250ac2ab627aacfcc6118b548a4c7e5/packages/redux-store/src/auth/ImpersonationState/saga.ts#L13
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = await api.impersonate.impersonate(payload);
      setImpersonateState({
        step: ImpersonateStep.success
      });
      await delay(1000);
      /**
       * We want to avoid cleaning the origin of the redirect URL
       * because we want to refresh the page and redirect to the same origin on embedded mode
       */
      await actions.afterAuthNavigation({
        preventRedirectUrlOriginCleaning: true
      });
      resetImpersonateState();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setImpersonateState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  return {
    setImpersonateState,
    resetImpersonateState,
    impersonate
  };
});