import { mockActionsExpect } from '../helpers';
import { buildAuditLogsActions } from '../../audits/AuditLogsState';
import { errorHandler } from '../../helpers';
import { auditsLogsFilterAndSort } from './dummy';
export default ((store, api, actions) => {
  const originalActions = buildAuditLogsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setAuditLogsState', 'resetAuditLogsState']);
  mockedActions.loadAuditLogs = async payload => {
    mockedActions.setAuditLogsState({
      loading: !payload.silentLoading,
      error: null
    });
    const state = store.auditLogs.auditLogsState;
    const {
      columns
    } = store.auditLogs.auditsMetadataState;
    try {
      var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort, _payload$callback;
      const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : state.pageSize;
      const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : state.pageOffset;
      const filter = (_payload$filter = payload.filter) != null ? _payload$filter : state.filter;
      const sort = (_payload$sort = payload.sort) != null ? _payload$sort : state.sort;
      mockedActions.setAuditLogsState({
        pageSize,
        pageOffset,
        filter,
        sort
      });
      if (!columns) {
        await actions.loadAuditsMetadata();
      }
      const {
        data,
        total
      } = auditsLogsFilterAndSort(filter, sort);
      mockedActions.setAuditLogsState({
        loading: false,
        logs: data != null ? data : [],
        totalPages: +Math.ceil(total / pageSize)
      });
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      mockedActions.setAuditLogsState({
        loading: false,
        error: errorHandler(e)
      });
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  return mockedActions;
});