import { initialState } from './state';
import { deepResetState, errorHandler } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const setBillingInvoiceState = state => {
    Object.assign(store.subscriptions.billing.invoices, state);
  };
  const resetBillingInvoiceState = () => {
    deepResetState(store, ['subscriptions', 'billing', 'invoices'], initialState);
  };
  const setBillingInvoiceError = error => {
    setBillingInvoiceState({
      error,
      loading: false,
      fetching: false
    });
  };
  const setBillingInvoiceDownloadState = state => {
    Object.assign(store.subscriptions.billing.invoices.invoiceDownload, state);
  };
  const loadInvoices = async () => {
    setBillingInvoiceState({
      loading: true
    });
    try {
      const responseInvoices = await api.subscriptions.getSubscriptionInvoices();
      const invoices = responseInvoices.map(invoice => ({
        id: invoice.id,
        externalId: invoice.externalId,
        subscriptionId: invoice.subscriptionId,
        paymentDate: new Date(Date.parse(invoice.paymentDate)),
        totalAmount: +((invoice.totalAmount || 0) / 100).toFixed(2),
        currency: invoice.currency || 'usd',
        paid: invoice.paid || false,
        receiptNumber: invoice.receiptNumber
      }));
      setBillingInvoiceState({
        loading: false,
        fetching: false,
        invoices
      });
    } catch (e) {
      setBillingInvoiceError(errorHandler(e));
    }
  };
  const downloadInvoice = async payload => {
    setBillingInvoiceDownloadState({
      loading: true,
      error: null
    });
    try {
      await api.subscriptions.getSubscriptionInvoicePdf(payload.invoiceId, payload.filename);
      setBillingInvoiceDownloadState({
        loading: false,
        error: null
      });
    } catch (e) {
      setBillingInvoiceDownloadState({
        loading: false,
        error: errorHandler(e, null)
      });
    }
  };
  return {
    setBillingInvoiceState,
    resetBillingInvoiceState,
    // @deprecated use loadBillingInvoices instead
    loadInvoices,
    loadBillingInvoices: loadInvoices,
    // @deprecated use downloadBillingInvoice instead
    downloadInvoice,
    downloadBillingInvoice: downloadInvoice
  };
});