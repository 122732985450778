import { ContextHolder } from '@frontegg/rest-api';
import { getMfaStepForEnrolledUsers } from '../../LoginState/helpers';
import { MFAStep } from '../../MfaState/interfaces';
import { SHOULD_STEP_UP_KEY } from '../consts';
/**
 * Error returned from the BE when the user is not enrolled to MFA and logged in with email magic code/link
 */
const MFA_IS_NOT_ENROLLED_ERROR = 'MFA is not enrolled';

/**
 * @param error API error
 * @returns true when the error is MFA is not enrolled error
 */
const isMfaIsNotEnrolledError = error => (error == null ? void 0 : error['message']) === MFA_IS_NOT_ENROLLED_ERROR;
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * Handle an edge case when the user should NOT step up, so the response including the generated stepped-up jwt
   * Relevant for scenarios like login with magic code, no other mfa enrolled, allowed to skip mfa and max age is valid
   * @param generateResponse
   * @private
   */
  const __handleNoNeedToStepUpFlow = async generateResponse => {
    await actions.afterAuthenticationStateUpdate(generateResponse);
    await actions.afterStepUpAuthNavigation();
  };

  /**
   * Handle the common scenario when the user should step up by the mfa token and devices returned from the generate req
   * @param generateStepUpSessionResponse
   * @private
   */
  const __handleNeedToStepUpFlow = async ({
    mfaToken,
    mfaDevices
  }) => {
    actions.setStepUpState({
      mfaDevices,
      mfaToken
    });

    // to handle scenarios when sms, email or authenticator are the only options - show immediately the enrolled one
    const step = await getMfaStepForEnrolledUsers(mfaDevices);
    actions.setMfaState({
      step
    });
  };

  /**
   * Handle step up generation request error
   * @param error
   * @private
   */
  const __handleStepUpError = async error => {
    if (isMfaIsNotEnrolledError(error)) {
      actions.setMfaState({
        step: MFAStep.noMFAEnrolledStepUp
      });
      return;
    }
    window.localStorage.setItem(SHOULD_STEP_UP_KEY, 'true');
    const routes = store.auth.routes;
    ContextHolder.for(store.root.appName).onRedirectTo(routes.logoutUrl, {
      preserveQueryParams: true
    });
  };

  /**
   * Generate step up session
   * Logout on error and redirecting to the login page
   * @param payload.maxAge - The max age of the session in seconds
   * @param payload.callback - The callback function to be called after the request is done
   */
  const generateStepUpSession = async payload => {
    const {
      maxAge,
      callback
    } = payload;
    actions.setStepUpState({
      mfaDevices: undefined,
      mfaToken: ''
    });
    try {
      const response = await api.auth.generateStepupSession({
        maxAge
      });
      if (response.hasOwnProperty('user')) {
        // got stepped up jwt, no need to continue step up by a second factor
        await __handleNoNeedToStepUpFlow(response);
      } else {
        // regular step up flow
        await __handleNeedToStepUpFlow(response);
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      callback == null ? void 0 : callback(false);
      await __handleStepUpError(e);
    }
  };
  return {
    generateStepUpSession
  };
});