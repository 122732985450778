import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class SubscriptionTenantConfigApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getTenantConfiguration = async tenantId => {
      return this.get(`${urls.subscriptions.billing.tenantConfiguration.v1}/${tenantId}`);
    };

    this.createTenantConfiguration = async request => {
      return this.post(urls.subscriptions.billing.tenantConfiguration.v1, request);
    };
  }

}