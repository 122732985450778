import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["events", "url"];
import { SignUpStage } from './interfaces';
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { ContextHolder } from '@frontegg/rest-api';
import { extractPhoneNumber, isMfaRequired } from '../helpers';
import { AuthenticationTypes } from '../interfaces';
import { GTMEventAction, reportGTMEvent } from '../../helpers/gtm';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const contextHolder = ContextHolder.for(store.root.appName);
  const setSignUpState = state => {
    Object.assign(store.auth.signUpState, state);
  };
  const resetSignUpState = () => {
    deepResetState(store, ['auth', 'signUpState'], initialState);
  };
  const resetSignUpStateSoft = () => {
    const {
      allowSignUps,
      allowNotVerifiedUsersLogin
    } = store.auth.signUpState;
    resetSignUpState();
    setSignUpState({
      allowSignUps,
      allowNotVerifiedUsersLogin
    });
  };
  const signUpUser = async _payload => {
    const {
        events,
        url
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    setSignUpState({
      loading: true
    });
    try {
      const {
        routes,
        onRedirectTo
      } = store.auth;
      const {
        shouldActivate,
        user,
        tenantId,
        userId,
        tenants = [],
        activeTenant
      } = await api.auth.signUpUser(
      //Currently we are not supporting phone number in signup in the backend
      //Remove this line when we will support it (also make sure when field is optional no empty string is sent)
      extractPhoneNumber(payload));
      if (!payload.invitationToken) {
        var _events$signUpComplet;
        const {
          email,
          name,
          companyName,
          phoneNumber
        } = payload;
        const signUpCompletePayload = {
          email,
          name,
          companyName,
          url,
          phoneNumber: phoneNumber || undefined,
          authenticationType: AuthenticationTypes.PASSWORD,
          id: userId,
          tenantId,
          createdAt: new Date()
        };
        events == null ? void 0 : (_events$signUpComplet = events.signUpComplete) == null ? void 0 : _events$signUpComplet.call(events, signUpCompletePayload);
        reportGTMEvent(GTMEventAction.SIGNUP_COMPLETED, signUpCompletePayload);
      }
      if (!shouldActivate && user && isMfaRequired(user, store.root.appName)) {
        const mfaRequiredState = await actions.getMfaRequiredState(user);
        actions.setAuthState(mfaRequiredState);
        onRedirectTo(routes.loginUrl);
      } else {
        const tenantsState = store.auth.tenantsState;
        setSignUpState({
          loading: false,
          shouldActivate,
          stage: SignUpStage.SignUpSuccess
        });
        actions.setAuthState({
          user,
          isAuthenticated: !!(user != null && user.accessToken),
          tenantsState: _extends({}, tenantsState, {
            tenants,
            activeTenant
          })
        });
      }
    } catch (e) {
      contextHolder.setAccessToken(null);
      contextHolder.setUser(null);
      setSignUpState({
        error: errorHandler(e)
      });
    } finally {
      setSignUpState({
        loading: false
      });
    }
  };
  const loadAllowSignUps = async () => {
    setSignUpState({
      loading: true
    });
    try {
      const policy = await api.auth.getVendorConfig();
      const policyWithCustomLoginAuthStrategy = await actions.__getSecurityPolicyPublicStateWithCustomLogin(policy);
      const {
        allowSignups: allowSignUps,
        allowNotVerifiedUsersLogin
      } = policy;
      setSignUpState({
        loading: false,
        allowSignUps,
        allowNotVerifiedUsersLogin,
        firstLoad: false
      });
      actions.setSecurityPolicyPublicState({
        policy: policyWithCustomLoginAuthStrategy,
        loading: false
      });
    } catch (e) {
      setSignUpState({
        loading: false,
        error: errorHandler(e),
        firstLoad: false
      });
    }
  };
  return {
    setSignUpState,
    resetSignUpState,
    resetSignUpStateSoft,
    signUpUser,
    loadAllowSignUps
  };
});