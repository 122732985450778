import { urls } from "../constants";
import { BaseApiClient } from '../BaseApiClient';
export class ApplicationsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getUserApplicationsId = async ({
      userId
    }) => {
      return this.get(`${urls.identity.applications.v1}/${userId}/apps`);
    };

    this.getUsersApplicationsId = async ({
      userIds
    }) => {
      return this.get(`${urls.identity.applications.v1}/users-apps`, {
        userIds: userIds.join(',')
      });
    };

    this.getTenantsApplications = async () => {
      return this.get(urls.applications.tenant.v1);
    };

    this.getApplicationsData = async ({
      appIds,
      includeFreeAccess = true
    }) => {
      return this.get(urls.applications.v1, {
        ids: appIds.join(','),
        _includeFreeAccess: includeFreeAccess
      });
    };

    this.assignUserToApplications = async body => {
      return this.post(`${urls.identity.applications.v1}/apps-user`, body);
    };

    this.unassignUserFromApplications = async body => {
      return this.delete(`${urls.identity.applications.v1}/user-apps`, body);
    };

    this.getUsersOfApplications = async ({
      appIds
    }) => {
      return this.get(`${urls.identity.applications.v1}/apps-users`, {
        appIds: appIds.join(',')
      });
    };
  }

}
export default new ApplicationsApi('default');