import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback", "phoneId"],
  _excluded4 = ["callback", "phoneId"],
  _excluded5 = ["callback"];
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setSmsState = state => {
    Object.assign(store.auth.smsState, state);
  };
  const resetSmsState = () => {
    deepResetState(store, ['auth', 'smsState'], initialState);
  };
  const loadPhoneNumbers = async () => {
    setSmsState({
      loading: true
    });
    const queryParams = {
      _limit: 10,
      _offset: 0
    };
    try {
      const {
        items
      } = await api.userPhoneNumbers.getUserPhoneNumbers(queryParams);
      setSmsState({
        phoneNumbers: items
      });
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  const setUpPhoneNumber = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    setSmsState({
      loading: true
    });
    try {
      const {
        otcToken
      } = await api.userPhoneNumbers.createPhoneNumber(payload);
      setSmsState({
        otcToken
      });
      await loadPhoneNumbers();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  const verifyPhoneNumber = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    setSmsState({
      loading: true
    });
    try {
      await api.userPhoneNumbers.verifyPhoneNumber(payload);
      await loadPhoneNumbers();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  const deletePhoneNumber = async _payload => {
    const {
        callback,
        phoneId
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded3);
    setSmsState({
      loading: true
    });
    try {
      const {
        otcToken
      } = await api.userPhoneNumbers.deletePhoneNumber(phoneId);
      setSmsState({
        otcToken
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  const verifyDeletePhoneNumber = async _payload => {
    const {
        callback,
        phoneId
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded4);
    setSmsState({
      loading: true
    });
    try {
      await api.userPhoneNumbers.verifyDeletePhoneNumber(phoneId, payload);
      await loadPhoneNumbers();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  const preVerifyPhoneNumber = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded5);
    setSmsState({
      loading: true
    });
    try {
      const {
        otcToken
      } = await api.userPhoneNumbers.preVerifyPhoneNumber(payload);
      setSmsState({
        otcToken
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSmsState({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    } finally {
      setSmsState({
        loading: false
      });
    }
  };
  return {
    setSmsState,
    resetSmsState,
    loadPhoneNumbers,
    setUpPhoneNumber,
    verifyPhoneNumber,
    deletePhoneNumber,
    verifyDeletePhoneNumber,
    preVerifyPhoneNumber
  };
});