import { buildTenantsActions } from '../../auth/TenantsState';
import { tenantsDemo } from '../dummy';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildTenantsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setTenantsState', 'resetTenantsState']);
  mockedActions.loadTenants = async () => {
    mockedActions.setTenantsState({
      loading: true
    });
    mockedActions.setTenantsState({
      tenants: tenantsDemo,
      activeTenant: tenantsDemo[0],
      loading: false
    });
  };
  return mockedActions;
});