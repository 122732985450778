import { createProxy } from '../../../toolkit/proxy';
export const initialState = {
  loading: false,
  error: null,
  fetching: true,
  cancellation: {
    loading: false,
    error: null
  },
  renewal: {
    loading: false,
    error: null
  }
};
export default (overrideState => createProxy(initialState, overrideState));