import _extends from "@babel/runtime/helpers/esm/extends";
import { mockActionsExpect } from '../helpers';
import { buildPlansActions } from '../../subscriptions/Plans';
import { delay } from '../../helpers';
import { planResponseMock } from './dummy';
export default ((store, api, actions) => {
  const originalActions = buildPlansActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setPlansState', 'resetPlansState']);
  mockedActions.loadPlans = async () => {
    mockedActions.setPlansState({
      loading: true
    });
    await delay(500);
    mockedActions.setPlansState({
      fetching: false,
      loading: false,
      plans: planResponseMock.map(item => {
        var _item$price, _item$price2;
        return _extends({}, item, {
          price: ((_item$price = item.price) == null ? void 0 : _item$price.amount) || 0,
          currency: ((_item$price2 = item.price) == null ? void 0 : _item$price2.currency) || 'usd',
          recurringInterval: 'month'
        });
      })
    });
  };
  return mockedActions;
});