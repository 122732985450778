export let TenantSortByEnum;

(function (TenantSortByEnum) {
  TenantSortByEnum["createdAt"] = "createdAt";
  TenantSortByEnum["name"] = "name";
  TenantSortByEnum["tenantId"] = "tenantId";
})(TenantSortByEnum || (TenantSortByEnum = {}));

export let TenantType;

(function (TenantType) {
  TenantType["CUSTOMER"] = "customer";
  TenantType["SPAM"] = "spam";
  TenantType["TEST"] = "test";
  TenantType["RESELLER"] = "reseller";
  TenantType["HEADQUARTER"] = "headquarter";
})(TenantType || (TenantType = {}));