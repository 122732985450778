import _extends from "@babel/runtime/helpers/esm/extends";
import { initialState } from './state';
import { deepResetState, errorHandler } from '../../../helpers';
import { SubscriptionStatus } from '../../interfaces';
import { toSubscriptionCancellation, toSubscriptionStatus } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setBillingInformationState = state => {
    Object.assign(store.subscriptions.billing.information, state);
  };
  const resetBillingInformationState = () => {
    deepResetState(store, ['subscriptions', 'billing', 'information'], initialState);
  };
  const setInformationError = error => {
    setBillingInformationState({
      error,
      loading: false,
      fetching: false
    });
  };
  const loadSummaries = async (tenantId, forceActive) => {
    setBillingInformationState({
      loading: true
    });
    try {
      var _planResponse$price, _planResponse$price2;
      const summary = await api.subscriptions.getSubscriptionSummaries(tenantId);
      const {
        currentPlanId,
        externallyManaged
      } = summary;
      let subscriptionResponse = null;
      let planResponse;
      if (!externallyManaged) {
        // @ts-ignore // TODO: fix type in rest-api
        [, planResponse] = await Promise.all([actions.loadSubscription(), api.subscriptions.getSubscriptionPlan(currentPlanId)]);
        subscriptionResponse = store.subscriptions.billing.subscription.subscription || null;
      } else {
        // @ts-ignore // TODO: fix type in rest-api
        planResponse = await api.subscriptions.getSubscriptionPlan(currentPlanId);
      }
      setBillingInformationState(_extends({
        loading: false,
        fetching: false,
        summary
      }, subscriptionResponse ? {
        subscription: {
          id: subscriptionResponse.id,
          externalId: subscriptionResponse.externalId,
          startDate: subscriptionResponse.startDate,
          currentPeriodStart: subscriptionResponse.currentPeriodStart,
          currentPeriodEnd: subscriptionResponse.currentPeriodEnd,
          status: forceActive ? SubscriptionStatus.ACTIVE : toSubscriptionStatus(subscriptionResponse.status),
          cancellation: subscriptionResponse.cancellation && toSubscriptionCancellation(subscriptionResponse.cancellation),
          trialEnd: subscriptionResponse.trialEnd ? subscriptionResponse.trialEnd : null
        }
      } : {}, planResponse ? {
        plan: {
          id: planResponse.id,
          name: planResponse.name,
          description: planResponse.description,
          price: ((_planResponse$price = planResponse.price) == null ? void 0 : _planResponse$price.amount) || 0,
          currency: ((_planResponse$price2 = planResponse.price) == null ? void 0 : _planResponse$price2.currency) || 'usd',
          recurringInterval: 'month',
          slug: planResponse.slug
        }
      } : {}));
    } catch (e) {
      setInformationError(errorHandler(e));
    }
  };
  const loadBillingInformation = async forceActive => {
    var _store$subscriptions$, _store$auth$user;
    const paymentProvider = (_store$subscriptions$ = store.subscriptions.config.config) == null ? void 0 : _store$subscriptions$.paymentProvider;
    const tenantId = (_store$auth$user = store.auth.user) == null ? void 0 : _store$auth$user.tenantId;
    if (!paymentProvider || !tenantId) {
      setInformationError(!paymentProvider ? 'Internal feature failure' : 'Not authorized');
      return;
    }
    await loadSummaries(tenantId, forceActive);
  };
  return {
    setBillingInformationState,
    resetBillingInformationState,
    loadSummaries,
    loadBillingInformation
  };
});