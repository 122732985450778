import { createProxy } from '../toolkit/proxy';
export const initialState = {
  isLoading: false,
  isSaving: false,
  list: [],
  processIds: [],
  slackChannels: {
    isLoading: false
  }
};
export default (overrideState => createProxy(initialState, overrideState));