import { mockActionsExpect } from '../helpers';
import { buildVendorPublicConfigActions } from '../../subscriptions/VendorPublicConfig';
import { delay } from '../../helpers';
import { vendorPublicConfigurationResponseMock } from './dummy';
export default ((store, api, actions) => {
  const originalActions = buildVendorPublicConfigActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setVendorPublicConfigState', 'resetVendorPublicConfigState', 'setVendorPublicConfigError', 'setVendorPublicConfigLoading']);
  mockedActions.loadVendorPublicConfiguration = async () => {
    mockedActions.setVendorPublicConfigLoading(true);
    await delay();
    mockedActions.setVendorPublicConfigState({
      fetching: false,
      loading: false,
      vendorPublicConfig: vendorPublicConfigurationResponseMock
    });
  };
  return mockedActions;
});