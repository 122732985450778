import { isEntitlementsDeeplyEqual } from './helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setEntitlements = entitlements => {
    if (store.auth.user) {
      store.auth.user.entitlements = entitlements;
    }
  };
  const handleFetchedEntitlements = newEntitlementsResponse => {
    var _store$auth$user;
    // @ts-ignore // TODO: fix type UserEntitlementsResponseV2 type
    const oldEntitlements = (_store$auth$user = store.auth.user) == null ? void 0 : _store$auth$user.entitlements;
    if (isEntitlementsDeeplyEqual(oldEntitlements, newEntitlementsResponse)) {
      return;
    }
    setEntitlements(newEntitlementsResponse);
  };

  /**
   * Load entitlements data for saga action
   * Don't update entitlements data in the store when data is equal (deep check) to the existing store data
   * @param payload saga payload action including a payload with/out a callback
   *                The callback will be called on request completed with true if succeeded, false if failed
   */
  const loadEntitlements = async payload => {
    const callback = payload == null ? void 0 : payload.callback;
    try {
      const entitlements = await api.entitlements.loadEntitlementsV2();
      handleFetchedEntitlements(entitlements);
      callback == null ? void 0 : callback(true);
    } catch (e) {
      callback == null ? void 0 : callback(false);
    }
  };
  return {
    setEntitlements,
    handleFetchedEntitlements,
    loadEntitlements
  };
});