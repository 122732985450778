import { initialState } from './state';
import { deepResetState, errorHandler } from '../../helpers';
export default ((store, api, sharedActions) => {
  const setStripeState = state => {
    Object.assign(store.subscriptions.stripe, state);
  };
  const resetStripeState = () => {
    deepResetState(store, ['subscriptions', 'stripe'], initialState);
  };
  const setStripeError = error => {
    Object.assign(store.subscriptions.stripe, {
      error,
      loading: false,
      fetching: false
    });
  };
  const createCardSetupIntentSecret = async payload => {
    setStripeState({
      loading: true,
      error: null,
      cardSetupIntentSecret: null
    });
    try {
      const request = {};
      if (payload) {
        request.paymentMethodId = payload;
      }
      const {
        setupIntentSecret
      } = await api.subscriptions.createStripePaymentMethodSetupIntentSecret(request);
      setStripeState({
        cardSetupIntentSecret: setupIntentSecret,
        loading: false
      });
    } catch (e) {
      setStripeError(errorHandler(e));
    }
  };
  return {
    // loadCustomer, // not implemented
    createCardSetupIntentSecret,
    setStripeState,
    resetStripeState
  };
});