import { urls } from '../../../constants';
import { BaseApiClient } from '../../../BaseApiClient';
export class StripeProviderApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.createSubscription = async request => {
      return this.post(urls.subscriptions.billing.paymentProviders.stripe.subscriptions.v1, request);
    };

    this.getStripeCustomer = async tenantId => {
      return this.get(`${urls.subscriptions.billing.paymentProviders.stripe.customers.v1}/${tenantId}`);
    };

    this.createStripeCustomer = async request => {
      return this.post(urls.subscriptions.billing.paymentProviders.stripe.customers.v1, request);
    };

    this.getStripePaymentProviderConfiguration = async () => {
      return this.get(urls.subscriptions.billing.paymentProviders.stripe.publicConfigurations.v1);
    };

    this.createStripePaymentMethodSetupIntentSecret = async request => {
      return this.post(urls.subscriptions.billing.paymentProviders.stripe.setupIntents.v1, request);
    };
  }

}