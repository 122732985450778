import _extends from "@babel/runtime/helpers/esm/extends";
import getValue from 'get-value';
import setValue from 'set-value';
export const delay = (ms = 500) => new Promise(resolve => {
  setTimeout(resolve, ms);
});
export const retry = async (asyncFunc, retryCount, delay) => {
  let attempts = 0;
  while (attempts < retryCount - 1) {
    try {
      return await asyncFunc();
    } catch (error) {
      attempts++;
      if (attempts >= retryCount) {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  return await asyncFunc();
};
export function omitProps(props, keys) {
  const newProps = _extends({}, props);
  keys.forEach(key => {
    delete newProps[key];
  });
  return newProps;
}
function shouldBeCloneable(o) {
  const type = typeof o;
  return (o == null ? void 0 : o.constructor) === {}.constructor || type === 'undefined' || o === null || type === 'boolean' || type === 'number' || type === 'string' || o instanceof Date || o instanceof RegExp || o instanceof Blob || o instanceof File || o instanceof FileList || o instanceof ArrayBuffer || o instanceof ImageData || o instanceof ImageBitmap || o instanceof Array || o instanceof Map || o instanceof Set;
}

/**
 * Check if the object is a proxy object to avoid cloning it
 */
export function isProxy(obj) {
  if (obj === null || obj === undefined) {
    return;
  }
  const _shouldBeCloneable = shouldBeCloneable(obj);
  return _shouldBeCloneable && obj.__isProxy !== undefined;
}
export const deepClone = objectToClone => {
  return JSON.parse(JSON.stringify(objectToClone));
};

/**
 * Used to reset the state of the store to the initial state
 * @param store - the store object
 * @param statePath - the path to the state that needs to be reset
 * @param initialState - the initial state object
 */
export const deepResetState = (store, statePath, initialState) => {
  const initialLoginState = deepClone(initialState);
  const currentState = getValue(store, statePath);
  for (const key in currentState) {
    if (key === '__isProxy') {
      continue;
    }
    if (key in initialLoginState) {
      setValue(store, [...statePath, key], initialLoginState[key]);
    } else {
      setValue(store, [...statePath, key], undefined);
    }
  }
};