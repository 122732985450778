import _extends from "@babel/runtime/helpers/esm/extends";
import { deepResetState } from '../../../helpers';
import { initialState } from '../state';
import generateStepUpSessionActions from './generateStepUpSession.actions';
import stepUpHostedLoginActions from './stepUpHostedLogin.actions';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setStepUpState = state => {
    Object.assign(store.auth.stepUpState, state);
  };
  const resetStepUpState = () => {
    deepResetState(store, ['auth', 'stepUpState'], initialState);
  };

  /**
   * Step up with authenticator app
   * @param payload.callback - callback function to be called after the verification is done
   * @param payload.mfaToken
   * @param payload.value - 6 digits code input by the user
   */
  const stepUpWithAuthenticator = async payload => {
    await actions.mfaWithAuthenticator(payload, actions.setStepUpState, true);
  };

  /**
   * Pre verify step for MFA SMS step up
   * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.mfaToken
   */
  const preVerifyMFASMSForStepUp = async payload => {
    await actions.preVerifyMFASMS(payload, actions.setStepUpState);
  };

  /**
   * Verify step for MFA SMS step up
   * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.otcToken
   * @param payload.code - 6 digits code input by the user
   */
  const verifyMFASMSForStepUp = async payload => {
    await actions.verifyMFASMS(payload, actions.setStepUpState, true);
  };

  /**
   * Pre verify step for MFA Webauthn step up
   * @param payload.callback - callback function to be called after the verification is done
   * @param payload.deviceId
   * @param payload.mfaToken
   */
  const preVerifyMFAWebAuthnForStepUp = async payload => {
    await actions.preVerifyMFAWebAuthn(payload, actions.setStepUpState);
  };

  /**
   * Verify step for MFA Webauthn step up
   * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.webauthnToken
   * @param payload.mfaToken
   * @param payload.publicKey - public key object from browser navigator credentials
   * @param payload.code - 6 digits code input by the user
   */
  const verifyMFAWebAuthnForStepUp = async payload => {
    await actions.verifyMFAWebAuthn(payload, actions.setStepUpState, true);
  };

  /**
   * Pre verify step for MFA Email code step up
   * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
   * @param payload.mfaToken
   */
  const preVerifyMFAEmailCodeForStepUp = async payload => {
    await actions.preVerifyMFAEmailCode(payload, actions.setStepUpState);
  };

  /**
   * Verify step for MFA Email code step up
   * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
   * @param payload.otcToken
   * @param payload.code - 6 digits code input by the user
   */
  const verifyMFAEmailCodeForStepUp = async payload => {
    await actions.verifyMFAEmailCode(payload, actions.setStepUpState);
  };
  return _extends({
    setStepUpState,
    resetStepUpState,
    stepUpWithAuthenticator,
    preVerifyMFASMSForStepUp,
    verifyMFASMSForStepUp,
    preVerifyMFAWebAuthnForStepUp,
    verifyMFAWebAuthnForStepUp,
    preVerifyMFAEmailCodeForStepUp,
    verifyMFAEmailCodeForStepUp
  }, generateStepUpSessionActions(store, api, sharedActions), stepUpHostedLoginActions(store, api, sharedActions));
});