import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["type"],
      _excluded2 = ["type"],
      _excluded3 = ["type"];
export * from "./secutiry-poilicy";
export * from "./enums";
export { FRONTEGG_SEPARATE_TABS_BY_TENANT } from "./constants";
export { setTabTenantInSessionStorage, getTabTenantFromSessionStorage, getCurrentUserTenantsFunction, removeTabTenantFromSessionStorage } from './utils';
import { urls } from "../constants";
import { jwtDecode } from "../jwt";
import { LOAD_AUTHORIZATION_FF } from './interfaces';
import { ADMIN_PORTAL_ENTITLEMENTS_FF } from '../entitlements/interfaces';
import { FeatureFlags } from "../feature-flags";
import { executeConditionalPromise, getCurrentUserTenantsFunction, getTabTenantFromSessionStorage, setTabTenantInSessionStorage } from "./utils";
import { TenantsApi } from "../tenants";
import { BaseApiClient } from '../BaseApiClient';
import { EntitlementsApi } from '../entitlements';
import { ContextHolder } from '../ContextHolder';
import { SecurityPolicyApi } from './secutiry-poilicy';
export class AuthenticationApi extends BaseApiClient {
  constructor(appName) {
    super(appName);
    this.tenantsApi = new TenantsApi(this.appName);
    this.entitlementsApi = new EntitlementsApi(this.appName);
    this.securityPolicy = new SecurityPolicyApi(this.appName);

    this.generateLoginResponse = async loginResponse => {
      if (!loginResponse.accessToken) {
        return loginResponse;
      }

      const contextHolder = ContextHolder.for(this.appName);
      contextHolder.setAccessToken(loginResponse.accessToken);
      const me = await this.getMeAndEntitlements();
      const decodedContent = loginResponse.accessToken ? jwtDecode(loginResponse.accessToken) : {};

      const user = _extends({}, loginResponse, decodedContent, me);

      contextHolder.setUser(user);
      return user;
    };

    this.generateLoginResponseV2 = async loginResponse => {
      if (!loginResponse.accessToken) {
        return {
          user: loginResponse
        };
      }

      const contextHolder = ContextHolder.for(this.appName);
      contextHolder.setAccessToken(loginResponse.accessToken);
      const [me, tenants] = await Promise.all([this.getMeAndEntitlements(), this.tenantsApi.getTenants()]);
      const decodedContent = loginResponse.accessToken ? jwtDecode(loginResponse.accessToken) : {};

      const user = _extends({}, loginResponse, decodedContent, me);

      contextHolder.setUser(user);
      return {
        user,
        tenants
      };
    };

    this.generateLoginResponseV3 = async loginResponse => {
      const {
        accessToken
      } = loginResponse;

      if (!accessToken) {
        return {
          user: loginResponse
        };
      }

      const contextHolder = ContextHolder.for(this.appName);
      contextHolder.setAccessToken(accessToken);
      const [me, currentUserTenants] = await Promise.all([this.getMeAndEntitlements(), getCurrentUserTenantsFunction(this.appName)()]);
      const decodedContent = accessToken ? jwtDecode(accessToken) : {};

      const user = _extends({}, loginResponse, decodedContent, me);

      if (contextHolder.isSessionPerTenantEnabled()) {
        setTabTenantInSessionStorage(user.tenantId);
      }

      contextHolder.setUser(user);
      return {
        user,
        tenants: currentUserTenants.tenants,
        activeTenant: currentUserTenants.activeTenant
      };
    };

    this.generateLoginResponseFromOAuthResponse = async oauthResponse => {
      const contextHolder = ContextHolder.for(this.appName);
      contextHolder.setAccessToken(oauthResponse.id_token);
      const me = await this.getMeAndEntitlements();
      const decodedContent = oauthResponse.id_token ? jwtDecode(oauthResponse.id_token) : {};

      const user = _extends({
        mfaRequired: false,
        accessToken: oauthResponse.id_token,
        refreshToken: oauthResponse.refresh_token
      }, decodedContent, me, {
        expiresIn: oauthResponse.expires_in || 300
      });

      contextHolder.setUser(user);
      return user;
    };

    this.generateLoginResponseFromOAuthResponseV2 = async oauthResponse => {
      const contextHolder = ContextHolder.for(this.appName);
      contextHolder.setAccessToken(oauthResponse.id_token);
      const [me, currentUserTenants] = await Promise.all([this.getMeAndEntitlements(), getCurrentUserTenantsFunction(this.appName)()]);
      const decodedContent = oauthResponse.id_token ? jwtDecode(oauthResponse.id_token) : {};

      const user = _extends({
        mfaRequired: false,
        accessToken: oauthResponse.id_token,
        refreshToken: oauthResponse.refresh_token
      }, decodedContent, me, {
        expiresIn: oauthResponse.expires_in || 300
      });

      if (contextHolder.isSessionPerTenantEnabled()) {
        setTabTenantInSessionStorage(user.tenantId);
      }

      contextHolder.setUser(user);
      return {
        user,
        tenants: currentUserTenants.tenants,
        activeTenant: currentUserTenants.activeTenant
      };
    };

    this.preLogin = async body => {
      try {
        const {
          address
        } = await this.post(`${urls.identity.auth.v1}/user/saml/prelogin`, body);
        return address;
      } catch {
        return null;
      }
    };

    this.preLoginWithIdpType = async body => {
      return this.post(`${urls.identity.auth.v1}/user/saml/prelogin`, body);
    };

    this.postLogin = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/saml/postlogin`, body);
      return this.generateLoginResponse(data);
    };

    this.oidcPostLogin = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/oidc/postlogin`, body);
      return this.generateLoginResponse(data);
    };

    this.login = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user`, body);
      return this.generateLoginResponse(data);
    };

    this.loginv2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user`, body);
      return this.generateLoginResponseV3(data);
    };

    this.loginWithMfa = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/verify`, body);
      return this.generateLoginResponse(data);
    };

    this.loginWithMfaV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.activateAccount = async body => {
      return this.post(`${urls.identity.users.v1}/activate`, body);
    };

    this.activateAccountV2 = async body => {
      const data = await this.post(`${urls.identity.users.v1}/activate`, body);
      return this.generateLoginResponseV3(data);
    };

    this.activateAccountWithOTC = async body => {
      const data = await this.post(`${urls.identity.users.v1}/activate/code`, body);
      return this.generateLoginResponseV3(data);
    };

    this.getActivateAccountStrategy = async params => {
      return this.get(`${urls.identity.users.v1}/activate/strategy`, params);
    };

    this.resendActivationEmail = async body => {
      return this.post(`${urls.identity.users.v1}/activate/reset`, body);
    };

    this.resendInvitationEmail = async body => {
      return this.post(`${urls.identity.users.v1}/invitation/reset`, body);
    };

    this.acceptInvitation = async body => {
      return this.post(`${urls.identity.users.v1}/invitation/accept`, body);
    };

    this.acceptInvitationWithOTC = async body => {
      return this.post(`${urls.identity.users.v1}/invitation/accept/code`, body);
    };

    this.refreshToken = async () => {
      const tabTenantId = getTabTenantFromSessionStorage(this.appName);
      const data = await this.post(`${urls.identity.auth.v1}/user/token/refresh`, {
        tenantId: tabTenantId
      });
      return this.generateLoginResponse(data);
    };

    this.refreshTokenV2 = async () => {
      const tabTenantId = getTabTenantFromSessionStorage(this.appName);
      const data = await this.post(`${urls.identity.auth.v1}/user/token/refresh`, {
        tenantId: tabTenantId
      });
      return this.generateLoginResponseV2(data);
    };

    this.refreshTokenV3 = async () => {
      const tabTenantId = getTabTenantFromSessionStorage(this.appName);
      const data = await this.post(`${urls.identity.auth.v1}/user/token/refresh`, {
        tenantId: tabTenantId
      });
      return this.generateLoginResponseV3(data);
    };

    this.logout = async () => {
      return this.post(`${urls.identity.auth.v1}/logout`);
    };

    this.OAuthLogout = async params => {
      return this.get(`${urls.oauth.v1}/logout`, params);
    };

    this.forgotPassword = async body => {
      return this.post(`${urls.identity.users.v1}/passwords/reset`, body);
    };

    this.resetPassword = async body => {
      return this.post(`${urls.identity.users.v1}/passwords/reset/verify`, body);
    };

    this.loadPasswordConfig = async params => {
      return this.get(`${urls.identity.users.v1}/passwords/config`, params);
    };

    this.recoverMfaToken = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/recover`, body);
    };

    this.enrollMfa = async () => {
      return this.post(`${urls.identity.users.v1}/mfa/enroll`);
    };

    this.preEnrollMFAAuthenticatorApp = async () => {
      return this.post(`${urls.identity.users.v1}/mfa/authenticator/enroll`);
    };

    this.enrollMFAAuthenticatorApp = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/authenticator/enroll/verify`, body);
    };

    this.preEnrollMFASMS = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/sms/enroll`, body);
    };

    this.enrollMFASMS = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/sms/enroll/verify`, body);
    };

    this.preEnrollMFAWebAuthn = async () => {
      return this.post(`${urls.identity.users.v1}/mfa/webauthn/enroll`);
    };

    this.enrollMFAWebAuthn = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/webauthn/enroll/verify`, body);
    };

    this.verifyMfa = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/enroll/verify`, body);
    };

    this.disableMfa = async body => {
      return this.post(`${urls.identity.users.v1}/mfa/disable`, body);
    };

    this.disableMFAAuthenticatorApp = async (deviceId, body) => {
      return this.post(`${urls.identity.users.v1}/mfa/authenticator/${deviceId}/disable/verify`, body);
    };

    this.preDisableMFASMS = async deviceId => {
      return this.post(`${urls.identity.users.v1}/mfa/sms/${deviceId}/disable`);
    };

    this.disableMFASMS = async (deviceId, body) => {
      return this.post(`${urls.identity.users.v1}/mfa/sms/${deviceId}/disable/verify`, body);
    };

    this.preDisableMFAWebAuthn = async deviceId => {
      return this.post(`${urls.identity.users.v1}/mfa/webauthn/${deviceId}/disable`);
    };

    this.disableMFAWebAuthn = async (deviceId, body) => {
      return this.post(`${urls.identity.users.v1}/mfa/webauthn/${deviceId}/disable/verify`, body);
    };

    this.getMFADevices = async () => {
      return this.get(`${urls.identity.users.v1}/mfa/devices`);
    };

    this.preEnrollMFAAuthenticatorAppForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/authenticator/enroll`, body);
    };

    this.enrollMFAAuthenticatorAppForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/authenticator/enroll/verify`, body);
    };

    this.enrollMFAAuthenticatorAppForLoginV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/authenticator/enroll/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.verifyMFAAuthenticatorAppForLogin = async (deviceId, body) => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/authenticator/${deviceId}/verify`, body);
    };

    this.preVerifyMFAEmailCode = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/emailcode`, body);
    };

    this.verifyMFAEmailCode = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/emailcode/verify`, body);
    };

    this.verifyMFAEmailCodeV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/emailcode/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.preEnrollMFASMSForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/sms/enroll`, body);
    };

    this.enrollMFASMSForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/sms/enroll/verify`, body);
    };

    this.enrollMFASMSForLoginV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/sms/enroll/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.preVerifyMFASMSForLogin = async (deviceId, body) => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/sms/${deviceId}`, body);
    };

    this.verifyMFASMSForLogin = async (deviceId, body) => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/sms/${deviceId}/verify`, body);
    };

    this.verifyMFASMSForLoginV2 = async (deviceId, body) => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/sms/${deviceId}/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.preEnrollMFAWebAuthnForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/enroll`, body);
    };

    this.enrollMFAWebAuthnForLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/enroll/verify`, body);
    };

    this.enrollMFAWebAuthnForLoginV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/enroll/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.preVerifyMFAWebAuthnForLogin = async (deviceId, body) => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}`, body);
    };

    this.verifyMFAWebAuthnForLogin = async (deviceId, body) => {
      return this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}/verify`, body);
    };

    this.verifyMFAWebAuthnForLoginV2 = async (deviceId, body) => {
      const data = await this.post(`${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}/verify`, body);
      return this.generateLoginResponseV3(data);
    };

    this.getSamlConfiguration = async () => {
      return this.get(`${urls.team.sso.v1}/saml/configurations`);
    };

    this.updateSamlConfiguration = async body => {
      return this.post(`${urls.team.sso.v1}/saml/configurations`, body);
    };

    this.getSamlVendorConfiguration = async () => {
      return this.get(`${urls.team.sso.v1}/saml/configurations/vendor-config`);
    };

    this.updateSamlVendorMetadata = async body => {
      return this.put(`${urls.team.sso.v1}/saml/configurations/metadata`, body);
    };

    this.validateSamlDomain = async () => {
      return this.put(`${urls.team.sso.v1}/saml/validations/domain`);
    };

    this.getSamlRoles = async () => {
      return this.get(`${urls.team.sso.v1}/saml/configurations/roles/default`);
    };

    this.getSamlRolesGroups = async () => {
      return this.get(`${urls.team.sso.v2}/saml/configurations/groups`);
    };

    this.updateSamlRoles = async ({
      roleIds
    }) => {
      return this.post(`${urls.team.sso.v1}/saml/configurations/roles/default`, {
        roleIds
      });
    };

    this.createSamlGroup = async ({
      roleIds,
      group
    }) => {
      return this.post(`${urls.team.sso.v2}/saml/configurations/groups`, {
        group,
        roleIds
      });
    };

    this.updateSamlGroup = async ({
      roleIds,
      group,
      id
    }) => {
      return this.patch(`${urls.team.sso.v2}/saml/configurations/groups/${id}`, {
        group,
        roleIds
      });
    };

    this.deleteSamlGroup = async ({
      id
    }) => {
      return this.delete(`${urls.team.sso.v2}/saml/configurations/groups/${id}`);
    };

    this.getOidcConfiguration = async () => {
      return this.get(`${urls.team.sso.v1}/oidc/configurations`);
    };

    this.getSSOPublicConfiguration = async () => {
      return this.get(`${urls.team.sso.v2}/configurations/public`);
    };

    this.getSocialLoginProviders = async () => {
      return this.get(urls.identity.sso.v1);
    };

    this.getSocialLoginProvidersV2 = async () => {
      return this.get(urls.identity.sso.v2);
    };

    this.getSocialLoginProvidersV2ForAuthenticatedUser = async () => {
      return this.get(`${urls.identity.sso.v2}/authenticated`);
    };

    this.getCustomSocialLoginProvidersV1 = async () => {
      return this.get(urls.identity.sso.custom.v1);
    };

    this.loginViaSocialLogin = async params => {
      const queryParams = {};

      if (params.code) {
        queryParams.code = params.code;
      }

      if (params.idToken) {
        queryParams.id_token = params.idToken;
      }

      if (params.redirectUri) {
        queryParams.redirectUri = params.redirectUri;
      }

      if (params.codeVerifier) {
        queryParams.code_verifier = params.codeVerifier;
      }

      if (params.codeVerifierPkce) {
        queryParams.code_verifier_pkce = params.codeVerifierPkce;
      }

      if (params.state) {
        queryParams.state = params.state;
      }

      return this.post(`${urls.identity.auth.v1}/user/sso/${params.provider}/postlogin`, {
        metadata: params.metadata,
        invitationToken: params.invitationToken
      }, {
        params: queryParams
      });
    };

    this.getVendorConfig = async () => {
      return this.get(`${urls.identity.configurations.v1}/public`);
    };

    this.signUpUser = async body => {
      const {
        shouldActivate,
        authResponse,
        userId,
        tenantId
      } = await this.post(`${urls.identity.users.v1}/signUp`, body);
      const response = {
        shouldActivate,
        userId,
        tenantId
      };

      if (!shouldActivate && authResponse) {
        const {
          user,
          tenants,
          activeTenant
        } = await this.generateLoginResponseV3(authResponse);
        return _extends({}, response, {
          user,
          tenants,
          activeTenant
        });
      }

      return response;
    };

    this.getCurrentUserSessions = async () => {
      return this.get(urls.identity.users.sessions.currentUser.v1);
    };

    this.getCurrentUserSession = async () => {
      return this.get(`${urls.identity.users.sessions.currentUser.v1}/current`);
    };

    this.revokeSessionsForUser = async userId => {
      return this.post(`${urls.identity.users.sessions.v1}/revoke`, {
        userId
      });
    };

    this.getSessionConfigurations = async () => {
      return this.get(urls.identity.users.sessions.configurations.v1);
    };

    this.createOrUpdateSessionConfigrations = async body => {
      await this.post(urls.identity.users.sessions.configurations.v1, body);
    };

    this.deleteSessionForUser = async id => {
      await this.delete(`${urls.identity.users.sessions.currentUser.v1}/${id}`);
    };

    this.deleteAllSessionsForUser = async () => {
      await this.delete(`${urls.identity.users.sessions.currentUser.v1}/all`);
    };

    this.getUserAccessTokensData = async () => {
      return this.get(urls.identity.users.accessTokens.v1);
    };

    this.getTenantAccessTokensData = async () => {
      return this.get(urls.identity.tenants.accessTokens.v1);
    };

    this.deleteTenantAccessToken = async ({
      id
    }) => {
      await this.delete(`${urls.identity.tenants.accessTokens.v1}/${id}`);
    };

    this.deleteUserAccessToken = async ({
      id
    }) => {
      await this.delete(`${urls.identity.users.accessTokens.v1}/${id}`);
    };

    this.createUserAccessToken = async body => {
      return this.post(urls.identity.users.accessTokens.v1, body);
    };

    this.createTenantAccessToken = async body => {
      return this.post(urls.identity.tenants.accessTokens.v1, body);
    };

    this.getUserApiTokensData = async () => {
      return this.get(urls.identity.users.apiTokens.v1);
    };

    this.getTenantApiTokensData = async () => {
      return this.get(urls.identity.tenants.apiTokens.v1);
    };

    this.updateUserApiTokensData = async body => {
      return this.post(urls.identity.users.apiTokens.v1, body);
    };

    this.updateTenantApiTokensData = async body => {
      return this.post(urls.identity.tenants.apiTokens.v2, body);
    };

    this.deleteTenantApiToken = async ({
      tokenId
    }) => {
      await this.delete(`${urls.identity.tenants.apiTokens.v1}/${tokenId}`);
    };

    this.deleteUserApiToken = async ({
      tokenId
    }) => {
      await this.delete(`${urls.identity.users.apiTokens.v1}/${tokenId}`);
    };

    this.getUserById = async ({
      userId
    }) => {
      return this.get(`${urls.identity.users.v1}/${userId}`);
    };

    this.checkIfAllowToRememberMfaDevice = async mfaToken => {
      return this.get(`${urls.identity.configurations.v1}/mfa-policy/allow-remember-device`, {
        mfaToken
      });
    };

    this.passwordlessPreLogin = async _ref => {
      let {
        type
      } = _ref,
          body = _objectWithoutPropertiesLoose(_ref, _excluded);

      return this.post(`${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/prelogin`, body);
    };

    this.passwordlessPostLoginV2 = async _ref2 => {
      let {
        type
      } = _ref2,
          body = _objectWithoutPropertiesLoose(_ref2, _excluded2);

      const data = await this.post(`${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/postlogin`, body);
      return this.generateLoginResponseV3(data);
    };

    this.passwordlessPostLogin = async _ref3 => {
      let {
        type
      } = _ref3,
          body = _objectWithoutPropertiesLoose(_ref3, _excluded3);

      return this.post(`${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/postlogin`, body);
    };

    this.verifyInviteToken = async ({
      token
    }) => {
      return this.post(urls.identity.tenants.invites.verify.v1, {
        token
      });
    };

    this.getSSOConfigurations = async () => {
      return this.get(`${urls.team.sso.v1}/configurations`);
    };

    this.createSSOConfiguration = async body => {
      return this.post(`${urls.team.sso.v1}/configurations`, body);
    };

    this.updateSSOConfiguration = async (ssoConfigId, body) => {
      return this.patch(`${urls.team.sso.v1}/configurations/${ssoConfigId}`, body);
    };

    this.deleteSSOConfiguration = async ssoConfigId => {
      await this.delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}`);
    };

    this.createSSOConfigurationByMetadata = async body => {
      return this.post(`${urls.team.sso.v1}/configurations/metadata`, body);
    };

    this.updateSSOConfigurationByMetadata = async (ssoConfigId, body) => {
      return this.put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/metadata`, body);
    };

    this.createSSOConfigurationByMetadataUrl = async body => {
      return this.post(`${urls.team.sso.v1}/configurations/metadata-url`, body);
    };

    this.updateSSOConfigurationByMetadataUrl = async (ssoConfigId, body) => {
      return this.put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/metadata-url`, body);
    };

    this.createSSODomain = async (ssoConfigId, body) => {
      return this.post(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains`, body);
    };

    this.deleteSSODomain = async (ssoConfigId, domainId) => {
      return this.delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains/${domainId}`);
    };

    this.validateSSODomain = async (ssoConfigId, domainId) => {
      return this.put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains/${domainId}/validate`);
    };

    this.validateSSODomainV2 = async (ssoConfigId, domainId) => {
      return this.put(`${urls.team.sso.v2}/configurations/${ssoConfigId}/domains/${domainId}/validate`);
    };

    this.getSSODefaultRoles = async ssoConfigId => {
      return this.get(`${urls.team.sso.v1}/configurations/${ssoConfigId}/roles`);
    };

    this.setSSODefaultRoles = async (ssoConfigId, body) => {
      return this.put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/roles`, body);
    };

    this.createSSOGroup = async (ssoConfigId, body) => {
      return this.post(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups`, body);
    };

    this.updateSSOGroup = async (ssoConfigId, {
      roleIds,
      group,
      id
    }) => {
      return this.patch(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups/${id}`, {
        group,
        roleIds
      });
    };

    this.deleteSSOGroup = async (ssoConfigId, groupId) => {
      return this.delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups/${groupId}`);
    };

    this.getSSOGroups = async ssoConfigId => {
      return this.get(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups`);
    };

    this.preLoginV2 = async body => {
      return this.post(`${urls.identity.auth.v2}/user/sso/prelogin`, body);
    };

    this.oidcPostLoginV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v2}/user/oidc/postlogin`, body);
      return this.generateLoginResponse(data);
    };

    this.exchangeOAuthTokensV2 = async body => {
      const data = await this.post(`${urls.oauth.v1}/token`, body);
      return this.generateLoginResponseFromOAuthResponseV2(data);
    };

    this.silentOAuthRefreshTokenV2 = async () => {
      const tabTenantId = getTabTenantFromSessionStorage(this.appName);
      const data = await this.post(`${urls.oauth.v1}/authorize/silent`, {
        tenantId: tabTenantId
      });
      return this.generateLoginResponseFromOAuthResponseV2(data);
    };

    this.exchangeOAuthTokens = async body => {
      const data = await this.post(`${urls.oauth.v1}/token`, body);
      return this.generateLoginResponseFromOAuthResponse(data);
    };

    this.silentOAuthRefreshToken = async () => {
      const tabTenantId = getTabTenantFromSessionStorage(this.appName);
      const data = await this.post(`${urls.oauth.v1}/authorize/silent`, {
        tenantId: tabTenantId
      });
      return this.generateLoginResponseFromOAuthResponse(data);
    };

    this.resetPhoneNumber = async body => {
      return this.post(`${urls.identity.users.v1}/phone/reset`, body);
    };

    this.verifyResetPhoneNumber = async body => {
      return this.post(`${urls.identity.users.v1}/phone/reset/verify`, body);
    };

    this.changePhoneNumberWithVerification = async body => {
      return this.post(`${urls.identity.users.v1}/phone`, body);
    };

    this.verifyChangePhoneNumber = async body => {
      return this.post(`${urls.identity.users.v1}/phone/verify`, body);
    };

    this.changePhoneNumber = async body => {
      return this.put(`${urls.identity.users.v1}/phone/change`, body);
    };

    this.webAuthnPreLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/webauthn/prelogin`, body);
    };

    this.webAuthnPostLogin = async body => {
      return this.post(`${urls.identity.auth.v1}/webauthn/postlogin`, body);
    };

    this.webAuthnPostLoginV2 = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/webauthn/postlogin`, body);
      return this.generateLoginResponseV3(data);
    };

    this.webAuthnCreateNewDeviceSession = async () => {
      return this.post(urls.identity.webAuthnDevices.v1);
    };

    this.getWebAuthnDevices = async () => {
      return this.get(urls.identity.webAuthnDevices.v1);
    };

    this.deleteWebAuthnDevice = async deviceId => {
      return this.delete(`${urls.identity.webAuthnDevices.v1}/${deviceId}`);
    };

    this.verifyNewDeviceSession = async body => {
      return this.post(`${urls.identity.webAuthnDevices.v1}/verify`, body);
    };

    this.getVendorPublicAuthStrategiesConfig = async () => {
      return this.get(`${urls.identity.configurations.v1}/auth/strategies/public`);
    };

    this.getPublicAuthStrategiesConfigForAuthenticatedUser = async () => {
      return this.get(`${urls.identity.configurations.v1}/auth/strategies`);
    };

    this.getMFAStrategiesConfig = async () => {
      return this.get(`${urls.identity.configurations.v1}/mfa/strategies`);
    };

    this.getUserAuthorization = async () => {
      return this.get(`${urls.identity.users.authorization.v1}`);
    };

    this.getMeV2 = async () => {
      return this.get(`${urls.identity.users.v2}/me`);
    };

    this.noThrowLoadEntitlements = async () => {
      try {
        return await this.entitlementsApi.loadEntitlementsV2();
      } catch (e) {}
    };

    this.getMeAndEntitlements = async () => {
      const actions = [{
        action: this.getMeV2,
        shouldLoad: true
      }, {
        action: this.noThrowLoadEntitlements,
        shouldLoad: this.shouldLoadEntitlements()
      }, {
        action: this.getUserAuthorization,
        shouldLoad: this.shouldLoadMeAuthorization()
      }];
      const promises = actions.map(action => executeConditionalPromise(action));
      const [me, entitlements, authorization] = await Promise.all(promises);
      const tabTenant = getTabTenantFromSessionStorage(this.appName);

      if (tabTenant) {
        me.tenantId = tabTenant;
      }

      return _extends({}, me, authorization != null ? authorization : {}, {
        entitlements
      });
    };

    this.generateStepupSession = async body => {
      const data = await this.post(`${urls.identity.auth.v1}/user/step-up/generate`, body);

      if (!data.accessToken) {
        return data;
      }

      return this.generateLoginResponseV3(data);
    };

    this.getGlobalSecurityPolicy = this.securityPolicy.getGlobalSecurityPolicy.bind(this.securityPolicy);
    this.getMfaPolicy = this.securityPolicy.getMfaPolicy.bind(this.securityPolicy);
    this.getVendorMfaPolicy = this.securityPolicy.getVendorMfaPolicy.bind(this.securityPolicy);
    this.saveMfaPolicy = this.securityPolicy.saveMfaPolicy.bind(this.securityPolicy);
    this.getLockoutPolicy = this.securityPolicy.getLockoutPolicy.bind(this.securityPolicy);
    this.getVendorLockoutPolicy = this.securityPolicy.getVendorLockoutPolicy.bind(this.securityPolicy);
    this.saveLockoutPolicy = this.securityPolicy.saveLockoutPolicy.bind(this.securityPolicy);
    this.getCaptchaPolicy = this.securityPolicy.getCaptchaPolicy.bind(this.securityPolicy);
    this.getPasswordHistoryPolicy = this.securityPolicy.getPasswordHistoryPolicy.bind(this.securityPolicy);
    this.getVendorPasswordHistoryPolicy = this.securityPolicy.getVendorPasswordHistoryPolicy.bind(this.securityPolicy);
    this.savePasswordHistoryPolicy = this.securityPolicy.savePasswordHistoryPolicy.bind(this.securityPolicy);
    this.getPasswordConfigPolicy = this.securityPolicy.getPasswordConfigPolicy.bind(this.securityPolicy);
    this.getDomainRestrictions = this.securityPolicy.getDomainRestrictions.bind(this.securityPolicy);
    this.getDomainRestrictionsConfig = this.securityPolicy.getDomainRestrictionsConfig.bind(this.securityPolicy);
    this.createDomainRestriction = this.securityPolicy.createDomainRestriction.bind(this.securityPolicy);
    this.updateDomainRestrictionConfig = this.securityPolicy.updateDomainRestrictionConfig.bind(this.securityPolicy);
    this.deleteDomainRestriction = this.securityPolicy.deleteDomainRestriction.bind(this.securityPolicy);
    this.getIPRestrictions = this.securityPolicy.getIPRestrictions.bind(this.securityPolicy);
    this.getIPRestrictionsConfig = this.securityPolicy.getIPRestrictionsConfig.bind(this.securityPolicy);
    this.createIPRestriction = this.securityPolicy.createIPRestriction.bind(this.securityPolicy);
    this.bulkCreateIPRestriction = this.securityPolicy.bulkCreateIPRestriction.bind(this.securityPolicy);
    this.updateIPRestrictionConfig = this.securityPolicy.updateIPRestrictionConfig.bind(this.securityPolicy);
    this.deleteIPRestriction = this.securityPolicy.deleteIPRestriction.bind(this.securityPolicy);
    this.testCurrentIp = this.securityPolicy.testCurrentIp.bind(this.securityPolicy);
    this.testCurrentIpInAllowList = this.securityPolicy.testCurrentIpInAllowList.bind(this.securityPolicy);
  }

  shouldLoadEntitlements() {
    if (!ContextHolder.for(this.appName).shouldLoadEntitlements()) {
      return false;
    }

    const [isEntitlementsFFOn] = FeatureFlags.getFeatureFlags([ADMIN_PORTAL_ENTITLEMENTS_FF], this.appName || '');
    return isEntitlementsFFOn;
  }

  shouldLoadMeAuthorization() {
    const [shouldLoadAuthorization] = FeatureFlags.getFeatureFlags([LOAD_AUTHORIZATION_FF], this.appName);
    return shouldLoadAuthorization;
  }

}
export default new AuthenticationApi('default');