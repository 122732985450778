/**
 * @param param0 accounts without users count
 * @param tenantsUsersCountArray users count for each account array
 * @returns array of accounts with users count
 */
export const getAccountsWithUsersCount = ({
  items: accounts
}, tenantsUsersCountArray) => {
  return accounts.map(account => {
    var _tenantsUsersCountArr, _tenantsUsersCountArr2;
    return {
      name: account.name,
      tenantId: account.tenantId,
      numberOfUsers: (_tenantsUsersCountArr = (_tenantsUsersCountArr2 = tenantsUsersCountArray.find(i => i.tenantId === account.tenantId)) == null ? void 0 : _tenantsUsersCountArr2.totalUsers) != null ? _tenantsUsersCountArr : 0,
      createdAt: account.createdAt,
      metadata: account.metadata,
      isReseller: account.isReseller,
      subAccountAccessType: account.subAccountAccessType
    };
  });
};