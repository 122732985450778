import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"];
import { LoginFlow, LoginStep } from '../interfaces';
import { errorHandler } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @param tenants
   * @param isAuthenticated
   * @param isStepUp
   * @returns additional update object for the afterAuthenticationStateUpdate saga as part of MFA auth with authenticator app
   * @private
   */
  async function __buildPostAuthStateUpdate(tenants, isAuthenticated, isStepUp) {
    const loginState = store.auth.loginState;
    let additionalUpdate = {};
    if (!isStepUp) {
      const step = loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step;
      additionalUpdate = {
        loginState: {
          flow: loginState.flow,
          quickLoginToRegister: loginState.quickLoginToRegister,
          loading: false,
          step,
          error: undefined,
          tenants
        }
      };
    }
    return _extends({}, additionalUpdate, {
      isAuthenticated
    });
  }

  /**
   * Handle after MFA authentication with authenticator app for login
   * @private
   */
  async function __postLoginMfaAuthenticator(isAuthenticated, callback) {
    const loginState = store.auth.loginState;
    if (loginState.flow !== LoginFlow.Login) return;
    const [securityCenterLoginFlows] = await actions.getFeatureFlags(['security-center-show-login-flows']);
    if (securityCenterLoginFlows && loginState.isBreachedPassword && !isAuthenticated) {
      actions.setLoginState({
        step: LoginStep.breachedPassword,
        loading: false
      });
    } else {
      const shouldShowPrompt = await actions.__shouldShowPromptPasskeys();
      if (shouldShowPrompt) {
        actions.setLoginState({
          step: LoginStep.promptPasskeys,
          loading: false
        });
      } else {
        await actions.afterAuthNavigation();
      }
    }
    callback == null ? void 0 : callback(true);
  }

  /**
   * Handle after MFA authentication with authenticator app for step up
   * @private
   */
  async function __postStepUpMfaAuthenticator(callback) {
    await actions.afterStepUpAuthNavigation();
    callback == null ? void 0 : callback(true);
  }

  /**
   * Utility function to handle MFA authentication with authenticator app
   * @param payload MFA with authenticator action payload
   * @param setLoadingAction set loading action
   * @param isStepUp true if this is a step-up authentication
   * @returns
   */
  const mfaWithAuthenticator = async (_ref, setLoadingAction, isStepUp) => {
    let {
        callback
      } = _ref,
      loginWithMfaPayload = _objectWithoutPropertiesLoose(_ref, _excluded);
    setLoadingAction({
      loading: true,
      error: undefined
    });
    try {
      const {
        user,
        tenants = [],
        activeTenant
      } = await api.auth.loginWithMfaV2(loginWithMfaPayload);
      const isAuthenticated = !!user.accessToken;
      const additionalUpdate = await __buildPostAuthStateUpdate(tenants, isAuthenticated, isStepUp);
      await actions.afterAuthenticationStateUpdate({
        user,
        tenants,
        activeTenant
      }, additionalUpdate);
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      setLoadingAction({
        loading: false,
        error: undefined
      });
      if (isStepUp) {
        return await __postStepUpMfaAuthenticator(callback);
      }
      return await __postLoginMfaAuthenticator(isAuthenticated, callback);
    } catch (e) {
      setLoadingAction({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    }
  };

  /**
   * Login with MFA with authenticator app - external saga
   * @param payload.mfaToken
   * @param payload.mfaDevices
   * @param payload.rememberDevice
   * @param payload.invitationToken
   * @param payload.callback - The callback function to be called after the request is done
   */
  const loginWithMfa = async payload => {
    return await mfaWithAuthenticator(payload, actions.setLoginState, false);
  };
  return {
    mfaWithAuthenticator,
    loginWithMfa
  };
});