import _extends from "@babel/runtime/helpers/esm/extends";
import { createRandomString, generateCodeChallenge } from '../../../helpers';
import { HOSTED_LOGIN_VERIFIER_KEY } from '../../../constants';
import { getBaseNameWithoutSlashSuffix, getSearchParam, isOauthCallbackRoute, TENANT_ID_PARAM_KEY } from '../helpers';
import { getUri, isSteppedUp } from '../../helpers';
import { SHOULD_STEP_UP_KEY } from '../../StepUpState/consts';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @protected
   */
  const __requestHostedLoginSilentAuthorize = async () => {
    const response = await api.auth.silentOAuthRefreshTokenV2();
    if (response != null && response.user) {
      await actions.afterAuthenticationStateUpdate(response, {
        isAuthenticated: true,
        isLoading: false
      });
    } else {
      throw new Error(`couldn't refresh user token with oauth service`);
    }
  };
  const requestHostedLoginAuthorize = async additionalParams => {
    var _store$auth$customLog;
    const {
      routes,
      onRedirectTo
    } = store.auth;
    const {
      context,
      urlStrategy
    } = store.root;
    const activeUri = getUri(urlStrategy);
    if (activeUri === routes.hostedLoginRedirectUrl) {
      console.debug('Calling loginWithRedirect while in hostedLoginCallback route');
      return;
    }
    actions.setAuthState({
      isLoading: true
    });
    // Generate the relevant params for the redirect
    const nonce = createRandomString();
    const code_verifier = createRandomString();
    const code_challenge = await generateCodeChallenge(code_verifier);

    // We are saving the verifier in session storage to be able to validate the response
    localStorage.setItem(HOSTED_LOGIN_VERIFIER_KEY, code_verifier);
    const basename = getBaseNameWithoutSlashSuffix(store);
    let redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${basename != null ? basename : ''}${routes.hostedLoginRedirectUrl}`;
    await actions.loadCustomLoginRoutes();
    const customLoginSearchParam = (_store$auth$customLog = store.auth.customLoginState) == null ? void 0 : _store$auth$customLog.customLoginSearchParams;
    if (customLoginSearchParam) {
      if (redirectUrl.endsWith('/')) {
        redirectUrl = redirectUrl.slice(0, -1);
      }
      redirectUrl += customLoginSearchParam;
    }
    const baseUrl = api.fetch.getBaseUrl(context, '/oauth/authorize');
    // Hard coded for now
    const oauthUrl = `${baseUrl}/oauth/authorize`;
    const params = _extends({
      response_type: 'code',
      client_id: context.appId || context.clientId || 'INVALID-CLIENT-ID',
      scope: 'openid email profile',
      redirect_uri: redirectUrl,
      code_challenge: code_challenge,
      code_challenge_method: 'S256',
      nonce
    }, additionalParams);
    if (context.tenantResolver) {
      var _context$tenantResolv;
      const resolvedTenantResult = await ((_context$tenantResolv = context.tenantResolver) == null ? void 0 : _context$tenantResolv.call(context));
      if (resolvedTenantResult != null && resolvedTenantResult.tenant) {
        params['organization'] = resolvedTenantResult.tenant;
      }
    }
    const tenantId = getSearchParam(TENANT_ID_PARAM_KEY);
    if (tenantId) {
      params['tenantId'] = tenantId;
    }
    const searchParams = new URLSearchParams(params);
    const url = `${oauthUrl}?${searchParams.toString()}`;
    onRedirectTo(url, {
      refresh: true
    });
  };
  const refreshOrRequestHostedLoginAuthorize = async additionalParams => {
    var _localStorage;
    // when the user pause the step up flow we may still have the key in the local storage in hosted - Remove it
    (_localStorage = localStorage) == null ? void 0 : _localStorage.removeItem(SHOULD_STEP_UP_KEY);
    const disableSilentRefresh = store.auth.disableSilentRefresh;
    if ((additionalParams == null ? void 0 : additionalParams['prompt']) === 'login') {
      return await requestHostedLoginAuthorize(additionalParams);
    }
    try {
      if (disableSilentRefresh) {
        throw new Error('silent refresh is disabled');
      }
      return await __requestHostedLoginSilentAuthorize();
    } catch (e) {
      return await requestHostedLoginAuthorize(additionalParams);
    }
  };
  const refreshOrRequestHostedLoginAuthorizeV2 = async payload => {
    var _localStorage2, _additionalParams;
    const {
      shouldRedirectToLogin,
      firstTime,
      loginDirectAction
    } = payload != null ? payload : {};
    let {
      additionalParams
    } = payload != null ? payload : {};
    // when the user pause the step up flow we may still have the key in the local storage in hosted - Remove it
    (_localStorage2 = localStorage) == null ? void 0 : _localStorage2.removeItem(SHOULD_STEP_UP_KEY);
    if (firstTime) {
      const urlStrategy = store.root.urlStrategy;
      const activeUri = getUri(urlStrategy);
      actions.setAuthState({
        isLoading: true
      });
      if (isOauthCallbackRoute(activeUri)) {
        return;
      }
    }
    const disableSilentRefresh = store.auth.disableSilentRefresh;
    if (loginDirectAction) {
      additionalParams = additionalParams || {};
      additionalParams['login_direct_action'] = btoa(JSON.stringify(loginDirectAction));
    }
    if (((_additionalParams = additionalParams) == null ? void 0 : _additionalParams['prompt']) === 'login') {
      return await requestHostedLoginAuthorize(additionalParams);
    }
    try {
      if (disableSilentRefresh) {
        throw new Error('silent refresh is disabled');
      }
      return await __requestHostedLoginSilentAuthorize();
    } catch (e) {
      if (!shouldRedirectToLogin) {
        actions.setAuthState({
          isLoading: false
        });
        return;
      }
      return await requestHostedLoginAuthorize(additionalParams);
    }
  };
  const handleHostedLoginCallback = async payload => {
    var _store$auth$customLog2;
    // Hard coded for now
    const code_verifier = localStorage.getItem(HOSTED_LOGIN_VERIFIER_KEY) || 'INVALID-CODE-VERIFIER';
    const routes = store.auth.routes;
    const urlStrategy = store.root.urlStrategy;
    const basename = getBaseNameWithoutSlashSuffix(store);
    let redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${basename != null ? basename : ''}${routes.hostedLoginRedirectUrl}`;
    await actions.loadCustomLoginRoutes();
    const customLoginSearchParam = (_store$auth$customLog2 = store.auth.customLoginState) == null ? void 0 : _store$auth$customLog2.customLoginSearchParams;
    if (customLoginSearchParam) {
      if (redirectUrl.endsWith('/')) {
        redirectUrl = redirectUrl.slice(0, -1);
      }
      redirectUrl += customLoginSearchParam;
    }
    const body = {
      code: payload.code,
      redirect_uri: redirectUrl,
      code_verifier,
      grant_type: 'authorization_code'
    };
    try {
      const response = await api.auth.exchangeOAuthTokensV2(body);
      actions.afterAuthenticationStateUpdate(response, {
        isAuthenticated: true,
        isLoading: false
      });
    } catch (e) {
      actions.setAuthState({
        isLoading: false
      });
      console.error('Failed to exchangeOAuthTokens', e);
    } finally {
      const user = store.auth.user;
      const isStepUpFlow = window.localStorage.getItem(SHOULD_STEP_UP_KEY);
      window.localStorage.removeItem(SHOULD_STEP_UP_KEY);

      // when the user pause the step up flow we may still have the key in the local storage in hosted. Ignore it.
      if (isStepUpFlow && isSteppedUp(user)) {
        await actions.afterStepUpAuthNavigation();
      } else {
        await actions.afterAuthNavigation();
      }
    }
  };
  return {
    requestHostedLoginAuthorize: refreshOrRequestHostedLoginAuthorize,
    requestHostedLoginAuthorizeV2: refreshOrRequestHostedLoginAuthorizeV2,
    __requestHostedLoginAuthorize: requestHostedLoginAuthorize,
    __requestHostedLoginSilentAuthorize,
    handleHostedLoginCallback
  };
});