import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class ImpersonateApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.impersonate = async body => {
      return this.post(`${urls.identity.impersonate.v1}`, body);
    };
  }

}
export default new ImpersonateApi('default');