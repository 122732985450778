import { ContextHolder } from '@frontegg/rest-api';
import isEqual from 'fast-deep-equal';
import { evaluateIsEntitledToFeature, evaluateIsEntitledToPermissions } from '@frontegg/entitlements-javascript-commons';
const ENTITLEMENTS_NOT_ENABLED_EXCEPTION_TEXT = 'You must first enable entitlements via Frontegg options to use this function';

/**
 * Guard entitlements feature by checking if it is enabled by the vendor
 * @param appName - the app name to check entitlements for
 * @throws when entitlement is not enabled via frontegg options
 */
const guardEntitlementsUsage = (appName = 'default') => {
  if (ContextHolder.for(appName).shouldLoadEntitlements()) return;
  throw new Error(ENTITLEMENTS_NOT_ENABLED_EXCEPTION_TEXT);
};

/**
 @param entitlements
 @param key permission key
 @param attributes entitlements query attributes including consumer and frontegg attributes
 @param isV2 not in use. should be removed after removing from frontegg-vue
 @returns if the user is entitled to the given permission. Attaching the justification if not
 @throws when entitlement is not enabled via frontegg options
 */
export const getPermissionEntitlements = (entitlements, key, attributes, isV2, appName = 'default') => {
  guardEntitlementsUsage(appName);
  return evaluateIsEntitledToPermissions(key, entitlements, attributes);
};

/**
 @param entitlements
 @param key feature key
 @param attributes entitlements query attributes including consumer and frontegg attributes
 @param isV2 not in use. should be removed after removing from frontegg-vue
 @param appName
 @returns if the user is entitled to the given feature. Attaching the justification if not
 @throws when entitlement is not enabled via frontegg options
 */
export const getFeatureEntitlements = (entitlements, key, attributes, isV2, appName = 'default') => {
  guardEntitlementsUsage(appName);
  return evaluateIsEntitledToFeature(key, entitlements, attributes);
};

/**
 @param entitlements
 @param options including permission or feature
 @param attributes entitlements query attributes including consumer and frontegg attributes
 @param isV2 not in use. should be removed after removing from frontegg-vue
 @param appName
 @returns if the user is entitled to the given feature or permission (check only one). Attaching the justification if not
 @throws when entitlement is not enabled via frontegg options
 */
export const getEntitlements = (entitlements, options, attributes, isV2, appName = 'default') => {
  if ('permissionKey' in options) {
    return getPermissionEntitlements(entitlements, options.permissionKey, attributes, isV2, appName);
  }
  return getFeatureEntitlements(entitlements, options.featureKey, attributes, isV2, appName);
};

/**
 * @param oldEntitlements
 * @param newEntitlements
 * @returns true when old and new entitlements are deeply equal
 */
export function isEntitlementsDeeplyEqual(oldEntitlements, newEntitlements) {
  return isEqual(oldEntitlements, newEntitlements);
}