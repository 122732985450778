import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"];
import { initialState } from './state';
import { errorHandler, deepResetState } from '../../helpers';
import { mapMetaDataObjectToActions } from '../helpers';
import { ContextHolder } from '@frontegg/rest-api';
import { getSearchParamsFromUrl } from '../LoginState/helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setCustomLoginState = payload => {
    Object.assign(store.auth.customLoginState, payload);
  };
  const resetCustomLoginState = () => {
    deepResetState(store, ['auth', 'customLoginState'], initialState);
  };
  const loadTenantMetadata = async () => {
    setCustomLoginState({
      loading: true
    });
    try {
      const tenantMetadata = await api.metadata.getAdminBoxMetadata();
      setCustomLoginState({
        tenantMetadata,
        loading: false
      });
    } catch (e) {
      setCustomLoginState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const updateTenantMetadata = async payload => {
    const {
        callback
      } = payload,
      state = _objectWithoutPropertiesLoose(payload, _excluded);
    try {
      setCustomLoginState({
        error: null,
        saving: true
      });
      const requestActions = mapMetaDataObjectToActions(state);
      if (requestActions.length === 0) {
        throw new Error('No changes to update');
      }
      await api.metadata.updateAdminBoxMetadata({
        actions: requestActions
      });
      const tenantMetadata = await api.metadata.getAdminBoxMetadata();
      setCustomLoginState({
        tenantMetadata,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setCustomLoginState({
        error: errorHandler(e, 'No changes to update'),
        saving: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const customLoginEnabled = async () => {
    var _await$ContextHolder$, _ContextHolder$for$ge, _ContextHolder$for$ge2;
    const isAuthenticated = store.auth.isAuthenticated;
    if (isAuthenticated) {
      var _store$auth$tenantsSt, _store$auth$tenantsSt2;
      return (_store$auth$tenantsSt = (_store$auth$tenantsSt2 = store.auth.tenantsState.activeTenant) == null ? void 0 : _store$auth$tenantsSt2.hasCustomLogin) != null ? _store$auth$tenantsSt : false;
    }
    const resolvedTenant = (_await$ContextHolder$ = await ((_ContextHolder$for$ge = ContextHolder.for(store.root.appName).getContext()) == null ? void 0 : (_ContextHolder$for$ge2 = _ContextHolder$for$ge.tenantResolver) == null ? void 0 : _ContextHolder$for$ge2.call(_ContextHolder$for$ge))) == null ? void 0 : _await$ContextHolder$.tenant;
    return !!resolvedTenant;
  };
  const loadCustomLoginRoutes = async () => {
    try {
      var _getSearchParamsFromU;
      const isCustomLoginEnabled = await customLoginEnabled();
      if (!isCustomLoginEnabled) {
        return;
      }
      const isAuthenticated = store.auth.isAuthenticated;
      const {
        getSettings,
        getPublicSettings
      } = api.accountSettings;
      const {
        applicationUrl
      } = await (isAuthenticated ? getSettings() : getPublicSettings());
      if (!applicationUrl) {
        return;
      }
      const routes = store.auth.routes;
      const searchParams = (_getSearchParamsFromU = getSearchParamsFromUrl(applicationUrl)) != null ? _getSearchParamsFromU : '';
      actions.setAuthState({
        routes: _extends({}, routes, {
          customLoginAuthenticatedUrl: applicationUrl,
          customLoginUrl: routes.loginUrl + searchParams
        })
      });
      setCustomLoginState({
        customLoginSearchParams: searchParams != null ? searchParams : undefined
      });
    } catch {
      /* empty */
    }
  };
  return {
    setCustomLoginState,
    resetCustomLoginState,
    loadTenantMetadata,
    updateTenantMetadata,
    customLoginEnabled,
    loadCustomLoginRoutes
  };
});