import deepMerge from 'deepmerge';
import { getPalette } from './getPalette';
export { alpha } from './colorManipulator';
const defaultMetadata = {
  theme: {},
  themeV2: {},
  localizations: {},
  navigation: {
    usage: {
      visibility: 'hidden',
      permissions: ['fe.usage.read'],
      featureFlag: 'fe-usage-page'
    },
    webhooks: {
      visibility: 'hidden',
      permissions: ['fe.connectivity.read.webhooks']
    },
    roles: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.roles']
    },
    users: {
      visibility: 'always',
      permissions: ['fe.secure.read.users'],
      inviteDialog: {
        phoneNumberField: 'optional'
      }
    },
    personalApiTokens: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.userApiTokens']
    },
    apiTokens: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.tenantApiTokens']
    },
    profile: {
      visibility: 'always'
    },
    privacy: {
      visibility: 'always'
    },
    account: {
      visibility: 'always',
      permissions: ['fe.secure.read.accountSettings']
    },
    security: {
      visibility: 'always',
      permissions: ['fe.secure.read.securityPolicy']
    },
    sso: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.samlConfiguration']
    },
    provisioning: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.provisioningConfiguration']
    },
    audits: {
      visibility: 'always',
      permissions: ['fe.secure.read.audits']
    },
    subscriptions: {
      visibility: 'hidden',
      permissions: ['fe.subscriptions.*']
    },
    allAccounts: {
      visibility: 'byPermissions',
      permissions: ['fe.account-hierarchy.read.subAccount']
    },
    groups: {
      visibility: 'hidden',
      permissions: ['fe.secure.read.groups']
    }
  }
};
export class Metadata {
  constructor() {
    this._theme = defaultMetadata.theme;
    this._themeV2 = defaultMetadata.themeV2;
    this._navigation = defaultMetadata.navigation;
    this._localizations = defaultMetadata.localizations;
    this._integrations = defaultMetadata.integrations;
  }
  static getInstance(name = 'default') {
    return this._instances[name];
  }
  static set(metadata, name = 'default') {
    const metadataInstance = new Metadata();
    metadataInstance.set(metadata);
    Metadata._instances[name] = metadataInstance;
    return metadataInstance;
  }
  get theme() {
    var _this$_theme;
    return (_this$_theme = this._theme) != null ? _this$_theme : {};
  }
  get themeV2() {
    var _this$_themeV;
    return (_this$_themeV = this._themeV2) != null ? _this$_themeV : {};
  }
  get navigation() {
    var _this$_navigation;
    return (_this$_navigation = this._navigation) != null ? _this$_navigation : {};
  }
  get localizations() {
    var _this$_localizations;
    return (_this$_localizations = this._localizations) != null ? _this$_localizations : {};
  }
  get integrations() {
    var _this$_integrations;
    return (_this$_integrations = this._integrations) != null ? _this$_integrations : {};
  }
  set(metadata) {
    try {
      var _defaultMetadata$navi, _metadata$navigation, _defaultMetadata$them, _defaultMetadata$them2, _metadata$themeV, _defaultMetadata$loca, _metadata$localizatio, _defaultMetadata$inte, _metadata$integration;
      this._navigation = deepMerge.all([(_defaultMetadata$navi = defaultMetadata.navigation) != null ? _defaultMetadata$navi : {}, (_metadata$navigation = metadata == null ? void 0 : metadata.navigation) != null ? _metadata$navigation : {}]);
      this._theme = deepMerge.all([(_defaultMetadata$them = defaultMetadata.theme) != null ? _defaultMetadata$them : {}, getPalette(metadata == null ? void 0 : metadata.theme, defaultMetadata.theme)]);
      this._themeV2 = deepMerge.all([(_defaultMetadata$them2 = defaultMetadata.themeV2) != null ? _defaultMetadata$them2 : {}, (_metadata$themeV = metadata == null ? void 0 : metadata.themeV2) != null ? _metadata$themeV : {}]);
      this._localizations = deepMerge.all([(_defaultMetadata$loca = defaultMetadata.localizations) != null ? _defaultMetadata$loca : {}, (_metadata$localizatio = metadata == null ? void 0 : metadata.localizations) != null ? _metadata$localizatio : {}]);
      this._integrations = deepMerge.all([(_defaultMetadata$inte = defaultMetadata.integrations) != null ? _defaultMetadata$inte : {}, (_metadata$integration = metadata == null ? void 0 : metadata.integrations) != null ? _metadata$integration : {}]);
    } catch (e) {
      this._navigation = defaultMetadata.navigation;
      this._theme = defaultMetadata.theme;
      this._themeV2 = defaultMetadata.themeV2;
      this._localizations = defaultMetadata.localizations;
      this._integrations = defaultMetadata.integrations;
    }
  }
}
Metadata._instances = {};