import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"];
import { MFAStep } from '../../MfaState/interfaces';
import { errorHandler } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  /**
   * Shared logic for MFA Email code pre-verify step
   * @param payload.callback callback function to be called after the verification is done
   * @param payload.mfaToken
   * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
   */
  const preVerifyMFAEmailCode = async (_ref, setLoadingAction) => {
    var _setLoadingAction;
    let {
        callback
      } = _ref,
      payload = _objectWithoutPropertiesLoose(_ref, _excluded);
    setLoadingAction = (_setLoadingAction = setLoadingAction) != null ? _setLoadingAction : actions.setLoginState;
    setLoadingAction({
      loading: true,
      error: undefined
    });
    try {
      const data = await api.auth.preVerifyMFAEmailCode(payload);
      actions.setMfaState({
        otcToken: data.otcToken,
        step: MFAStep.emailVerifyCode
      });
      setLoadingAction({
        loading: false,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoadingAction({
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };

  /**
   * Shared logic for MFA Email code verify step
   * @param payload.otcToken
   * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
   * @param payload.code 6 digits code input by the user
   */
  const verifyMFAEmailCode = async (_ref2, setLoadingAction) => {
    var _setLoadingAction2;
    let {
        callback
      } = _ref2,
      payload = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    setLoadingAction = (_setLoadingAction2 = setLoadingAction) != null ? _setLoadingAction2 : actions.setLoginState;
    setLoadingAction({
      loading: true
    });
    try {
      const data = await api.auth.verifyMFAEmailCodeV2(payload);
      await actions.handleVerifyMFAResponse(data);
      setLoadingAction({
        loading: false,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoadingAction({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };

  /**
   * Pre verify step for MFA Email login
   * @param payload.mfaToken
   * @param payload.callback callback function to be called after the verification is done
   */
  const preVerifyMFAEmailCodeForLogin = async payload => {
    return await preVerifyMFAEmailCode(payload, actions.setLoginState);
  };

  /**
   * Verify step for MFA Email login
   * @param payload.otcToken
   * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
   * @param payload.code 6 digits code input by the user
   */
  const verifyMFAEmailCodeForLogin = async payload => {
    return await verifyMFAEmailCode(payload, actions.setLoginState);
  };
  return {
    preVerifyMFAEmailCode,
    verifyMFAEmailCode,
    preVerifyMFAEmailCodeForLogin,
    verifyMFAEmailCodeForLogin
  };
});