import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"];
import { MFAStep } from '../../auth';
import { delay, errorHandler } from '../../helpers';
import { buildMfaActions } from '../../auth/MfaState';
import { userDemo } from '../dummy';
import { mockActionsExpect } from '../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const originalActions = buildMfaActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setMfaState', 'resetMfaState']);
  mockedActions.enrollMfa = async () => {
    mockedActions.setMfaState({
      saving: true
    });
    const qrCode = 'test';
    mockedActions.setMfaState({
      saving: false,
      error: undefined,
      qrCode
    });
  };
  mockedActions.verifyMfa = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    const {
      mfaDevices = {
        webauthn: [],
        phones: [],
        authenticators: [],
        emails: []
      }
    } = store.auth.mfaState;
    mockedActions.setMfaState({
      saving: true
    });
    await delay();
    const data = {
      token: 'token',
      recoveryCode: 'recoveryCode'
    };
    const mfaState = {
      step: MFAStep.recoveryCode,
      loading: false,
      error: undefined,
      saving: false,
      mfaDevices: _extends({}, mfaDevices, {
        authenticators: [{
          id: 'authenticator-id'
        }]
      })
    };
    if (data != null && data.recoveryCode) {
      mfaState.recoveryCode = data.recoveryCode;
    }
    mockedActions.setMfaState(mfaState);
    actions.setUser(_extends({}, userDemo, {
      mfaEnrolled: true
    }));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  };
  mockedActions.disableMfa = async payload => {
    var _payload$callback;
    const {
      mfaDevices = {
        webauthn: [],
        phones: [],
        authenticators: [],
        emails: []
      }
    } = store.auth.mfaState;
    mockedActions.setMfaState({
      saving: true
    });
    await delay();
    mockedActions.setMfaState({
      saving: false,
      error: undefined,
      mfaDevices: _extends({}, mfaDevices, {
        authenticators: []
      })
    });
    actions.setUser(_extends({}, userDemo));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  };
  mockedActions.verifyMfaAfterForce = async _payload => {
    const {
        callback
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    mockedActions.setMfaState({
      loading: true
    });
    await delay();
    const data = _extends({}, payload, {
      recoveryCode: 'recoveryCode'
    });
    const mfaState = {
      step: MFAStep.recoveryCode,
      loading: false,
      error: undefined,
      saving: false
    };
    if (data != null && data.recoveryCode) {
      mfaState.recoveryCode = data.recoveryCode;
    }
    mockedActions.setMfaState(mfaState);
    await delay();
    actions.setUser(_extends({}, userDemo, {
      mfaEnrolled: true
    }));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  };
  mockedActions.preDisableMfaSms = async payload => {
    mockedActions.setMfaState({
      saving: true
    });
    try {
      var _payload$callback2;
      mockedActions.setMfaState({
        saving: false,
        error: undefined,
        otcToken: 'otc-token',
        phoneNumber: '+972504589346'
      });
      (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, true);
    } catch (e) {
      var _payload$callback3;
      mockedActions.setMfaState({
        saving: false,
        error: errorHandler(e)
      });
      (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, false, e);
    }
  };
  mockedActions.disableMfaSms = async payload => {
    var _payload$callback4;
    const {
      mfaDevices = {
        webauthn: [],
        phones: [],
        authenticators: [],
        emails: []
      }
    } = store.auth.mfaState;
    mockedActions.setMfaState({
      saving: true
    });
    await delay();
    mockedActions.setMfaState({
      loading: false,
      error: undefined,
      mfaDevices: _extends({}, mfaDevices, {
        phones: []
      })
    });
    actions.setUser(_extends({}, userDemo));
    (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, true);
  };
  mockedActions.preEnrollMfaSms = async payload => {
    var _payload$callback5;
    mockedActions.setMfaState({
      saving: true
    });
    await delay();
    mockedActions.setMfaState({
      saving: false,
      error: undefined,
      otcToken: 'otc-token',
      phoneNumber: '+972504589346'
    });
    (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
  };
  mockedActions.enrollMfaSms = async _payload => {
    const {
      callback
    } = _payload;
    const {
      mfaDevices = {
        webauthn: [],
        phones: [],
        authenticators: [],
        emails: []
      }
    } = store.auth.mfaState;
    mockedActions.setMfaState({
      saving: true
    });
    await delay();
    const data = {
      token: 'token',
      recoveryCode: 'recoveryCode'
    };
    const mfaState = {
      step: MFAStep.recoveryCode,
      loading: false,
      error: undefined,
      saving: false,
      mfaDevices: _extends({}, mfaDevices, {
        phones: [{
          id: 'phone-id',
          phoneNumber: '+972504589346'
        }]
      })
    };
    if (data != null && data.recoveryCode) {
      mfaState.recoveryCode = data.recoveryCode;
    }
    mockedActions.setMfaState(mfaState);
    actions.setUser(_extends({}, userDemo, {
      mfaEnrolled: true
    }));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  };
  return mockedActions;
});