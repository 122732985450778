import { createProxy } from '../../../toolkit/proxy';
import { SubAccountAccessTypeEnum } from '@frontegg/rest-api';
export const initialState = {
  createSubAccountDialog: {
    loading: false,
    open: false,
    error: false,
    jwt: '',
    canSelectParentAccount: false
  },
  updateSubAccountDialog: {
    accountId: '',
    accountName: '',
    loading: false,
    open: false,
    error: false,
    jwt: ''
  },
  disableSubAccountManagementDialog: {
    accountId: '',
    accountName: '',
    loading: false,
    open: false,
    error: false
  },
  enableSubAccountManagementDialog: {
    accountId: '',
    accountName: '',
    loading: false,
    open: false,
    error: false
  },
  updateSubAccountHierarchySettingsDialog: {
    accountId: '',
    accountName: '',
    subAccountAccessType: SubAccountAccessTypeEnum.DEFAULT_OFF,
    loading: false,
    open: false,
    error: false
  },
  deleteSubAccountDialog: {
    accountId: '',
    accountName: '',
    isParentAccount: false,
    loading: false,
    open: false,
    error: false,
    jwt: ''
  },
  addUsersToAccountDialog: {
    loading: false,
    open: false,
    accountName: '',
    accountId: '',
    permissionToGiveAccessToSubAccounts: false,
    jwt: ''
  },
  deleteUserFromAccountDialog: {
    userId: '',
    userName: '',
    accountId: '',
    loading: false,
    open: false,
    jwt: ''
  },
  editUserRolesDialog: {
    loading: false,
    open: false,
    jwt: '',
    accountId: '',
    userId: '',
    userName: '',
    userRoles: []
  }
};
export default (overrideState => createProxy(initialState, overrideState));