import _extends from "@babel/runtime/helpers/esm/extends";
export const mockActionsExpect = (actions, exclude) => {
  return Object.keys(actions).reduce((acc, key) => {
    const actionKey = key;
    if (exclude.includes(key)) {
      return _extends({}, acc, {
        [key]: actions[actionKey]
      });
    }
    return _extends({}, acc, {
      [key]: () => {
        // eslint-disable-next-line no-console
        console.debug(`Action ${String(key)} was called`);
      }
    });
  }, {});
};