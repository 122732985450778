import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"];
import { ForgotPasswordStep } from './interfaces';
import { initialState } from './state';
import { errorHandler, deepResetState } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setForgotPasswordState = payload => {
    Object.assign(store.auth.forgotPasswordState, payload);
  };
  const resetForgotPasswordState = () => {
    deepResetState(store, ['auth', 'forgotPasswordState'], initialState);
  };
  const forgotPassword = async payload => {
    setForgotPasswordState({
      loading: true
    });
    try {
      var _payload$callback;
      await api.auth.forgotPassword(payload);
      setForgotPasswordState({
        loading: false,
        error: undefined,
        step: ForgotPasswordStep.success
      });
      (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setForgotPasswordState({
        loading: false,
        error: errorHandler(e, 'Unknown error occurred')
      });
      (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
    }
  };
  const resetPassword = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    setForgotPasswordState({
      loading: true
    });
    try {
      await api.auth.resetPassword(body);
      setForgotPasswordState({
        loading: false,
        error: undefined,
        step: ForgotPasswordStep.success
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setForgotPasswordState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  const loadPasswordConfig = async payload => {
    setForgotPasswordState({
      loading: true
    });
    try {
      const passwordConfig = await api.auth.loadPasswordConfig(payload);
      setForgotPasswordState({
        loading: false,
        passwordConfig
      });
    } catch (e) {
      setForgotPasswordState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  return {
    setForgotPasswordState,
    resetForgotPasswordState,
    forgotPassword,
    resetPassword,
    loadPasswordConfig
  };
});