import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class SecurityCenterApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getRecommendations = async () => {
      return this.get(urls.securityCenter.recommendations.v1);
    };

    this.getInsights = async () => {
      return this.get(urls.securityCenter.insights.v1);
    };
  }

}
export default new SecurityCenterApi('default');