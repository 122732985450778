import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { buildSecurityPolicyActions } from '../../../auth/Security/SecurityPolicyState';
import { delay, errorHandler } from '../../../helpers';
import { policyDemo, policyLockoutDemo, policyMfaDemo, policyPasswordHistoryDemo, publicSecurityPolicy } from '../../dummy';
import { mockActionsExpect } from '../../helpers';
export default ((store, api, actions) => {
  const originalActions = buildSecurityPolicyActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setSecurityPolicyState', 'setSecurityPolicyGlobalState', 'setSecurityPolicyPublicState', 'setSecurityPolicyMfaState', 'setSecurityPolicyVendorMfaState', 'setSecurityPolicyLockoutState', 'setSecurityPolicyVendorLockoutState', 'setSecurityPolicyCaptchaState', 'setSecurityPolicyPasswordHistoryState', 'setSecurityPolicyVendorPasswordHistoryState', 'resetSecurityPolicyState', 'setSecurityPolicyPasswordState', 'setSecurityPolicyAuthStrategyPublicState']);
  mockedActions.loadSecurityPolicy = async () => {
    mockedActions.setSecurityPolicyGlobalState({
      loading: true,
      error: null
    });
    await Promise.all([mockedActions.loadSecurityPolicyMfa(), mockedActions.loadSecurityPolicyVendorMfa(), mockedActions.loadSecurityPolicyLockout(), mockedActions.loadSecurityPolicyVendorLockout(), mockedActions.loadSecurityPolicyPasswordHistory(), mockedActions.loadSecurityPolicyVendorPasswordHistory(), mockedActions.loadSecurityPolicyCaptcha()]);
    await delay();
    mockedActions.setSecurityPolicyGlobalState({
      policy: policyDemo,
      loading: false
    });
  };
  mockedActions.loadPublicSecurityPolicy = async () => {
    mockedActions.setSecurityPolicyPublicState({
      loading: true,
      error: null
    });
    try {
      mockedActions.setSecurityPolicyPublicState({
        policy: publicSecurityPolicy,
        loading: false
      });
    } catch (e) {
      mockedActions.setSecurityPolicyPublicState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  mockedActions.saveSecurityPolicyMfa = async payload => {
    var _newSecurityPolicy$id;
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded);
    mockedActions.setSecurityPolicyMfaState({
      saving: true,
      error: null
    });
    const policy = _extends({}, policyLockoutDemo, {
      id: (_newSecurityPolicy$id = newSecurityPolicy.id) != null ? _newSecurityPolicy$id : policyLockoutDemo.id,
      enforceMFAType: newSecurityPolicy.enforceMFAType
    });
    await delay();
    mockedActions.setSecurityPolicyMfaState({
      policy,
      saving: false
    });
    callback == null ? void 0 : callback(policy);
  };
  mockedActions.loadSecurityPolicyMfa = async () => {
    mockedActions.setSecurityPolicyMfaState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyMfaState({
      policy: policyMfaDemo,
      loading: false
    });
  };
  mockedActions.loadSecurityPolicyVendorMfa = async () => {
    mockedActions.setSecurityPolicyVendorMfaState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyVendorMfaState({
      policy: policyMfaDemo,
      loading: false
    });
  };
  mockedActions.saveSecurityPolicyLockout = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded2);
    mockedActions.setSecurityPolicyLockoutState({
      saving: true,
      error: null
    });
    await delay();
    const policy = _extends({}, policyLockoutDemo, newSecurityPolicy, {
      id: newSecurityPolicy.id ? newSecurityPolicy.id : policyLockoutDemo.id
    });
    callback == null ? void 0 : callback(policy);
    mockedActions.setSecurityPolicyLockoutState({
      policy,
      saving: false
    });
  };
  mockedActions.loadSecurityPolicyLockout = async () => {
    mockedActions.setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyLockoutState({
      policy: policyLockoutDemo,
      loading: false
    });
  };
  mockedActions.loadSecurityPolicyVendorLockout = async () => {
    mockedActions.setSecurityPolicyVendorLockoutState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyVendorLockoutState({
      policy: policyLockoutDemo,
      loading: false
    });
  };
  mockedActions.loadSecurityPolicyCaptcha = async () => {
    mockedActions.setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyLockoutState({
      policy: policyLockoutDemo,
      loading: false
    });
  };
  mockedActions.saveSecurityPolicyPasswordHistory = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded3);
    mockedActions.setSecurityPolicyPasswordHistoryState({
      saving: true,
      error: null
    });
    await delay();
    const policy = _extends({}, policyPasswordHistoryDemo, newSecurityPolicy, {
      id: newSecurityPolicy.id ? newSecurityPolicy.id : policyPasswordHistoryDemo.id
    });
    callback == null ? void 0 : callback(policy);
    mockedActions.setSecurityPolicyPasswordHistoryState({
      policy,
      saving: false
    });
  };
  mockedActions.loadSecurityPolicyPasswordHistory = async () => {
    mockedActions.setSecurityPolicyPasswordHistoryState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyPasswordHistoryState({
      policy: policyPasswordHistoryDemo,
      loading: false
    });
  };
  mockedActions.loadSecurityPolicyVendorPasswordHistory = async () => {
    mockedActions.setSecurityPolicyVendorPasswordHistoryState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setSecurityPolicyVendorPasswordHistoryState({
      policy: policyPasswordHistoryDemo,
      loading: false
    });
  };
  return mockedActions;
});