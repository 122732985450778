export const urls = {
  vendor: '/vendors',
  identity: {
    webAuthnDevices: {
      v1: '/identity/resources/users/webauthn/v1/devices'
    },
    auth: {
      v1: '/identity/resources/auth/v1',
      v2: '/identity/resources/auth/v2'
    },
    users: {
      v1: '/identity/resources/users/v1',
      v2: '/identity/resources/users/v2',
      v3: '/identity/resources/users/v3',
      roles: {
        v3: '/identity/resources/users/v3/roles'
      },
      groups: {
        v3: '/identity/resources/users/v3/groups'
      },
      tenants: {
        me: {
          v1: '/identity/resources/users/v1/me/tenants',
          v3: '/identity/resources/users/v3/me/tenants'
        }
      },
      apiTokens: {
        v1: '/identity/resources/users/api-tokens/v1'
      },
      accessTokens: {
        v1: '/identity/resources/users/access-tokens/v1'
      },
      sessions: {
        v1: '/identity/resources/users/sessions/v1',
        currentUser: {
          v1: '/identity/resources/users/sessions/v1/me'
        },
        configurations: {
          v1: '/identity/resources/configurations/sessions/v1'
        }
      },
      invitation: {
        resendAll: {
          v1: '/identity/resources/users/v1/invitation/reset/all'
        }
      },
      passwords: {
        resetBreachedPasswords: {
          v1: '/identity/resources/users/v1/passwords/breached/reset/bulk'
        }
      },
      authorization: {
        v1: '/identity/resources/users/v1/me/authorization'
      },
      temporary: {
        v1: '/identity/resources/users/temporary/v1',
        configuration: {
          v1: '/identity/resources/users/temporary/v1/configuration'
        }
      }
    },
    configurations: {
      v1: '/identity/resources/configurations/v1'
    },
    restrictions: {
      emailDomain: {
        v1: "/identity/resources/configurations/restrictions/v1/email-domain"
      },
      ip: {
        v1: "/identity/resources/configurations/v1/restrictions/ip"
      }
    },
    sso: {
      v1: '/identity/resources/sso/v1',
      v2: '/identity/resources/sso/v2',
      custom: {
        v1: '/identity/resources/sso/custom/v1'
      }
    },
    permissions: {
      v1: '/identity/resources/permissions/v1'
    },
    tenants: {
      apiTokens: {
        v1: '/identity/resources/tenants/api-tokens/v1',
        v2: '/identity/resources/tenants/api-tokens/v2'
      },
      accessTokens: {
        v1: '/identity/resources/tenants/access-tokens/v1'
      },
      invites: {
        user: {
          v1: '/identity/resources/tenants/invites/v1/user'
        },
        verify: {
          v1: '/identity/resources/tenants/invites/v1/verify'
        },
        configuration: {
          v1: '/identity/resources/tenants/invites/v1/configuration'
        }
      },
      users: {
        v1: "/identity/resources/tenants/users/v1"
      }
    },
    roles: {
      v1: '/identity/resources/roles/v1',
      v2: '/identity/resources/roles/v2'
    },
    subTenants: {
      v1: '/identity/resources/sub-tenants/users/v1',
      v2: '/identity/resources/sub-tenants/users/v2'
    },
    impersonate: {
      v1: '/identity/resources/impersonation/v1'
    },
    applications: {
      v1: '/identity/resources/applications/v1'
    },
    groups: {
      v1: '/identity/resources/groups/v1',
      configurations: {
        v1: '/identity/resources/groups/v1/config'
      },
      getByIds: {
        v1: '/identity/resources/groups/v1/bulkGet'
      }
    },
    phoneNumbers: {
      v1: '/identity/resources/users/phone-numbers/v1'
    }
  },
  team: {
    sso: {
      v1: '/team/resources/sso/v1',
      v2: '/team/resources/sso/v2'
    },
    members: {
      v1: '/team/resources/members/v1'
    },
    roles: {
      v1: '/team/resources/roles/v1'
    },
    stats: {
      v1: '/team/resources/stats/v1'
    },
    profile: {
      v1: '/team/resources/profile'
    }
  },
  tenants: {
    accountSettings: {
      v1: '/tenants/resources/account-settings/v1'
    },
    hierarchy: {
      v1: '/tenants/resources/hierarchy/v1',
      tree: {
        v1: '/tenants/resources/hierarchy/v1/tree'
      },
      parents: {
        v1: '/tenants/resources/hierarchy/v1/parents'
      },
      v2: '/tenants/resources/hierarchy/v2',
      metadata: {
        v2: '/tenants/resources/hierarchy/v2/metadata'
      }
    },
    subTenants: {
      v1: '/tenants/resources/sub-tenants/v1'
    }
  },
  integrations: {
    configurations: {
      v1: '/integrations/resources/configurations/v1',
      v2: '/integrations/resources/configurations/v2'
    }
  },
  subscriptions: {
    billing: {
      subscriptions: {
        v1: '/subscriptions/resources/billing/subscriptions/v1'
      },
      plans: {
        v1: '/subscriptions/resources/billing/plans/v1'
      },
      invoices: {
        v1: '/subscriptions/resources/billing/invoices/v1'
      },
      paymentMethods: {
        v1: '/subscriptions/resources/billing/payment-methods/v1'
      },
      summaries: {
        v1: '/subscriptions/resources/billing/summaries/v1'
      },
      tenantConfiguration: {
        v1: '/subscriptions/resources/billing/tenant-configurations/v1'
      },
      configurations: {
        vendorPublicConfigurations: {
          v1: '/subscriptions/resources/billing/configurations/vendor-public-configurations/v1'
        }
      },
      paymentProviders: {
        stripe: {
          customers: {
            v1: '/subscriptions/resources/billing/payment-providers/stripe/customers/v1'
          },
          publicConfigurations: {
            v1: '/subscriptions/resources/billing/payment-providers/stripe/public-configurations/v1'
          },
          subscriptions: {
            v1: '/subscriptions/resources/billing/payment-providers/stripe/subscriptions/v1'
          },
          setupIntents: {
            v1: '/subscriptions/resources/billing/payment-providers/stripe/setup-intents/v1'
          }
        }
      }
    },
    paymentProviders: {
      v1: '/subscriptions/resources/payment-providers/v1'
    },
    managedSubscriptions: {
      v1: '/subscriptions/resources/subscriptions/v1'
    }
  },
  reports: {
    service: {
      v1: '/reports/resources/reports/v1',
      v2: '/reports/resources/reports/v2'
    },
    trigger: {
      v1: '/reports/engine/resources/triggers/v1'
    }
  },
  notifications: {
    v1: '/notification'
  },
  audits: {
    v1: '/audits'
  },
  events: {
    configurations: {
      v1: '/event/resources/configurations/v1'
    }
  },
  webhooks: {
    v1: '/webhook'
  },
  oauth: {
    v1: '/oauth'
  },
  featureFlags: {
    v1: '/flags'
  },
  directory: {
    v1: '/directory/resources/v1/configurations/scim2',
    temp: '/directory/resources/scim/temp'
  },
  entitlements: {
    v1: '/entitlements/api/v1/user-entitlements',
    v2: '/entitlements/api/v2/user-entitlements'
  },
  securityCenter: {
    recommendations: {
      v1: '/security-center/resources/recommendations/v1'
    },
    insights: {
      v1: '/security-center/resources/insights/v1'
    }
  },
  applications: {
    v1: '/applications/resources/applications/v1',
    tenant: {
      v1: '/applications/resources/applications/tenant-assignments/v1'
    }
  }
};
export const GENERIC_ERROR_MESSAGE = `We're facing some difficulties, Please try again`;