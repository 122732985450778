import _extends from "@babel/runtime/helpers/esm/extends";
export * from './Checkout/interfaces';
export * from './Billing/interfaces';
export * from './Plans/interfaces';
export * from './Stripe/interfaces';
export * from './Config/interfaces';
export * from './VendorPublicConfig/interfaces';
import { createConfigState, buildConfigActions } from './Config';
import { createBillingState, buildBillingActions } from './Billing';
import { createPlansState, buildPlansActions } from './Plans';
import { createCheckoutState, buildCheckoutActions } from './Checkout';
import { createStripeState, buildStripeActions } from './Stripe';
import { createVendorPublicConfigState, buildVendorPublicConfigActions } from './VendorPublicConfig';
export const createSubscriptionsState = overrideState => {
  return createProxy({
    config: createConfigState(overrideState == null ? void 0 : overrideState.config),
    billing: createBillingState(overrideState == null ? void 0 : overrideState.billing),
    plans: createPlansState(overrideState == null ? void 0 : overrideState.plans),
    checkout: createCheckoutState(overrideState == null ? void 0 : overrideState.checkout),
    stripe: createStripeState(overrideState == null ? void 0 : overrideState.stripe),
    vendorPublicConfig: createVendorPublicConfigState(overrideState == null ? void 0 : overrideState.vendorPublicConfig)
  });
};
export const buildSubscriptionsActions = (store, api, actions) => {
  const configActions = buildConfigActions(store, api, actions);
  const [billingActions, billingStateActions] = buildBillingActions(store, api, actions);
  const plansActions = buildPlansActions(store, api, actions);
  const checkoutActions = buildCheckoutActions(store, api, actions);
  const stripeActions = buildStripeActions(store, api, actions);
  const vendorPublicConfigActions = buildVendorPublicConfigActions(store, api, actions);
  return [_extends({}, configActions, billingActions, plansActions, checkoutActions, stripeActions, vendorPublicConfigActions), {
    config: configActions,
    billing: billingStateActions,
    plans: plansActions,
    checkout: checkoutActions,
    stripe: stripeActions,
    vendorPublicConfig: vendorPublicConfigActions
  }];
};
import { createProxy } from '../toolkit/proxy';