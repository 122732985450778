import _extends from "@babel/runtime/helpers/esm/extends";
export const defaultItemsPerPage = 20;

/** @deprecated use auditLogs instead of audits */
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setContext = payload => {
    store.audits.context = payload;
  };
  const startLoading = () => {
    store.audits.isLoading = true;
  };
  const setVirtualScroll = payload => {
    store.audits.virtualScroll = payload;
  };
  const startRefresh = () => {
    store.audits.isLoading = true;
  };
  const startFetching = () => {
    store.audits.isFetchMore = true;
  };
  const finishLoading = () => {
    store.audits.isLoading = false;
  };
  const startDownloadingCsv = () => {
    store.audits.isDownloadingCsv = true;
  };
  const stopDownloadingCsv = () => {
    store.audits.isDownloadingCsv = false;
  };
  const loadStatsSuccess = payload => {
    store.audits.error = undefined;
    store.audits.totalToday = payload.totalToday;
    store.audits.severeThisWeek = payload.severeThisWeek;
  };
  const loadItemFailedAction = payload => {
    var _store$audits$error;
    store.audits.error = (_store$audits$error = store.audits.error) != null ? _store$audits$error : {};
    Object.assign(store.audits.error, {
      [payload.name]: payload.error
    });
  };
  const loadMetadataSuccess = payload => {
    store.audits.error = {};
    store.audits.headerProps = payload.properties;
    store.audits.themeAudits = payload.theme ? payload.theme.styles : {};
  };
  const loadAuditsSuccess = payload => {
    store.audits.error = {};
    store.audits.rowsData = payload.rowsData;
    store.audits.lastUpdated = new Date();
    store.audits.total = payload.total;
  };
  const fetchMoreSuccess = payload => {
    store.audits.error = {};
    store.audits.offset = payload.offset;
    store.audits.currentPage = payload.currentPage;
    store.audits.isFetchMore = false;
  };
  const setFilterData = payload => {
    store.audits.filters = payload;
    store.audits.currentPage = 0;
    store.audits.offset = 0;
    store.audits.isLoading = true;
  };
  const textSearch = payload => {
    store.audits.filter = payload;
    store.audits.currentPage = 0;
    store.audits.offset = 0;
    store.audits.isLoading = true;
  };
  const onPageChange = payload => {
    store.audits.currentPage = payload - 1;
    store.audits.offset = store.audits.currentPage * defaultItemsPerPage;
    store.audits.isLoading = true;
  };
  const setDataSorting = payload => {
    store.audits.sortBy = payload.sortBy;
    store.audits.currentPage = 0;
    store.audits.sortDirection = payload.sortDirection === 'asc' ? 'desc' : 'asc';
    store.audits.offset = 0;
    store.audits.isLoading = true;
  };
  const setPredefinedFilters = payload => {
    store.audits.predefinedFilters = payload;
    const filters = Object.keys(payload).map(key => ({
      key,
      value: payload[key]
    }));
    store.audits.filters = filters;
  };
  const __loadStats = async () => {
    const {
      sortBy,
      sortDirection
    } = store.audits;
    try {
      const stats = await api.audits.getAuditsStats({
        sortBy,
        sortDirection,
        count: defaultItemsPerPage
      });
      loadStatsSuccess(stats);
    } catch (e) {
      const errorMessage = {
        name: 'stats',
        error: e
      };
      console.error('failed to load stats - ', e);
      loadItemFailedAction(errorMessage);
    }
  };
  const __loadMetadata = async () => {
    try {
      const result = await api.metadata.getAuditsMetadata();
      loadMetadataSuccess(result);
    } catch (e) {
      const errorMessage = {
        name: 'metadata',
        error: e
      };
      console.error('failed to load metadata - ', e);
      loadItemFailedAction(errorMessage);
    }
  };
  const __filterToObject = arr => arr.reduce((res, curr) => {
    res[curr.key] = curr.value;
    return res;
  }, {});

  /** @deprecated use auditLogs instead of audits */
  const loadAudits = async payload => {
    const {
      filters,
      sortBy,
      sortDirection,
      filter,
      offset,
      virtualScroll
    } = store.audits;
    const {
      appendMode = virtualScroll,
      onlyOneLoad = true,
      offset: incomeOffset = 0
    } = payload || {};
    const {
      rowsData
    } = appendMode ? store.audits : {
      rowsData: []
    };
    try {
      const f2o = __filterToObject(filters);
      const {
        data,
        total
      } = await api.audits.getAudits(_extends({}, virtualScroll && {
        paginationMode: 'virtual'
      }, {
        sortDirection,
        sortBy,
        filter
      }, f2o, {
        // TODO: refactor once api become V2 with query field for virtual scroll
        offset: virtualScroll ? rowsData.length + incomeOffset || rowsData.length + offset : incomeOffset || offset,
        count: defaultItemsPerPage
      }));
      loadAuditsSuccess({
        rowsData: [...rowsData, ...data],
        total
      });
      if (onlyOneLoad) {
        finishLoading();
      }
    } catch (e) {
      const errorMessage = {
        name: 'audits',
        error: e
      };
      console.error('failed to load audits - ', e);
      loadItemFailedAction(errorMessage);
    }
  };

  /** @deprecated use auditLogs instead of audits */
  const initData = async () => {
    startLoading();
    await Promise.all([__loadStats(), __loadMetadata(), loadAudits({
      onlyOneLoad: false
    })]);
    finishLoading();
  };

  /** @deprecated use auditLogs instead of audits */
  const removeFilter = async payload => {
    const {
      filters: allFilters
    } = store.audits;
    const removedFilterIndex = allFilters.findIndex(item => item.key === payload.key);
    if (removedFilterIndex < 0) {
      return;
    }
    const newFilters = [...allFilters.slice(0, removedFilterIndex), ...allFilters.slice(removedFilterIndex + 1)];
    setFilterData(newFilters);
  };

  /** @deprecated use auditLogs instead of audits */
  const filterData = async payload => {
    const {
      filters: allFilters
    } = store.audits;
    let filterIndex = allFilters.findIndex(item => item.key === payload.key);
    if (filterIndex < 0) {
      filterIndex = allFilters.length;
    }
    const newFilters = [...allFilters.slice(0, filterIndex), payload, ...allFilters.slice(filterIndex + 1)];
    setFilterData(newFilters);
  };

  /** @deprecated use auditLogs instead of audits */
  const exportCSV = async () => {
    const {
      filters,
      sortBy,
      sortDirection,
      filter,
      headerProps = []
    } = store.audits;
    const f2o = __filterToObject(filters);
    startDownloadingCsv();
    const outputFileName = `audits.csv`;
    try {
      await api.audits.exportAudits(_extends({
        endpoint: 'csv/v2',
        headerProps: headerProps,
        sortDirection,
        sortBy,
        filter
      }, f2o, {
        offset: 0,
        outputFileName
      }));
    } catch (e) {
      console.error('failed to export audits - ', e);
    } finally {
      stopDownloadingCsv();
    }
  };
  return {
    initData,
    loadAudits,
    removeFilter,
    filterData,
    exportCSV
    // deleteAudits, // not implemented
  };
});