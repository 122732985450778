import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { ProviderType } from '@frontegg/rest-api';
import { PaymentProvider } from '../interfaces';
export default ((store, api, sharedActions) => {
  const setPaymentConfigState = state => {
    Object.assign(store.subscriptions.config, state);
  };
  const resetPaymentConfigState = () => {
    deepResetState(store, ['subscriptions', 'config'], initialState);
  };
  const setPaymentConfigError = error => {
    Object.assign(store.subscriptions.config, {
      error,
      loading: false,
      fetching: false
    });
  };
  const loadStripePaymentConfiguration = async () => {
    setPaymentConfigState({
      loading: true
    });
    try {
      const response = await api.subscriptions.getStripePaymentProviderConfiguration();
      const config = {
        paymentProvider: PaymentProvider.STRIPE,
        apiKey: response.publishableKey
      };
      setPaymentConfigState({
        config
      });
      setPaymentConfigState({
        loading: false,
        fetching: false
      });
    } catch (e) {
      setPaymentConfigError(errorHandler(e));
    }
  };
  const loadPaymentConfiguration = async () => {
    setPaymentConfigState({
      loading: true
    });
    try {
      var _await$api$subscripti;
      const response = (_await$api$subscripti = await api.subscriptions.getPaymentProviders()) != null ? _await$api$subscripti : [];
      const stripePaymentProvider = response.find(paymentProvider => paymentProvider.status === '1' && paymentProvider.providerType === ProviderType.Stripe);
      if (stripePaymentProvider) {
        await loadStripePaymentConfiguration();
      } else {
        setPaymentConfigError(errorHandler('Payment provider not configured'));
      }
    } catch (e) {
      setPaymentConfigError(errorHandler(e));
    }
  };
  return {
    setPaymentConfigState,
    resetPaymentConfigState,
    loadPaymentConfiguration
  };
});