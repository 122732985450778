import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["authorizationUrl"],
  _excluded2 = ["events", "url"];
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { AuthenticationTypes, UserVeirifedOriginTypes } from '../interfaces';
import { GTMEventAction, reportGTMEvent } from '../../helpers/gtm';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @private
   */
  const __getSocialLoginsConfigurationsV2 = async () => {
    const isAuthenticated = store.auth.isAuthenticated;
    let socialLoginsConfigV2;
    if (isAuthenticated) {
      socialLoginsConfigV2 = await api.auth.getSocialLoginProvidersV2ForAuthenticatedUser();
    } else {
      socialLoginsConfigV2 = await api.auth.getSocialLoginProvidersV2();
    }
    return socialLoginsConfigV2;
  };
  const setSocialLoginsState = state => {
    Object.assign(store.auth.socialLoginState, state);
  };
  const resetSocialLoginsState = () => {
    deepResetState(store, ['auth', 'socialLoginState'], initialState);
  };
  const setSocialLoginError = payload => {
    setSocialLoginsState({
      error: payload.error,
      loading: false,
      firstLoad: false
    });
  };
  const loadSocialLoginsConfiguration = async () => {
    try {
      setSocialLoginsState({
        loading: true
      });
      const socialLoginsConfig = await api.auth.getSocialLoginProviders();
      setSocialLoginsState({
        socialLoginsConfig,
        loading: false,
        firstLoad: false
      });
    } catch (e) {
      setSocialLoginsState({
        error: errorHandler(e),
        loading: false,
        firstLoad: false
      });
    }
  };
  const loadSocialLoginsConfigurationV2 = async payload => {
    try {
      var _payload$callback;
      const context = store.root.context;
      setSocialLoginsState({
        loading: true
      });
      const socialLoginsConfigV2 = await __getSocialLoginsConfigurationsV2();
      const {
        providers: customSocialLoginsConfig
      } = await api.auth.getCustomSocialLoginProvidersV1();
      const socialLoginsConfigWithFullUrl = socialLoginsConfigV2.map(_ref => {
        let {
            authorizationUrl
          } = _ref,
          config = _objectWithoutPropertiesLoose(_ref, _excluded);
        const baseUrl = api.fetch.getBaseUrl(context, authorizationUrl != null ? authorizationUrl : '');
        return _extends({}, config, {
          authorizationUrl: authorizationUrl ? `${baseUrl}${authorizationUrl}` : null
        });
      });
      const newState = {
        socialLoginsConfigV2: socialLoginsConfigWithFullUrl,
        customSocialLoginsConfig,
        loading: false,
        firstLoad: false
      };
      setSocialLoginsState(newState);
      payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, newState);
    } catch (e) {
      var _payload$callback2;
      payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
      setSocialLoginsState({
        error: errorHandler(e),
        loading: false,
        firstLoad: false
      });
    }
  };
  const loginViaSocialLogin = async _payload => {
    const {
        events,
        url
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    try {
      setSocialLoginsState({
        loading: true
      });
      const {
        email,
        isNewUser,
        userId,
        tenantId,
        name
      } = await api.auth.loginViaSocialLogin(payload);
      if (isNewUser) {
        var _events$signUpComplet, _events$userVerified;
        const basePayload = {
          email,
          url,
          createdAt: new Date(),
          id: userId,
          tenantId
        };
        const signUpCompletePayload = _extends({}, basePayload, {
          socialProvider: payload.provider,
          authenticationType: AuthenticationTypes.SOCIAL_LOGIN
        });
        events == null ? void 0 : (_events$signUpComplet = events.signUpComplete) == null ? void 0 : _events$signUpComplet.call(events, signUpCompletePayload);
        reportGTMEvent(GTMEventAction.SIGNUP_COMPLETED, signUpCompletePayload);
        const userVerifiedPayload = _extends({}, basePayload, {
          origin: UserVeirifedOriginTypes.SOCIAL_LOGIN,
          name
        });
        events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, userVerifiedPayload);
        reportGTMEvent(GTMEventAction.USER_VERIFIED, userVerifiedPayload);
      }
      if (userId) {
        localStorage.setItem('userId', userId);
      }
      actions.setLoginState({
        email,
        isNewUser
      });
      actions.refreshTokenForSocialLogins();
      localStorage.removeItem('register-quick-login');
      setSocialLoginsState({
        loading: false
      });
    } catch (e) {
      setSocialLoginsState({
        loading: false,
        error: errorHandler(e, 'Failed to authenticate'),
        firstLoad: false
      });
    }
  };
  return {
    setSocialLoginsState,
    resetSocialLoginsState,
    setSocialLoginError,
    loadSocialLoginsConfiguration,
    loadSocialLoginsConfigurationV2,
    loginViaSocialLogin
  };
});