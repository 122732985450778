import _extends from "@babel/runtime/helpers/esm/extends";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { devtools, subscribe } from '../valtio';
import { createApiClient, ContextHolder, FetchClient } from '@frontegg/rest-api';
import { buildAuthActions, createAuthState } from '../auth';
import { buildConnectivityActions, createConnectivityState } from '../connectivity';
import { buildSubscriptionsActions, createSubscriptionsState } from '../subscriptions';
import { buildVendorActions, createVendorState } from '../vendor';
import { buildAuditsActions, createAuditsState } from '../audits';
import setValue from 'set-value';
import { buildOldAuditsActions, createOldAuditsState } from '../audits-backward-compatibility';
import buildMockActions from '../mocks';
import { createProxy } from './proxy';
export * from '../auth';
export * from '../interfaces';
/**
 * @deprecated use createStore instead
 */
export const createFronteggStore = (rootInitialState, storeHolder, previewMode = false, authInitialState, overrideInitialState, builderMode = false, urlStrategy = 'path') => {
  return createStore({
    name: rootInitialState.appName,
    context: rootInitialState.context,
    storeHolder,
    previewMode,
    builderMode,
    urlStrategy,
    initialState: {
      root: _extends({}, rootInitialState),
      auth: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.auth, authInitialState),
      connectivity: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.connectivity),
      subscriptions: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.subscriptions),
      vendor: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.vendor),
      auditLogs: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.auditLogs),
      audits: _extends({}, overrideInitialState == null ? void 0 : overrideInitialState.audits)
    }
  });
};
export const createStore = options => {
  var _options$initialState, _options$initialState2, _options$initialState3, _options$initialState4, _options$initialState5, _options$initialState6, _options$initialState7;
  const {
    storeHolder
  } = options;
  const isSSR = typeof window === 'undefined';
  let holder = storeHolder;
  if (isSSR && storeHolder == null) {
    throw Error('createFronteggStore(initState, storeHolder) failed, storeHolder must not be null in Server-Side rendering');
  }
  if (!holder) {
    holder = window;
  }
  if (holder.store) {
    return holder.store;
  }
  const appName = options.name || 'default';
  const {
    context,
    previewMode = false,
    builderMode = false,
    urlStrategy = 'path'
  } = options;
  const contextHolder = ContextHolder.for(appName);
  if (!previewMode && !builderMode) {
    contextHolder.setContext(context);
  }
  if (context.requestCredentials === undefined) {
    context.requestCredentials = 'include';
  }
  const authState = createAuthState((_options$initialState = options.initialState) == null ? void 0 : _options$initialState.auth);
  const connectivityState = createConnectivityState((_options$initialState2 = options.initialState) == null ? void 0 : _options$initialState2.connectivity);
  const subscriptionsState = createSubscriptionsState((_options$initialState3 = options.initialState) == null ? void 0 : _options$initialState3.subscriptions);
  const vendorState = createVendorState((_options$initialState4 = options.initialState) == null ? void 0 : _options$initialState4.vendor);
  const auditsState = createAuditsState((_options$initialState5 = options.initialState) == null ? void 0 : _options$initialState5.auditLogs);
  const oldAuditsState = createOldAuditsState((_options$initialState6 = options.initialState) == null ? void 0 : _options$initialState6.audits);
  const overrideAuthState = (_options$initialState7 = options.initialState) == null ? void 0 : _options$initialState7.auth;
  if (overrideAuthState) {
    Object.keys(overrideAuthState).forEach(key => {
      setValue(authState, key, overrideAuthState[key], {
        merge: true
      });
    });
  }

  /** use parse and stringify to deep clone without any functions or references */
  const snapshotAuthState = JSON.parse(JSON.stringify(authState));
  const store = createProxy({
    root: {
      appName,
      context,
      urlStrategy,
      previewMode
    },
    auth: authState,
    connectivity: connectivityState,
    subscriptions: subscriptionsState,
    vendor: vendorState,
    auditLogs: auditsState,
    audits: oldAuditsState
  });
  if (typeof window !== 'undefined' && window.localStorage && (window.localStorage['DEBUG_FRONTEGG_STORE'] === 'true' || process.env.NODE_ENV === 'development')) {
    devtools(store, {
      name: `${appName} Store`,
      enabled: true
    });
  }
  const stateActions = {};
  const actions = {};
  contextHolder.setContext(options.context);
  contextHolder.setAppName(appName);
  const restApiInstance = createApiClient(appName);
  const fetchInstance = new FetchClient(appName);
  const api = _extends({}, restApiInstance, {
    fetch: fetchInstance
  });
  if (options.previewMode || options.builderMode) {
    const {
      authActions,
      authStateActions,
      connectivityActions,
      subscriptionsActions,
      subscriptionsStateActions,
      vendorActions,
      auditsActions
    } = buildMockActions(store, api, actions, snapshotAuthState);
    stateActions.auth = authStateActions;
    stateActions.connectivity = connectivityActions;
    stateActions.subscriptions = subscriptionsStateActions;
    stateActions.vendor = vendorActions;
    stateActions.auditLogs = auditsActions;
    stateActions.audits = {};
    Object.assign(actions, _extends({}, authActions, connectivityActions, subscriptionsActions, vendorActions, auditsActions));
    const fronteggStore = {
      dispatch: action => {
        setTimeout(() => {
          const [namespace, ...rest] = action.type.split('/');
          const actionName = rest.join('/');
          if (namespace === 'auth' && typeof authActions[actionName] === 'function') {
            authActions[actionName](action.payload);
          } else if (namespace === 'connectivity' && typeof connectivityActions[actionName] === 'function') {
            connectivityActions[actionName](action.payload);
          } else if (namespace === 'subscriptions' && typeof subscriptionsActions[actionName] === 'function') {
            subscriptionsActions[actionName](action.payload);
          } else if (namespace === 'vendor' && typeof vendorActions[actionName] === 'function') {
            vendorActions[actionName](action.payload);
          }
        });
      },
      getState: () => store,
      subscribe: callback => subscribe(store, callback),
      subscribeStateChanged: callback => subscribe(store, () => callback(store)),
      store,
      actions,
      stateActions
    };
    if (options.previewMode) {
      fronteggStore.dispatch({
        type: 'auth/requestAuthorize',
        payload: true
      });
    }
    if (holder) {
      holder.store = fronteggStore;
    }
    return fronteggStore;
  } else {
    const [authActions, authStateActions] = buildAuthActions(store, api, actions, snapshotAuthState);
    const connectivityActions = buildConnectivityActions(store, api, actions);
    const [subscriptionsActions, subscriptionsStateActions] = buildSubscriptionsActions(store, api, actions);
    const vendorActions = buildVendorActions(store, api, actions);
    const auditsActions = buildAuditsActions(store, api, actions);
    const oldAuditsActions = buildOldAuditsActions(store, api, actions);
    stateActions.auth = authStateActions;
    stateActions.connectivity = connectivityActions;
    stateActions.subscriptions = subscriptionsStateActions;
    stateActions.vendor = vendorActions;
    stateActions.auditLogs = auditsActions;
    stateActions.audits = oldAuditsActions;
    Object.assign(actions, _extends({}, authActions, connectivityActions, subscriptionsActions, vendorActions, auditsActions));
    return {
      dispatch: action => {
        const [namespace, ...rest] = action.type.split('/');
        const actionName = rest.join('/');
        if (namespace === 'auth' && typeof authActions[actionName] === 'function') {
          authActions[actionName](action.payload);
        } else if (namespace === 'connectivity' && typeof connectivityActions[actionName] === 'function') {
          connectivityActions[actionName](action.payload);
        } else if (namespace === 'subscriptions' && typeof subscriptionsActions[actionName] === 'function') {
          subscriptionsActions[actionName](action.payload);
        } else if (namespace === 'vendor' && typeof vendorActions[actionName] === 'function') {
          vendorActions[actionName](action.payload);
        }
      },
      getState: () => store,
      subscribe: callback => subscribe(store, callback),
      subscribeStateChanged: callback => subscribe(store, () => callback(store)),
      store,
      actions,
      stateActions
    };
  }
};