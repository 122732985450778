import { mockActionsExpect } from '../helpers';
import { auditLogsMetadataDemo } from './dummy';
import { buildAuditsMetadataActions } from '../../audits/AuditsMetadataState';
export default ((store, api, actions) => {
  const originalActions = buildAuditsMetadataActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setAuditsMetadataState', 'resetAuditsMetadataState']);
  mockedActions.loadAuditsMetadata = async () => {
    mockedActions.setAuditsMetadataState({
      loading: true,
      error: null
    });
    try {
      const {
        properties
      } = auditLogsMetadataDemo;
      mockedActions.setAuditsMetadataState({
        columns: properties,
        loading: false
      });
    } catch (e) {
      mockedActions.setAuditsMetadataState({
        error: e,
        loading: false
      });
    }
  };
  return mockedActions;
});