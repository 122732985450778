import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "profilePictureUrl"];
import { base64ToFormData, deepResetState, errorHandler, retry } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setProfileState = state => {
    Object.assign(store.auth.profileState, state);
  };
  const resetProfileState = () => {
    deepResetState(store, ['auth', 'profileState'], initialState);
  };
  const loadProfile = async () => {
    setProfileState({
      loading: true
    });
    try {
      const profile = await retry(api.teams.getProfile, 3, 2000);
      const currentUser = store.auth.user;
      actions.setUser(_extends({}, currentUser, profile));
      setProfileState({
        profile,
        loading: false
      });
    } catch (e) {
      setProfileState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const saveProfile = async _payload => {
    const {
        callback,
        profilePictureUrl
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    setProfileState({
      saving: true,
      error: null,
      loading: true
    });
    try {
      var _store$auth$profileSt;
      const oldProfileData = (_store$auth$profileSt = store.auth.profileState.profile) != null ? _store$auth$profileSt : {};
      let newProfilePictureUrl = oldProfileData.profilePictureUrl;
      if (profilePictureUrl !== oldProfileData.profilePictureUrl && profilePictureUrl) {
        const matchResult = (profilePictureUrl || '').match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/);
        if (matchResult) {
          const profileImage = base64ToFormData(profilePictureUrl, 'image');
          if (profileImage) {
            newProfilePictureUrl = await api.teams.updateProfileImage(profileImage);
            const imageTimeStamp = Date.now().toString();
            const urlTemplate = new URL(newProfilePictureUrl);
            urlTemplate.searchParams.set('t', imageTimeStamp);
            newProfilePictureUrl = urlTemplate.href;
          }
        }
      }
      const newProfileData = _extends({}, oldProfileData, payload, {
        profilePictureUrl: newProfilePictureUrl
      });

      //TODO: change the payload type to IUpdateUserProfile which is the right type to send, currently we send more data then actually needed.
      const profile = await api.users.updateUserProfileV2(newProfileData);
      const currentUser = store.auth.user;
      actions.setUser(_extends({}, currentUser, profile));
      actions.setProfileState({
        profile,
        saving: false,
        loading: false
      });
      callback == null ? void 0 : callback(newProfileData);
    } catch (e) {
      setProfileState({
        saving: false,
        error: errorHandler(e),
        loading: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const changePassword = async payload => {
    setProfileState({
      loading: true
    });
    try {
      var _payload$callback;
      await api.teams.changePassword(payload);
      setProfileState({
        loading: false,
        error: undefined
      });
      (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
    } catch (e) {
      var _payload$callback2;
      setProfileState({
        loading: false,
        error: errorHandler(e)
      });
      (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    }
  };
  return {
    setProfileState,
    resetProfileState,
    loadProfile,
    saveProfile,
    changePassword
  };
});