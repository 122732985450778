import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "deviceId"],
  _excluded2 = ["callback", "deviceId"];
import { MFAStep } from '../../MfaState/interfaces';
import { errorHandler } from '../../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * Shared logic for MFA SMS pre-verify step
   * @param payload.callback callback function to be called after the verification is done
   * @param payload.deviceId
   * @param payload.mfaToken
   * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
   */
  const preVerifyMFASMS = async (_ref, setLoadingAction) => {
    let {
        callback,
        deviceId
      } = _ref,
      payload = _objectWithoutPropertiesLoose(_ref, _excluded);
    setLoadingAction({
      loading: false,
      error: undefined
    });
    try {
      const data = await api.auth.preVerifyMFASMSForLogin(deviceId, payload);
      actions.setMfaState({
        otcToken: data.otcToken,
        step: MFAStep.smsVerifyCode,
        phoneNumber: data.phoneNumber
      });
      setLoadingAction({
        loading: false,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoadingAction({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };

  /**
   * Shared logic for MFA SMS verify step
   * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.otcToken
   * @param payload.code 6 digits code input by the user
   * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
   * @param isStepUp true when it's step up flow
   */
  const verifyMFASMS = async (_ref2, setLoadingAction, isStepUp = false) => {
    let {
        callback,
        deviceId
      } = _ref2,
      payload = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    setLoadingAction({
      loading: true
    });
    try {
      const data = await api.auth.verifyMFASMSForLoginV2(deviceId, payload);
      await actions.handleVerifyMFAResponse(data, isStepUp);
      setLoadingAction({
        loading: false,
        error: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setLoadingAction({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null);
    }
  };

  /**
   * Pre verify step for MFA SMS login
   * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.mfaToken
   */
  const preVerifyMFASMSForLogin = async payload => {
    return await preVerifyMFASMS(payload, actions.setLoginState);
  };

  /**
   * Verify step for MFA SMS login
   * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
   * @param payload.deviceId
   * @param payload.otcToken
   * @param payload.code 6 digits code input by the user
   */
  const verifyMFASMSForLogin = async payload => {
    return await verifyMFASMS(payload, actions.setLoginState, false);
  };
  return {
    preVerifyMFASMS,
    verifyMFASMS,
    preVerifyMFASMSForLogin,
    verifyMFASMSForLogin
  };
});