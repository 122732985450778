/**
 * @notice these file is used inside Next.js middleware  file in Next.js runs inside edge runtime
 * using redux-toolkit is not allowed inside edge runtime and breaks the build because it is using eval
 * please don't import from redux or from other files that import from redux
 */
import { AuthStrategyEnum } from '@frontegg/rest-api';
import { LoginStep } from './interfaces';
export const authStrategyLoginStepMap = {
  [AuthStrategyEnum.Code]: LoginStep.loginWithOtc,
  [AuthStrategyEnum.EmailAndPassword]: LoginStep.loginWithPassword,
  [AuthStrategyEnum.MagicLink]: LoginStep.magicLinkPreLoginSuccess,
  [AuthStrategyEnum.SmsCode]: LoginStep.loginWithSmsOtc
};

// don't remove this object because it is used in next js middleware (notice top of the file)
export const defaultFronteggRoutes = {
  authenticatedUrl: '/',
  loginUrl: '/account/login',
  stepUpUrl: '/account/step-up',
  logoutUrl: '/account/logout',
  activateUrl: '/account/activate',
  impersonationUrl: '/account/impersonate',
  acceptInvitationUrl: '/account/invitation/accept',
  forgetPasswordUrl: '/account/forget-password',
  resetPhoneNumberUrl: '/account/reset-phone-number',
  resetPasswordUrl: '/account/reset-password',
  socialLoginCallbackUrl: '/account/social/success',
  signUpUrl: '/account/sign-up',
  oidcRedirectUrl: '/account/oidc/callback',
  samlCallbackUrl: '/account/saml/callback',
  magicLinkCallbackUrl: '/account/login/magic-link',
  hostedLoginRedirectUrl: '/oauth/callback',
  openAppUrl: '/account/redirect',
  unlockAccountUrl: '/account/unlock',
  activateWithOTCUrl: '/account/activate/code',
  acceptInvitationWithOTCUrl: '/account/invitation/code',
  mfaMobileAuthenticator: '/account/mfa-mobile-authenticator'
};