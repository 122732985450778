import _extends from "@babel/runtime/helpers/esm/extends";
import { initialState } from './state';
import { deepResetState } from '../helpers';
export default ((store, api, sharedActions) => {
  const setVendorState = state => {
    Object.assign(store.vendor, state);
  };
  const resetVendorState = () => {
    deepResetState(store, ['vendor'], initialState);
  };
  const loadVendorPublicInfo = async () => {
    setVendorState({
      loading: true
    });
    try {
      var _vendorInfo$whiteLabe;
      const vendorInfo = await api.vendor.getVendorPublicInfo();
      vendorInfo.whiteLabelMode = (_vendorInfo$whiteLabe = vendorInfo.whiteLabelMode) != null ? _vendorInfo$whiteLabe : false;
      setVendorState(_extends({}, vendorInfo, {
        loading: false
      }));
    } catch (e) {
      setVendorState({
        loading: false
      });
      console.error('failed to getVendorPublicInfo - ', e);
    }
  };
  return {
    setVendorState,
    resetVendorState,
    loadVendorPublicInfo,
    /**
     * @deprecated use loadVendorPublicInfo instead
     */
    loadVendorPublicConfiguration: loadVendorPublicInfo
  };
});