import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { AuthStrategyEnum } from '@frontegg/rest-api';
import { ResetPhoneNumberStep } from './interfaces';
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { LoginStep } from '../LoginState/interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setResetPhoneNumberState = state => {
    Object.assign(store.auth.resetPhoneNumberState, state);
  };
  const resetResetPhoneNumberState = () => {
    deepResetState(store, ['auth', 'resetPhoneNumberState'], initialState);
  };
  const resetPhoneNumber = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    setResetPhoneNumberState({
      loading: true
    });
    try {
      const res = await api.auth.resetPhoneNumber(body);
      setResetPhoneNumberState({
        loading: false,
        error: undefined,
        resetPhoneNumberToken: res.resetPhoneNumberToken,
        step: ResetPhoneNumberStep.VerifyResetPhoneNumber
      });
      actions.setLoginState({
        email: body.email
      });
    } catch (e) {
      setResetPhoneNumberState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const verifyResetPhoneNumber = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded2);
    setResetPhoneNumberState({
      loading: true
    });
    try {
      const res = await api.auth.verifyResetPhoneNumber(body);
      setResetPhoneNumberState({
        loading: false,
        error: undefined,
        changePhoneNumberToken: res.changePhoneNumberToken,
        step: ResetPhoneNumberStep.ChangePhoneNumber
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setResetPhoneNumberState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const changePhoneNumber = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    setResetPhoneNumberState({
      loading: true
    });
    try {
      const {
        onRedirectTo,
        routes
      } = store.auth;
      await api.auth.changePhoneNumber({
        phoneNumber: body.phoneNumber,
        changePhoneNumberToken: body.changePhoneNumberToken
      });
      actions.setLoginState({
        step: LoginStep.loginWithSmsOtc
      });
      await actions.passwordlessPreLogin({
        type: AuthStrategyEnum.SmsCode,
        email: body.email,
        recaptchaToken: body.recaptchaToken
      });
      onRedirectTo(routes.loginUrl);
      setResetPhoneNumberState({
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setResetPhoneNumberState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  return {
    setResetPhoneNumberState,
    resetResetPhoneNumberState,
    resetPhoneNumber,
    verifyResetPhoneNumber,
    changePhoneNumber
  };
});