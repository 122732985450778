import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback", "profileImage"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"],
  _excluded9 = ["callback"],
  _excluded10 = ["callback"],
  _excluded11 = ["callback"];
import { TeamStateKeys } from '../../auth';
import { delay } from '../../helpers';
import { allUsersDemo, permissionsDemo, rolesDemo, usersDemo, userTeamDemo } from '../dummy';
import { buildTeamActions } from '../../auth/TeamState';
import { v4 as uuidv4 } from 'uuid';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildTeamActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setTeamState', 'resetTeamState', 'setTeamLoader', 'setTeamError', 'openAddUserDialog', 'closeAddUserDialog', 'openDeleteUserDialog', 'closeDeleteUserDialog']);
  mockedActions.loadUsersV2 = async payload => {
    var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort;
    const {
      silentLoading,
      callback
    } = payload;
    const teamState = store.auth.teamState;
    const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : teamState.pageSize;
    const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : teamState.pageOffset;
    const filter = (_payload$filter = payload.filter) != null ? _payload$filter : teamState.filterV2;
    const sort = (_payload$sort = payload.sort) != null ? _payload$sort : teamState.sortV2;
    mockedActions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: !silentLoading
    });
    mockedActions.setTeamState({
      pageSize,
      pageOffset,
      filterV2: filter,
      sortV2: sort
    });
    const totalPages = 2;
    const totalItems = 10;
    await delay();
    mockedActions.setTeamState({
      users: usersDemo,
      totalPages,
      totalItems,
      roles: rolesDemo,
      permissions: permissionsDemo
    });
    mockedActions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: false
    });
    callback == null ? void 0 : callback(usersDemo);
  };
  mockedActions.loadAllSubTenantsUsers = async payload => {
    var _payload$_limit, _payload$_offset, _payload$_filter, _payload$_sortBy, _payload$_order;
    const {
      silentLoading,
      callback
    } = payload;
    const teamState = store.auth.teamState;
    const _limit = (_payload$_limit = payload._limit) != null ? _payload$_limit : teamState.allUsersQueryParams._limit;
    const _offset = (_payload$_offset = payload._offset) != null ? _payload$_offset : teamState.allUsersQueryParams._offset;
    const _filter = (_payload$_filter = payload._filter) != null ? _payload$_filter : teamState.allUsersQueryParams._filter;
    const _sortBy = (_payload$_sortBy = payload._sortBy) != null ? _payload$_sortBy : teamState.allUsersQueryParams._sortBy;
    const _order = (_payload$_order = payload._order) != null ? _payload$_order : teamState.allUsersQueryParams._order;
    mockedActions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: !silentLoading
    });
    mockedActions.setTeamState({
      allUsersQueryParams: {
        _limit,
        _offset,
        _filter,
        _sortBy,
        _order
      }
    });
    const totalPages = 2;
    const totalItems = 10;
    await delay();
    mockedActions.setTeamState({
      allUsers: allUsersDemo,
      totalPages,
      totalItems,
      roles: rolesDemo,
      permissions: permissionsDemo
    });
    mockedActions.setTeamLoader({
      key: TeamStateKeys.USERS,
      value: false
    });
    callback == null ? void 0 : callback(allUsersDemo);
  };
  mockedActions.loadRoles = async payload => {
    var _payload$callback;
    mockedActions.setTeamLoader({
      key: TeamStateKeys.ROLES_AND_PERMISSIONS,
      value: true
    });
    await delay();
    mockedActions.setTeamState({
      roles: rolesDemo,
      permissions: permissionsDemo
    });
    mockedActions.setTeamLoader({
      key: TeamStateKeys.ROLES_AND_PERMISSIONS,
      value: true
    });
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, {
      roles: rolesDemo,
      permissions: permissionsDemo
    });
  };
  mockedActions.addUser = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    const teamState = store.auth.teamState;
    mockedActions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    await delay();
    const date = new Date();
    const newUser = _extends({}, userTeamDemo, {
      groups: []
    }, body, {
      id: `${uuidv4()}`,
      temporaryExpirationDate: body.expirationInSeconds ? new Date(date.setSeconds(date.getSeconds() + body.expirationInSeconds)) : undefined
    });
    callback == null ? void 0 : callback(newUser);
    mockedActions.setTeamState({
      users: [newUser, ...teamState.users],
      addUserDialogState: {
        open: false,
        loading: false
      }
    });
  };
  mockedActions.addUserToSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded2);
    const teamState = store.auth.teamState;
    mockedActions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    await delay();
    const newUser = _extends({}, userTeamDemo, body, {
      id: `${uuidv4()}`
    });
    mockedActions.setTeamState({
      users: [newUser, ...teamState.users],
      addUserDialogState: {
        open: false,
        loading: false
      }
    });
    callback == null ? void 0 : callback(null);
  };
  mockedActions.updateUser = async payload => {
    var _body$roleIds;
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    const {
      id: userId
    } = body;
    const teamState = store.auth.teamState;
    const oldUserData = teamState.users.find(user => user.id === body.id);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER,
      value: userId || ''
    });
    mockedActions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      })
    });
    mockedActions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: true
      }),
      users: teamState.users.map(user => {
        if (user.id === body.id) {
          return _extends({}, user, body);
        }
        return user;
      })
    });
    if (oldUserData.roleIds.length > 0 && ((_body$roleIds = body.roleIds) == null ? void 0 : _body$roleIds.length) === 0) {
      body.roleIds = [''];
    }
    await delay();
    const newUser = _extends({}, oldUserData, body);
    callback == null ? void 0 : callback(newUser);
    mockedActions.setTeamState({
      users: teamState.users.map(user => user.id === newUser.id ? _extends({}, user, newUser, {
        createdAt: user.createdAt,
        customData: user.customData,
        lastLogin: user.lastLogin
      }) : user)
    });
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER,
      value: false
    });
  };
  mockedActions.deleteUser = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded4);
    const teamState = store.auth.teamState;
    mockedActions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: true
      })
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamState({
      users: teamState.users.filter(user => user.id !== body.userId),
      deleteUserDialogState: {
        open: false,
        loading: false
      }
    });
  };
  mockedActions.updateUserExpirationTime = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded5);
    const teamState = store.auth.teamState;
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: true
    });
    const date = new Date();
    mockedActions.setTeamState({
      users: teamState.users.map(user => {
        return user.id === body.userId ? _extends({}, user, {
          temporaryExpirationDate: body.expirationInSeconds ? new Date(date.setSeconds(date.getSeconds() + body.expirationInSeconds)) : undefined
        }) : user;
      })
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: false
    });
  };
  mockedActions.setUserAsPermanent = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded6);
    const teamState = store.auth.teamState;
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: true
    });
    mockedActions.setTeamState({
      users: teamState.users.map(user => {
        return user.id === body.userId ? _extends({}, user, {
          temporaryExpirationDate: undefined
        }) : user;
      })
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: false
    });
  };
  mockedActions.deleteUserFromSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded7);
    const teamState = store.auth.teamState;
    mockedActions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: true
      })
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamState({
      allUsers: teamState.allUsers.filter(user => user.id !== body.userId),
      deleteUserDialogState: {
        open: false,
        loading: false
      }
    });
  };
  mockedActions.resendActivationLink = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded8);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_ACTIVATE_LINK,
      value: body.userId
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_ACTIVATE_LINK,
      value: false
    });
  };
  mockedActions.resendInvitationLink = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded9);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: body.email
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: false
    });
  };
  mockedActions.resendInvitationEmail = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded10);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: body.email
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: false
    });
  };
  mockedActions.resendInvitationLinkToAllSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded11);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: body.email
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setTeamLoader({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: false
    });
  };
  return mockedActions;
});