import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["user"],
  _excluded2 = ["callback", "events"],
  _excluded3 = ["user"],
  _excluded4 = ["callback", "events"],
  _excluded5 = ["user"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"];
import { initialState } from './state';
import { ContextHolder } from '@frontegg/rest-api';
import { errorHandler, delay, deepResetState } from '../../helpers';
import { ActivateAccountStep } from './interfaces';
import { UserVeirifedOriginTypes } from '../interfaces';
import { GTMEventAction, reportGTMEvent } from '../../helpers/gtm';
import { isMfaRequired } from '../helpers';
import { TeamStateKeys } from '../TeamState/interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setActivateState = state => {
    Object.assign(store.auth.activateAccountState, state);
  };
  const resetActivateState = () => {
    deepResetState(store, ['auth', 'activateAccountState'], initialState);
  };
  const setActivateStrategyState = state => {
    Object.assign(store.auth.activateAccountState.activationStrategy, state);
  };
  const preActivateAccount = async payload => {
    const {
      userId,
      token
    } = payload;
    setActivateState({
      loading: true,
      step: ActivateAccountStep.activating
    });
    try {
      const isAuthenticated = store.auth.isAuthenticated;
      if (isAuthenticated) {
        await actions.silentLogout();
      }
      const strategy = await api.auth.getActivateAccountStrategy({
        userId,
        token
      });
      setActivateStrategyState({
        strategy,
        loading: false,
        error: undefined
      });
      if (strategy.shouldSetPassword) {
        setActivateState({
          loading: false,
          step: ActivateAccountStep.activatingForm
        });
      }
    } catch (e) {
      setActivateState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const handleActivationResponse = async ({
    loginResponse,
    events,
    onRedirectTo,
    routes
  }) => {
    const {
        user
      } = loginResponse,
      rest = _objectWithoutPropertiesLoose(loginResponse, _excluded);
    if (user.redirectLocation) {
      window.location.href = user.redirectLocation;
      return;
    }
    if (isMfaRequired(user, store.root.appName)) {
      setActivateState({
        step: ActivateAccountStep.success
      });
      const mfaRequiredState = await actions.getMfaRequiredState(user);
      actions.setAuthState(mfaRequiredState);
      await delay(1000);
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      var _events$userVerified;
      setActivateState({
        step: ActivateAccountStep.success
      });
      const userVerifiedPayload = {
        email: user.email,
        origin: UserVeirifedOriginTypes.ACTIVATION_LINK,
        id: user.id,
        tenantId: user.tenantId,
        name: user.name,
        createdAt: new Date()
      };
      events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, userVerifiedPayload);
      reportGTMEvent(GTMEventAction.USER_VERIFIED, userVerifiedPayload);
      actions.afterAuthenticationStateUpdate(_extends({
        user
      }, rest), {
        isAuthenticated: true
      });
      await delay(1000);
      await actions.afterAuthNavigation();
      resetActivateState();
    }
  };
  const activateAccount = async _payload => {
    const {
        callback,
        events
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded2);
    setActivateState({
      loading: true
    });
    try {
      const onRedirectTo = ContextHolder.for(store.root.appName).onRedirectTo;
      const routes = store.auth.routes;
      const _await$api$auth$activ = await api.auth.activateAccountV2(payload),
        {
          user
        } = _await$api$auth$activ,
        rest = _objectWithoutPropertiesLoose(_await$api$auth$activ, _excluded3);
      await handleActivationResponse({
        loginResponse: _extends({
          user
        }, rest),
        events,
        onRedirectTo,
        routes
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setActivateState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const activateAccountWithCode = async _payload => {
    const {
        callback,
        events
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded4);
    setActivateState({
      loading: true
    });
    try {
      const onRedirectTo = ContextHolder.for(store.root.appName).onRedirectTo;
      const routes = store.auth.routes;
      const _await$api$auth$activ2 = await api.auth.activateAccountWithOTC(payload),
        {
          user
        } = _await$api$auth$activ2,
        rest = _objectWithoutPropertiesLoose(_await$api$auth$activ2, _excluded5);
      await handleActivationResponse({
        loginResponse: _extends({
          user
        }, rest),
        events,
        onRedirectTo,
        routes
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setActivateState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const getActivateAccountStrategy = async payload => {
    const {
        callback
      } = payload,
      params = _objectWithoutPropertiesLoose(payload, _excluded6);
    setActivateStrategyState({
      loading: true
    });
    try {
      const strategy = await api.auth.getActivateAccountStrategy(params);
      setActivateStrategyState({
        strategy,
        loading: false,
        error: undefined
      });
      callback == null ? void 0 : callback(strategy);
    } catch (e) {
      setActivateStrategyState({
        loading: false,
        error: errorHandler(e)
      });
    }
  };
  const resendActivationEmail = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded7);
    actions.setTeamLoader({
      key: TeamStateKeys.RESEND_ACTIVATE_LINK,
      value: body.email
    });
    setActivateState({
      loading: true
    });
    try {
      await api.auth.resendActivationEmail(body);
      setActivateState({
        loading: false,
        error: undefined,
        resentEmail: true
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setActivateState({
        loading: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    } finally {
      actions.setTeamLoader({
        key: TeamStateKeys.RESEND_ACTIVATE_LINK,
        value: false
      });
    }
  };
  return {
    setActivateState,
    resetActivateState,
    setActivateStrategyState,
    activateAccount,
    preActivateAccount,
    getActivateAccountStrategy,
    resendActivationEmail,
    activateAccountWithCode
  };
});