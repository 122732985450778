import { sha256 } from './sha256';
export async function generateCodeChallengePureJs(codeVerifier) {
  // noinspection TypeScriptValidateJSTypes pure sha256 recevie single params alg name
  const digest = sha256.digest(new TextEncoder().encode(codeVerifier));
  return btoa(String.fromCharCode(...new Uint8Array(digest))).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
export async function generateCodeChallengeNative(codeVerifier) {
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
  return btoa(String.fromCharCode(...new Uint8Array(digest))).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
export async function generateCodeChallenge(codeVerifier) {
  var _window$crypto, _window$crypto$subtle;
  // @ts-expect-error Crypto.subtle not available in non-secure domains
  if ((_window$crypto = window.crypto) != null && (_window$crypto$subtle = _window$crypto.subtle) != null && _window$crypto$subtle.digest) {
    return generateCodeChallengeNative(codeVerifier);
  } else {
    console.warn('Generating CodeChallenge in non-secure domain:', window.location.origin);
    return generateCodeChallengePureJs(codeVerifier);
  }
}
export function createRandomString(length = 16) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}