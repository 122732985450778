import _extends from "@babel/runtime/helpers/esm/extends";
import { getPathAndSearchParamsFromUrl, getRedirectUrl, isAbsoluteUrl } from '../helpers';
import { FRONTEGG_AFTER_AUTH_REDIRECT_URL } from '../../../constants';
import { SHOULD_STEP_UP_KEY } from '../../StepUpState/consts';
import { delay } from '../../../helpers';
import { isSteppedUp } from '../../StepUpState/helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @param url
   * @returns url without the origin if it's the same origin as the current window origin
   * @deprecated
   * Don't use this unless you must
   * In case the url is equal to the window.location.origin, it will return an empty string
   */
  function cleanUrlIfSameOrigin(url) {
    var _window, _window2;
    if (!url.startsWith((_window = window) == null ? void 0 : _window.location.origin)) {
      return url;
    }
    return url.replace((_window2 = window) == null ? void 0 : _window2.location.origin, '');
  }

  /**
   * @param user
   * @param tenants
   * @param activeTenant
   * @param additionalUpdate
   *
   * @protected
   */
  function afterAuthenticationStateUpdate({
    user,
    tenants = [],
    activeTenant
  }, additionalUpdate = {}) {
    const currentTenantsState = store.auth.tenantsState;
    actions.setAuthState(_extends({
      user,
      tenantsState: _extends({}, currentTenantsState, {
        tenants,
        activeTenant,
        loading: false
      })
    }, additionalUpdate));
  }

  /**
   * @param customLoginAuthenticatedUrl custom login authenticated url if exists
   * @returns the authenticated url to redirect to after auth navigation
   */
  async function __getUrlForAfterAuthNavigation({
    customLoginAuthenticatedUrl,
    preventRedirectUrlOriginCleaning
  }) {
    const {
      routes,
      includeQueryParam = false,
      enforceRedirectToSameSite = false,
      allowedRedirectOrigins = []
    } = store.auth;
    if (customLoginAuthenticatedUrl) {
      return getPathAndSearchParamsFromUrl(customLoginAuthenticatedUrl, preventRedirectUrlOriginCleaning);
    }
    const {
      authenticatedUrl,
      loginUrl,
      logoutUrl,
      socialLoginCallbackUrl,
      activateUrl
    } = routes;
    let finalUrl = window.localStorage.getItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
    if (!finalUrl || [loginUrl, logoutUrl, socialLoginCallbackUrl, activateUrl].includes(finalUrl)) {
      finalUrl = authenticatedUrl;
    }
    const redirectUrl = getRedirectUrl({
      authenticatedUrl: finalUrl,
      includeQueryParam,
      enforceRedirectToSameSite,
      allowedRedirectOrigins
    });
    if (preventRedirectUrlOriginCleaning) {
      return redirectUrl;
    }
    // clean origin if it's the same origin as the current window origin to avoid refresh in afterAuthNavigationUtil
    return cleanUrlIfSameOrigin(redirectUrl);
  }

  /**
   * Utility to share after auth navigation flow between login and step up
   * @param resetStateAction reset state action
   * @param options custom login authenticated url if exists
   */
  const afterAuthNavigationUtil = async (resetStateAction, options = {}) => {
    const {
      customLoginAuthenticatedUrl,
      forceStepUpUrl,
      shouldStepUpDuringLogin,
      preventRedirectUrlOriginCleaning = false
    } = options;

    // TODO: change to store.root.onRedirectTo
    const onRedirectTo = store.auth.onRedirectTo;
    let redirectUrl = undefined;
    if (forceStepUpUrl) {
      // scenario to get to here: invalid max age, try to step up -> logout, login with magic code/link -> redirect to step up page for email code as the second factor
      // we don't want to remove the FRONTEGG_AFTER_AUTH_REDIRECT_URL when we are in the step up flow
      redirectUrl = forceStepUpUrl;
    } else {
      var _window3;
      if (shouldStepUpDuringLogin) {
        // getUrlForAfterAuthNavigation give priority to the redirectUrl
        // avoiding use of getUrlForAfterAuthNavigation because we don't want to use the redirectUrl for magic link for example
        const localStorageRedirectUrl = window.localStorage.getItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
        if (localStorageRedirectUrl && !isAbsoluteUrl(localStorageRedirectUrl)) {
          redirectUrl = localStorageRedirectUrl;
        }
      }
      if (!redirectUrl) {
        redirectUrl = await __getUrlForAfterAuthNavigation({
          customLoginAuthenticatedUrl,
          preventRedirectUrlOriginCleaning
        });
      }
      (_window3 = window) == null ? void 0 : _window3.localStorage.removeItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
    }
    await delay(200);
    await resetStateAction();
    onRedirectTo(redirectUrl, {
      refresh: redirectUrl.startsWith('http')
    });
  };

  /**
   * After auth navigation for login flow
   * Handling also step up scenario when user silently logout to continue to step up
   */
  const afterAuthNavigation = async payload => {
    var _window4, _payload$preventRedir;
    const {
      customLoginAuthenticatedUrl,
      stepUpUrl
    } = store.auth.routes;

    // login with magic code, try to step up, no other mfa, invalid max age, force_enroll -> logout, login with first factor, not-stepped up jwt -> navigate to step up
    const shouldStepUp = (_window4 = window) == null ? void 0 : _window4.localStorage.getItem(SHOULD_STEP_UP_KEY);
    const user = store.auth.user;
    if (shouldStepUp) {
      var _window5;
      (_window5 = window) == null ? void 0 : _window5.localStorage.removeItem(SHOULD_STEP_UP_KEY);
    }
    if (stepUpUrl && shouldStepUp && !isSteppedUp(user)) {
      return afterAuthNavigationUtil(actions.resetLoginState, {
        forceStepUpUrl: stepUpUrl
      });
    }
    let customLoginURL = customLoginAuthenticatedUrl;
    if (!customLoginAuthenticatedUrl) {
      var _store$auth$routes;
      await actions.loadCustomLoginRoutes();
      customLoginURL = (_store$auth$routes = store.auth.routes) == null ? void 0 : _store$auth$routes.customLoginAuthenticatedUrl;
    }
    return afterAuthNavigationUtil(actions.resetLoginState, {
      customLoginAuthenticatedUrl: customLoginURL,
      shouldStepUpDuringLogin: !!shouldStepUp,
      preventRedirectUrlOriginCleaning: (_payload$preventRedir = payload == null ? void 0 : payload.preventRedirectUrlOriginCleaning) != null ? _payload$preventRedir : false
    });
  };

  /**
   * After auth navigation for step up flow
   */
  const afterStepUpAuthNavigation = async () => {
    return afterAuthNavigationUtil(actions.resetStepUpState);
  };
  return {
    afterAuthNavigationUtil,
    afterAuthNavigation,
    afterStepUpAuthNavigation,
    afterAuthenticationStateUpdate
  };
});