import _extends from "@babel/runtime/helpers/esm/extends";
import { createAuditLogsState, buildAuditLogsActions } from './AuditLogsState';
import { createAuditsMetadataState, buildAuditsMetadataActions } from './AuditsMetadataState';
export const createAuditsState = overrideState => {
  return {
    auditLogsState: createAuditLogsState(overrideState == null ? void 0 : overrideState.auditLogsState),
    auditsMetadataState: createAuditsMetadataState(overrideState == null ? void 0 : overrideState.auditsMetadataState)
  };
};
export const buildAuditsActions = (store, api, sharedActions) => {
  return _extends({}, buildAuditLogsActions(store, api, sharedActions), buildAuditsMetadataActions(store, api, sharedActions));
};