import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"];
import { errorHandler } from '../../../helpers';
import { TeamStateKeys } from '../interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * Helper function to perform loading and error handling with try catch
   * @param key loading / error key
   * @param callback withCallback function
   * @param block function to execute
   * @private
   */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const __activateLinkTryCatch = async (key, loadingValue, callback, block) => {
    actions.setTeamLoader({
      key,
      value: loadingValue
    });
    try {
      await block();
    } catch (error) {
      actions.setTeamError({
        key,
        value: errorHandler(error)
      });
      callback == null ? void 0 : callback(null, error);
    } finally {
      actions.setTeamLoader({
        key,
        value: false
      });
    }
  };
  const resendActivationLink = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    await __activateLinkTryCatch(TeamStateKeys.RESEND_ACTIVATE_LINK, body.userId, callback, async () => {
      await api.teams.resendActivationLink(body);
      callback == null ? void 0 : callback(true);
    });
  };
  const resendInvitationLink = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded2);
    await __activateLinkTryCatch(TeamStateKeys.RESEND_INVITATION_LINK, body.email, callback, async () => {
      await api.teams.resendInvitationLink(body);
      callback == null ? void 0 : callback(true);
    });
  };
  const resendInvitationEmail = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded3);
    await __activateLinkTryCatch(TeamStateKeys.RESEND_INVITATION_LINK, callback, body.email, async () => {
      await api.auth.resendInvitationEmail(body);
      callback == null ? void 0 : callback(true);
    });
  };
  const resendInvitationLinkToAllSubTenants = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded4);
    await __activateLinkTryCatch(TeamStateKeys.RESEND_INVITATION_LINK, body.email, callback, async () => {
      await api.teams.resendInvitationLinkToAllTenants(body);
      callback == null ? void 0 : callback(true);
    });
  };
  return {
    resendActivationLink,
    resendInvitationLink,
    resendInvitationEmail,
    resendInvitationLinkToAllSubTenants
  };
});