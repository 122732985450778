import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class PaymentProvidersApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getPaymentProviders = async () => {
      return this.get(urls.subscriptions.paymentProviders.v1);
    };
  }

}