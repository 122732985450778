import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "profilePictureUrl"];
import { buildProfileActions } from '../../auth/ProfileState';
import { delay } from '../../helpers';
import { profileStateDemo, userDemo, userProfileDemo } from '../dummy';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildProfileActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setProfileState', 'resetProfileState']);
  mockedActions.loadProfile = async () => {
    mockedActions.setProfileState({
      loading: true
    });
    await delay();
    actions.setUser(_extends({}, userDemo, userProfileDemo));
    mockedActions.setProfileState({
      loading: false,
      profile: userProfileDemo
    });
  };
  mockedActions.saveProfile = async _payload => {
    const {
        callback,
        profilePictureUrl
      } = _payload,
      payload = _objectWithoutPropertiesLoose(_payload, _excluded);
    mockedActions.setProfileState({
      saving: true,
      error: null
    });
    const oldProfileData = profileStateDemo;
    let newProfilePictureUrl = oldProfileData.profile.profilePictureUrl;
    if (profilePictureUrl !== oldProfileData.profile.profilePictureUrl && profilePictureUrl) {
      const matchResult = (profilePictureUrl || '').match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/);
      if (matchResult) {
        newProfilePictureUrl = profilePictureUrl;
      }
    }
    const newProfileData = _extends({}, oldProfileData.profile, payload, {
      profilePictureUrl: newProfilePictureUrl
    });
    const currentUser = userDemo;
    await delay();
    actions.setUser(_extends({}, currentUser, newProfileData));
    mockedActions.setProfileState({
      loading: false,
      error: null,
      saving: false,
      profile: newProfileData
    });
    callback == null ? void 0 : callback(newProfileData);
  };
  mockedActions.changePassword = async payload => {
    var _payload$callback;
    mockedActions.setProfileState({
      loading: true
    });
    await delay();
    mockedActions.setProfileState({
      loading: false,
      error: undefined
    });
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  };
  return mockedActions;
});