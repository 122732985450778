import _extends from "@babel/runtime/helpers/esm/extends";
import { deepResetState } from '../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setGroupsDialogsState = payload => {
    Object.assign(store.auth.groupsDialogsState, payload);
  };
  const resetGroupsDialogsState = () => {
    deepResetState(store, ['auth', 'groupsDialogsState'], initialState);
  };
  const openAddGroupDialog = () => {
    store.auth.groupsDialogsState.addGroupDialogState.open = true;
  };
  const closeAddGroupDialog = () => {
    Object.assign(store.auth.groupsDialogsState.addGroupDialogState, {
      open: false,
      group: undefined
    });
  };
  const openAddMembersToGroupDialog = () => {
    store.auth.groupsDialogsState.addMembersToGroupDialogState.open = true;
  };
  const closeAddMembersToGroupDialog = () => {
    Object.assign(store.auth.groupsDialogsState.addMembersToGroupDialogState, {
      open: false,
      userIds: []
    });
  };
  const openEditGroupDialog = payload => {
    Object.assign(store.auth.groupsDialogsState.editGroupDialogState, _extends({}, payload, {
      open: true
    }));
  };
  const closeEditGroupDialog = () => {
    Object.assign(store.auth.groupsDialogsState.editGroupDialogState, {
      open: false,
      loading: false,
      group: undefined
    });
  };
  const openDeleteGroupDialog = payload => {
    Object.assign(store.auth.groupsDialogsState.deleteGroupDialogState, _extends({}, payload, {
      open: true
    }));
  };
  const closeDeleteGroupDialog = () => {
    Object.assign(store.auth.groupsDialogsState.deleteGroupDialogState, {
      open: false,
      loading: false,
      group: undefined
    });
  };
  const openDeleteMemberFromGroupDialog = payload => {
    Object.assign(store.auth.groupsDialogsState.deleteMemberFromGroupDialogState, payload);
  };
  const closeDeleteMemberFromGroupDialog = () => {
    Object.assign(store.auth.groupsDialogsState.deleteMemberFromGroupDialogState, {
      open: false,
      userId: undefined,
      email: undefined
    });
  };
  return {
    setGroupsDialogsState,
    resetGroupsDialogsState,
    openAddGroupDialog,
    closeAddGroupDialog,
    openAddMembersToGroupDialog,
    closeAddMembersToGroupDialog,
    openEditGroupDialog,
    closeEditGroupDialog,
    openDeleteGroupDialog,
    closeDeleteGroupDialog,
    openDeleteMemberFromGroupDialog,
    closeDeleteMemberFromGroupDialog
  };
});