import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"];
import { buildRestrictionsActions } from '../../../auth/Security/RestrictionsState';
import { RestrictionType } from '@frontegg/rest-api';
import { delay, errorHandler } from '../../../helpers';
import { dummyIpConfig, dummyIps } from '../../dummy';
import { v4 as uuid } from 'uuid';
import { mockActionsExpect } from '../../helpers';
export default ((store, api, actions) => {
  const originalActions = buildRestrictionsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setRestrictionsState', 'setIpRestrictionsState', 'setEmailDomainRestrictionsState']);
  mockedActions.loadIpRestrictions = async () => {
    mockedActions.setIpRestrictionsState({
      loading: true,
      error: null
    });
    mockedActions.setIpRestrictionsState({
      data: {
        restrictions: dummyIps.filter(ip => ip.strategy === dummyIpConfig.strategy),
        config: dummyIpConfig
      },
      totalPages: 1,
      pagination: {
        _filter: RestrictionType.ALLOW,
        _limit: 50,
        _offset: 0
      },
      loading: false
    });
  };
  mockedActions.loadEmailDomainRestrictions = async () => {
    mockedActions.setEmailDomainRestrictionsState({
      loading: true,
      error: null
    });
    mockedActions.setEmailDomainRestrictionsState({
      loading: false,
      data: {
        restrictions: [{
          domain: 'mock.com',
          id: '1',
          type: RestrictionType.ALLOW
        }],
        config: {
          active: true,
          listType: RestrictionType.ALLOW,
          blockPublicDomains: false
        }
      }
    });
  };
  mockedActions.saveIpRestriction = async payload => {
    var _ipRestrictionsState$, _ipRestrictionsState$2, _newRestriction$isAct;
    const {
        callback
      } = payload,
      newRestriction = _objectWithoutPropertiesLoose(payload, _excluded);
    mockedActions.setIpRestrictionsState({
      saving: true,
      error: null
    });
    const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
    const filteredRestrictions = ipRestrictionsState.data.restrictions.filter(r => r.ip !== newRestriction.ip);
    mockedActions.setIpRestrictionsState({
      data: {
        config: (_ipRestrictionsState$ = (_ipRestrictionsState$2 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$2.config) != null ? _ipRestrictionsState$ : {
          isActive: false,
          strategy: RestrictionType.ALLOW
        },
        restrictions: [...filteredRestrictions, _extends({}, newRestriction, {
          createdAt: new Date(),
          updatedAt: new Date(),
          id: uuid(),
          isActive: (_newRestriction$isAct = newRestriction.isActive) != null ? _newRestriction$isAct : true,
          strategy: ipRestrictionsState.data.config.strategy
        })]
      },
      totalPages: 1,
      pagination: {
        _filter: RestrictionType.ALLOW,
        _limit: 50,
        _offset: 0
      },
      saving: false
    });
    callback == null ? void 0 : callback(true);
  };
  mockedActions.saveIpRestrictionsConfig = async payload => {
    var _ipRestrictionsState$3, _ipRestrictionsState$4, _ipRestrictionsState$5;
    const {
        callback
      } = payload,
      config = _objectWithoutPropertiesLoose(payload, _excluded2);
    const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
    mockedActions.setIpRestrictionsState({
      saving: true,
      error: null
    });
    mockedActions.setIpRestrictionsState({
      data: {
        config: _extends({}, (_ipRestrictionsState$3 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$3.config, config),
        restrictions: (_ipRestrictionsState$4 = (_ipRestrictionsState$5 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$5.restrictions) != null ? _ipRestrictionsState$4 : []
      },
      pagination: {
        _filter: RestrictionType.ALLOW,
        _limit: 50,
        _offset: 0
      },
      totalPages: 1,
      saving: false
    });
    mockedActions.setIpRestrictionsState({
      saving: false,
      error: null
    });
    callback == null ? void 0 : callback(true);
  };
  mockedActions.deleteIpRestriction = async payload => {
    var _ipRestrictionsState$6;
    const {
      callback,
      id
    } = payload;
    mockedActions.setIpRestrictionsState({
      saving: true,
      error: null
    });
    const ipRestrictionsState = store.auth.restrictionsState.ipRestrictions;
    mockedActions.setIpRestrictionsState({
      data: {
        config: ((_ipRestrictionsState$6 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$6.config) || {
          isActive: false,
          strategy: RestrictionType.ALLOW
        },
        restrictions: ipRestrictionsState.data.restrictions.filter(ip => ip.id !== id)
      },
      pagination: {
        _filter: RestrictionType.ALLOW,
        _limit: 50,
        _offset: 0
      },
      totalPages: 1,
      saving: false,
      error: null
    });
    callback == null ? void 0 : callback(true);
  };
  mockedActions.checkIfUserIpValid = async () => {
    mockedActions.setIpRestrictionsState({
      loading: true,
      error: null
    });
    try {
      const ipRestrictionState = store.auth.restrictionsState.ipRestrictions;
      const userIp = dummyIps[0].ip;
      const userIpValid = !!ipRestrictionState.data.restrictions.find(restriction => restriction.isActive && restriction.ip === userIp);
      mockedActions.setIpRestrictionsState({
        userIpValid,
        loading: false
      });
    } catch (e) {
      mockedActions.setIpRestrictionsState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  mockedActions.saveEmailDomainRestriction = async payload => {
    var _emailDomainRestricti, _emailDomainRestricti2;
    const {
        callback
      } = payload,
      restriction = _objectWithoutPropertiesLoose(payload, _excluded3);
    const emailDomainRestrictionsState = store.auth.restrictionsState.emailDomainRestrictions;
    mockedActions.setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setEmailDomainRestrictionsState({
      data: {
        config: ((_emailDomainRestricti = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti.config) || {
          active: false,
          blockPublicDomains: false,
          listType: restriction.type
        },
        restrictions: [...(((_emailDomainRestricti2 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti2.restrictions) || []), _extends({}, restriction, {
          id: Date.now().toString()
        })]
      },
      saving: false
    });
  };
  mockedActions.saveEmailDomainRestrictionsConfig = async payload => {
    var _emailDomainRestricti3;
    const {
        callback
      } = payload,
      config = _objectWithoutPropertiesLoose(payload, _excluded4);
    const emailDomainRestrictionsState = store.auth.restrictionsState.emailDomainRestrictions;
    mockedActions.setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setEmailDomainRestrictionsState({
      data: {
        config: {
          active: config.active,
          blockPublicDomains: config.blockPublicDomains || false,
          listType: config.type || RestrictionType.ALLOW
        },
        restrictions: [...(((_emailDomainRestricti3 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti3.restrictions) || [])]
      },
      saving: false
    });
  };
  mockedActions.deleteEmailDomainRestriction = async payload => {
    var _emailDomainRestricti4, _emailDomainRestricti5;
    const {
      id
    } = payload;
    const emailDomainRestrictionsState = store.auth.restrictionsState.emailDomainRestrictions;
    mockedActions.setEmailDomainRestrictionsState({
      saving: true,
      error: null
    });
    await delay();
    mockedActions.setEmailDomainRestrictionsState({
      data: {
        config: ((_emailDomainRestricti4 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti4.config) || {
          active: false,
          blockPublicDomains: false,
          listType: RestrictionType.ALLOW
        },
        restrictions: (((_emailDomainRestricti5 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti5.restrictions) || []).filter(restriction => restriction.id === id)
      },
      saving: false
    });
  };
  return mockedActions;
});