import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { deepResetState, errorHandler } from '../../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @protected
   */
  const __getSecurityPolicyPublicStateWithCustomLogin = async securityPolicyPublicState => {
    var _authStrategies$mainA, _authStrategies$mainA2;
    const isCustomLoginEnabled = await actions.customLoginEnabled();
    if (!isCustomLoginEnabled) {
      return securityPolicyPublicState;
    }
    const authStrategies = await __getAuthStrategy();
    if (!(authStrategies != null && (_authStrategies$mainA = authStrategies.mainAuthStrategies) != null && (_authStrategies$mainA2 = _authStrategies$mainA[0]) != null && _authStrategies$mainA2.strategy)) {
      return securityPolicyPublicState;
    }
    return _extends({}, securityPolicyPublicState, {
      authStrategy: authStrategies.mainAuthStrategies[0].strategy
    });
  };

  /**
   * @private
   */
  const __getAuthStrategy = async () => {
    const isAuthenticated = store.auth.isAuthenticated;
    let policy;
    if (isAuthenticated) {
      policy = await api.auth.getPublicAuthStrategiesConfigForAuthenticatedUser();
    } else {
      policy = await api.auth.getVendorPublicAuthStrategiesConfig();
    }
    return policy;
  };

  /**
   * @private
   */
  const __setSecurityPolicyPublicStateForCustomLogin = async authStrategy => {
    var _store$auth$securityP, _store$auth$securityP2;
    const isCustomLoginEnabled = await actions.customLoginEnabled();
    if (!authStrategy || !isCustomLoginEnabled) {
      return;
    }
    const {
      policy
    } = (_store$auth$securityP = (_store$auth$securityP2 = store.auth.securityPolicyState) == null ? void 0 : _store$auth$securityP2.publicPolicy) != null ? _store$auth$securityP : {
      policy: null
    };
    setSecurityPolicyPublicState({
      policy: _extends({}, policy, {
        authStrategy
      }),
      loading: false
    });
  };
  const setSecurityPolicyState = state => {
    Object.assign(store.auth.securityPolicyState, state);
  };
  const setSecurityPolicyGlobalState = state => {
    Object.assign(store.auth.securityPolicyState.globalPolicy, state);
  };
  const setSecurityPolicyPublicState = state => {
    Object.assign(store.auth.securityPolicyState.publicPolicy, state);
  };
  const setSecurityPolicyMfaState = state => {
    Object.assign(store.auth.securityPolicyState.mfaPolicy, state);
  };
  const setSecurityPolicyVendorMfaState = state => {
    Object.assign(store.auth.securityPolicyState.vendorMfaPolicy, state);
  };
  const setSecurityPolicyLockoutState = state => {
    Object.assign(store.auth.securityPolicyState.lockoutPolicy, state);
  };
  const setSecurityPolicyVendorLockoutState = state => {
    Object.assign(store.auth.securityPolicyState.vendorLockoutPolicy, state);
  };
  const setSecurityPolicyCaptchaState = state => {
    Object.assign(store.auth.securityPolicyState.captchaPolicy, state);
  };
  const setSecurityPolicyPasswordHistoryState = state => {
    Object.assign(store.auth.securityPolicyState.passwordHistoryPolicy, state);
  };
  const setSecurityPolicyVendorPasswordHistoryState = state => {
    Object.assign(store.auth.securityPolicyState.vendorPasswordHistoryPolicy, state);
  };
  const setSecurityPolicyPasswordState = state => {
    Object.assign(store.auth.securityPolicyState.passwordPolicy, state);
  };
  const setSecurityPolicyAuthStrategyPublicState = state => {
    Object.assign(store.auth.securityPolicyState.publicAuthStrategyPolicy, state);
  };
  const resetSecurityPolicyState = () => {
    deepResetState(store, ['auth', 'securityPolicyState'], initialState);
  };
  const loadSecurityPolicy = async () => {
    await Promise.all([loadGlobalSecurityPolicy(), loadSecurityPolicyMfa(), loadSecurityPolicyVendorMfa(), loadSecurityPolicyLockout(), loadSecurityPolicyVendorLockout(), loadSecurityPolicyVendorPasswordHistory(), loadSecurityPolicyCaptcha(), loadPublicAuthStrategiesPolicy()]);
  };
  const loadGlobalSecurityPolicy = async () => {
    setSecurityPolicyGlobalState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getGlobalSecurityPolicy();
      setSecurityPolicyGlobalState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyGlobalState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadPublicSecurityPolicy = async () => {
    setSecurityPolicyPublicState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getVendorConfig();
      const policyWithCustomLoginAuthStrategy = await __getSecurityPolicyPublicStateWithCustomLogin(policy);
      setSecurityPolicyPublicState({
        policy: policyWithCustomLoginAuthStrategy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPublicState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadVendorPasswordConfig = async () => {
    setSecurityPolicyPasswordState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getPasswordConfigPolicy();
      setSecurityPolicyPasswordState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPasswordState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyMfa = async () => {
    setSecurityPolicyMfaState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getMfaPolicy();
      setSecurityPolicyMfaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyMfaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorMfa = async () => {
    setSecurityPolicyMfaState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getVendorMfaPolicy();
      setSecurityPolicyVendorMfaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorMfaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyLockout = async () => {
    setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getLockoutPolicy();
      setSecurityPolicyLockoutState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyLockoutState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorLockout = async () => {
    setSecurityPolicyVendorLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getVendorLockoutPolicy();
      setSecurityPolicyVendorLockoutState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorLockoutState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyCaptcha = async () => {
    setSecurityPolicyLockoutState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getCaptchaPolicy();
      setSecurityPolicyCaptchaState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyCaptchaState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyPasswordHistory = async () => {
    setSecurityPolicyPasswordHistoryState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getPasswordHistoryPolicy();
      setSecurityPolicyPasswordHistoryState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyPasswordHistoryState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSecurityPolicyVendorPasswordHistory = async () => {
    setSecurityPolicyVendorPasswordHistoryState({
      loading: true,
      error: null
    });
    try {
      const policy = await api.auth.getVendorPasswordHistoryPolicy();
      setSecurityPolicyVendorPasswordHistoryState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyVendorPasswordHistoryState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadPublicAuthStrategiesPolicy = async () => {
    setSecurityPolicyAuthStrategyPublicState({
      loading: true,
      error: null
    });
    try {
      var _policy$mainAuthStrat, _policy$mainAuthStrat2;
      const policy = await __getAuthStrategy();
      await __setSecurityPolicyPublicStateForCustomLogin(policy == null ? void 0 : (_policy$mainAuthStrat = policy.mainAuthStrategies) == null ? void 0 : (_policy$mainAuthStrat2 = _policy$mainAuthStrat[0]) == null ? void 0 : _policy$mainAuthStrat2.strategy);
      setSecurityPolicyAuthStrategyPublicState({
        policy,
        loading: false
      });
    } catch (e) {
      setSecurityPolicyAuthStrategyPublicState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const saveSecurityPolicyMfa = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded);
    setSecurityPolicyMfaState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.securityPolicy.saveMfaPolicy(newSecurityPolicy);
      setSecurityPolicyMfaState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyMfaState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSecurityPolicyLockout = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded2);
    setSecurityPolicyLockoutState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.saveLockoutPolicy(newSecurityPolicy);
      setSecurityPolicyLockoutState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyLockoutState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSecurityPolicyPasswordHistory = async payload => {
    const {
        callback
      } = payload,
      newSecurityPolicy = _objectWithoutPropertiesLoose(payload, _excluded3);
    setSecurityPolicyPasswordHistoryState({
      saving: true,
      error: null
    });
    try {
      const policy = await api.auth.savePasswordHistoryPolicy(newSecurityPolicy);
      setSecurityPolicyPasswordHistoryState({
        policy,
        saving: false
      });
      callback == null ? void 0 : callback(policy);
    } catch (e) {
      setSecurityPolicyPasswordHistoryState({
        saving: false,
        error: errorHandler(e)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  return {
    // reducers
    setSecurityPolicyState,
    setSecurityPolicyGlobalState,
    setSecurityPolicyPublicState,
    setSecurityPolicyMfaState,
    setSecurityPolicyVendorMfaState,
    setSecurityPolicyLockoutState,
    setSecurityPolicyVendorLockoutState,
    setSecurityPolicyCaptchaState,
    setSecurityPolicyPasswordHistoryState,
    setSecurityPolicyVendorPasswordHistoryState,
    resetSecurityPolicyState,
    setSecurityPolicyPasswordState,
    setSecurityPolicyAuthStrategyPublicState,
    // actions
    loadSecurityPolicy,
    loadGlobalSecurityPolicy,
    loadPublicSecurityPolicy,
    loadVendorPasswordConfig,
    loadSecurityPolicyMfa,
    loadSecurityPolicyVendorMfa,
    loadSecurityPolicyLockout,
    loadSecurityPolicyVendorLockout,
    loadSecurityPolicyCaptcha,
    loadSecurityPolicyPasswordHistory,
    loadSecurityPolicyVendorPasswordHistory,
    loadPublicAuthStrategiesPolicy,
    saveSecurityPolicyMfa,
    saveSecurityPolicyLockout,
    saveSecurityPolicyPasswordHistory,
    // protected
    __getSecurityPolicyPublicStateWithCustomLogin
  };
});