import { buildCustomLoginActions } from '../../auth/CustomLoginState';
import { delay } from '../../helpers';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildCustomLoginActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setCustomLoginState', 'resetCustomLoginState']);
  mockedActions.loadTenantMetadata = async () => {
    mockedActions.setCustomLoginState({
      loading: true
    });
    await delay();
    mockedActions.setCustomLoginState({
      loading: false
    });
  };
  mockedActions.updateTenantMetadata = async payload => {
    const {
      callback
    } = payload;
    mockedActions.setCustomLoginState({
      loading: true,
      error: null
    });
    await delay();
    mockedActions.setCustomLoginState({
      loading: false,
      error: null
    });
    callback == null ? void 0 : callback(true);
  };
  return mockedActions;
});