import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["phoneNumber"],
  _excluded2 = ["authenticatedUrl"];
import { ContextHolder } from '@frontegg/rest-api';
import { defaultFronteggRoutes } from './LoginState/consts';
export * from './LoginState/helpers';
export * from './StepUpState/helpers';
export * from './Entitlements/helpers';
export const isMfaRequired = (user, appName) => {
  const contextHolder = ContextHolder.for(appName);
  if (user.mfaRequired && user.mfaToken) {
    contextHolder.setAccessToken(null);
    contextHolder.setUser(null);
    return true;
  } else {
    contextHolder.setAccessToken(user.accessToken);
    contextHolder.setUser(user);
    return false;
  }
};
export const mapMetaDataObjectToActions = (obj, path = []) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return [...acc, ...mapMetaDataObjectToActions(value, [...path, key])];
    }
    return [...acc, {
      type: [...path, key].join('/'),
      payload: value
    }];
  }, []);
};
export const getUri = urlStrategy => {
  if (urlStrategy === 'path') {
    var _window;
    return ((_window = window) != null ? _window : document).location.pathname;
  } else {
    var _window2;
    const uri = (((_window2 = window) != null ? _window2 : document).location.hash || '#').substring(1);
    if (uri.indexOf('?') !== -1) {
      return uri.substring(0, uri.indexOf('?'));
    } else {
      return uri;
    }
  }
};
export const extractPhoneNumber = _ref => {
  let rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  return rest;
};
export const isAuthRoute = (path, routes) => {
  const _defaultFronteggRoute = _extends({}, defaultFronteggRoutes, routes),
    authRoutes = _objectWithoutPropertiesLoose(_defaultFronteggRoute, _excluded2);
  return Object.values(authRoutes).includes(path);
};