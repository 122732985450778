import { ContextHolder, MFAStrategyEnum } from '@frontegg/rest-api';
import { MFAStep } from '../MfaState/interfaces';
export const isAbsoluteUrl = path => {
  try {
    new URL(path);
    return true;
  } catch (e) {
    return false;
  }
};
const isValidRedirectUrl = (redirectUrl, allowedRedirectOrigins) => {
  const currentUrl = new URL(window.location.href);
  const redirectURL = new URL(redirectUrl);
  const isSameSite = redirectURL.origin === currentUrl.origin;
  const isOriginInWhiteList = allowedRedirectOrigins.includes(redirectURL.origin);
  return isOriginInWhiteList || isSameSite;
};
export const getRedirectUrl = ({
  authenticatedUrl,
  enforceRedirectToSameSite,
  allowedRedirectOrigins,
  includeQueryParam
}) => {
  const currentUrl = new URL(window.location.href);
  let redirectUrl = authenticatedUrl;
  const redirectUrlQueryParam = currentUrl.searchParams.get('redirectUrl');
  if (redirectUrlQueryParam) {
    if (!enforceRedirectToSameSite || !isAbsoluteUrl(redirectUrlQueryParam)) {
      redirectUrl = redirectUrlQueryParam;
    } else {
      if (isValidRedirectUrl(redirectUrlQueryParam, allowedRedirectOrigins)) {
        redirectUrl = redirectUrlQueryParam;
      } else {
        console.warn('The redirect url param is not valid, the origin is a different site');
      }
    }
  } else if (includeQueryParam) {
    redirectUrl += window.location.search;
  }
  return redirectUrl;
};
export const getSearchParamsFromUrl = url => {
  const {
    searchParams
  } = new URL(url);
  const stringifySearchParam = searchParams.toString();
  if (stringifySearchParam) {
    return `?${stringifySearchParam}`;
  }
  return undefined;
};
export const getPathAndSearchParamsFromUrl = (url, preventRedirectUrlOriginCleaning = false) => {
  const {
    pathname,
    href
  } = new URL(url);
  if (preventRedirectUrlOriginCleaning) {
    return href;
  }
  const searchParams = getSearchParamsFromUrl(url);
  if (searchParams) {
    return pathname + searchParams;
  }
  return pathname;
};

/**
 *
 * @param key
 * @returns the value of the param as string or undefined if it does not exist
 */
export const getSearchParam = key => {
  const params = new URLSearchParams(window.location.search);
  const param = params.get(key);
  return param != null ? param : undefined;
};
export const TENANT_ID_PARAM_KEY = 'tenantId';
export const getNumberOfMfaDevices = mfaDevices => {
  const numberOfWebAuthnDevices = +Boolean(mfaDevices.webauthn.length);
  const numberOfPhoneDevices = +Boolean(mfaDevices.phones.length);
  const numberOfAuthenticators = +Boolean(mfaDevices.authenticators.length);
  const numberOfEmails = +Boolean(mfaDevices.emails.length);
  const totalDevices = numberOfWebAuthnDevices + numberOfPhoneDevices + numberOfAuthenticators + numberOfEmails;
  return {
    numberOfWebAuthnDevices,
    numberOfPhoneDevices,
    numberOfAuthenticators,
    numberOfEmails,
    totalDevices
  };
};
export const getMfaStepForEnrolledUsers = mfaDevices => {
  const {
    numberOfPhoneDevices,
    numberOfAuthenticators,
    numberOfEmails,
    totalDevices
  } = getNumberOfMfaDevices(mfaDevices);
  if (totalDevices === 1) {
    if (numberOfAuthenticators) {
      return MFAStep.authenticatorApp;
    } else if (numberOfPhoneDevices) {
      return MFAStep.smsVerifyCode;
    } else if (numberOfEmails) {
      return MFAStep.emailVerifyCode;
    }
  }
  return MFAStep.verify;
};
export const getMfaStepForNotEnrolledUsers = mfaStrategies => {
  if (mfaStrategies.length === 1) {
    const [strategy] = mfaStrategies;
    if (strategy === MFAStrategyEnum.AuthenticatorApp) {
      return MFAStep.authenticatorApp;
    } else if (strategy === MFAStrategyEnum.SMS) {
      return MFAStep.smsSetPhoneNumber;
    } else if (strategy === MFAStrategyEnum.EmailCode) {
      return MFAStep.emailVerifyCode;
    }
  }
  return MFAStep.verify;
};
export const isOauthCallbackRoute = activeUri => {
  return activeUri === '/oauth/callback';
};
export function isEmailPayload(payload) {
  return 'email' in payload;
}
export const getBaseNameWithoutSlashSuffix = state => {
  const basename = ContextHolder.for(state.root.appName).getBasename();
  if (basename != null && basename.endsWith('/')) {
    return basename.slice(0, -1);
  }
  return basename;
};