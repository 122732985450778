import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"];
import { buildSessionsPolicyActions } from '../../../auth/Security/SessionsPolicyState';
import { mockActionsExpect } from '../../helpers';
export default ((store, api, actions) => {
  const originalActions = buildSessionsPolicyActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setSessionsPolicyState', 'resetSessionsPolicyState']);
  mockedActions.loadSessionsPolicy = async () => {
    mockedActions.setSessionsPolicyState({
      loading: true,
      error: null
    });
    const sessionPolicyState = store.auth.sessionsPolicyState;
    mockedActions.setSessionsPolicyState(_extends({}, sessionPolicyState, {
      loading: false
    }));
  };
  mockedActions.createOrUpdateSessionsPolicy = async payload => {
    const {
        callback
      } = payload,
      rest = _objectWithoutPropertiesLoose(payload, _excluded);
    mockedActions.setSessionsPolicyState({
      loading: true,
      error: null
    });
    callback == null ? void 0 : callback(true);
    mockedActions.setSessionsPolicyState({
      configurations: rest,
      loading: false
    });
  };
  return mockedActions;
});