import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "samlVendor"];
import { delay, omitProps } from '../../helpers';
import { samlConfigurationDemo, samlMetadataDemo, ssoStateDemo } from '../dummy';
import { buildSSOActions } from '../../auth/SSOState';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildSSOActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setSSOState', 'resetSSOState']);
  mockedActions.loadSSOConfigurations = async () => {
    mockedActions.setSSOState({
      loading: true
    });
    await delay();
    mockedActions.setSSOState({
      samlConfiguration: samlConfigurationDemo,
      loading: false,
      firstLoad: false
    });
  };
  mockedActions.saveSSOConfigurations = async payload => {
    var _samlMetadataDemo$con, _samlMetadataDemo$con2;
    const {
        callback,
        samlVendor
      } = payload,
      newSamlConfiguration = _objectWithoutPropertiesLoose(payload, _excluded);
    const oldSamlConfiguration = ssoStateDemo.samlConfiguration;
    const samlConfiguration = _extends({}, oldSamlConfiguration, newSamlConfiguration);
    let loaderKey = 'saving';
    if ((samlConfiguration == null ? void 0 : samlConfiguration.enabled) !== oldSamlConfiguration.enabled) {
      loaderKey = 'loading';
    }
    const firstTimeConfigure = !(samlConfiguration != null && samlConfiguration.domain);
    if (firstTimeConfigure) {
      mockedActions.setSSOState({
        samlConfiguration: _extends({}, oldSamlConfiguration, samlConfiguration)
      });
      return;
    } else {
      mockedActions.setSSOState({
        error: undefined,
        [loaderKey]: true
      });
    }
    await delay();
    samlConfiguration.acsUrl = samlMetadataDemo == null ? void 0 : (_samlMetadataDemo$con = samlMetadataDemo.configuration) == null ? void 0 : _samlMetadataDemo$con.acsUrl;
    samlConfiguration.spEntityId = samlMetadataDemo == null ? void 0 : (_samlMetadataDemo$con2 = samlMetadataDemo.configuration) == null ? void 0 : _samlMetadataDemo$con2.spEntityId;
    const updateSamlConfiguration = omitProps(samlConfiguration, ['validated', 'generatedVerification', 'createdAt', 'updatedAt']);
    if ((oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.domain) !== (updateSamlConfiguration == null ? void 0 : updateSamlConfiguration.domain)) {
      updateSamlConfiguration.ssoEndpoint = '';
      updateSamlConfiguration.publicCertificate = '';
      updateSamlConfiguration.signRequest = false;
    }
    updateSamlConfiguration.type = samlVendor == null ? void 0 : samlVendor.toLowerCase();
    await delay();
    mockedActions.setSSOState({
      samlConfiguration: updateSamlConfiguration,
      error: undefined,
      [loaderKey]: false
    });
    callback == null ? void 0 : callback(true);
  };
  mockedActions.saveSSOConfigurationsFile = async configFile => {
    const loaderKey = 'saving';
    mockedActions.setSSOState({
      error: undefined,
      [loaderKey]: true
    });
    const oldSamlConfiguration = store.auth.ssoState.samlConfiguration;
    const newSamlConfiguration = _extends({}, oldSamlConfiguration, {
      enabled: true
    }, configFile[0]);
    mockedActions.setSSOState({
      samlConfiguration: newSamlConfiguration,
      error: undefined,
      [loaderKey]: false
    });
  };
  mockedActions.validateSSODomain = async payload => {
    const {
      callback
    } = payload != null ? payload : {};
    const samlConfiguration = ssoStateDemo.samlConfiguration;
    mockedActions.setSSOState({
      error: undefined,
      saving: true
    });
    await delay();
    mockedActions.setSSOState({
      samlConfiguration: _extends({}, samlConfiguration, {
        validated: true
      }),
      error: undefined,
      saving: false
    });
    callback == null ? void 0 : callback(true);
  };
  mockedActions.loadSSOAuthorizationRoles = async () => {
    await delay();
    const data = {
      roleIds: ['1', '2', '3']
    };
    mockedActions.setSSOState({
      authorizationRoles: data.roleIds,
      error: undefined
    });
  };
  mockedActions.updateSSOAuthorizationRoles = async payload => {
    const {
      callback,
      authorizationRoles
    } = payload;
    mockedActions.setSSOState({
      error: undefined,
      saving: true
    });
    await delay();
    mockedActions.setSSOState({
      authorizationRoles,
      error: undefined,
      saving: false
    });
    callback == null ? void 0 : callback(true);
  };
  return mockedActions;
});