import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { SecondaryAuthStrategy } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setPasskeysState = payload => {
    Object.assign(store.auth.passkeysState, payload);
  };
  const resetPasskeysState = () => {
    deepResetState(store, ['auth', 'passkeysState'], initialState);
  };
  const loadWebAuthnDevices = async () => {
    setPasskeysState({
      loading: true
    });
    try {
      const {
        devices
      } = await api.auth.getWebAuthnDevices();
      setPasskeysState({
        devices: devices,
        loading: false
      });
    } catch (e) {
      setPasskeysState({
        loading: false,
        error: errorHandler(e, null)
      });
    }
  };
  const deleteWebAuthnDevice = async _payload => {
    const {
      callback,
      deviceId
    } = _payload;
    setPasskeysState({
      loading: true
    });
    try {
      await api.auth.deleteWebAuthnDevice(deviceId);
      const devices = store.auth.passkeysState.devices;
      const newDevices = devices.filter(device => device.id !== deviceId);
      setPasskeysState({
        devices: newDevices,
        loading: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setPasskeysState({
        loading: false,
        error: errorHandler(e, null)
      });
      callback == null ? void 0 : callback(null, e);
    }
  };

  /**
   * @private
   */
  async function getPasskeysVendorPolicy(policy) {
    var _policy$secondaryAuth;
    return !!(policy != null && (_policy$secondaryAuth = policy.secondaryAuthStrategies) != null && _policy$secondaryAuth.some(({
      isActive,
      strategy
    }) => isActive && strategy === SecondaryAuthStrategy.Passkeys));
  }

  /**
   * @private
   */
  async function __shouldShowPromptPasskeys() {
    const {
      publicAuthStrategyPolicy
    } = store.auth.securityPolicyState;
    const {
      policy
    } = publicAuthStrategyPolicy;
    const isPasskeysEnabledByVendor = await getPasskeysVendorPolicy(policy);
    const isLoggedInWithPasskeys = localStorage.getItem('preferred-login-method') === 'Passkeys';
    const isMarkedDontShowAgainPrompt = localStorage.getItem('dont-show-again-prompt-passkeys') === 'true';
    const [showPasskeys] = await actions.getFeatureFlags(['show-passkeys-new']);
    if (!showPasskeys || !isPasskeysEnabledByVendor || isLoggedInWithPasskeys || isMarkedDontShowAgainPrompt) {
      return false;
    } else {
      const {
        devices
      } = await api.auth.getWebAuthnDevices();
      setPasskeysState({
        devices: devices != null ? devices : []
      });
      const numOfDevices = !(devices != null && devices.length) ? 0 : devices.length;
      return numOfDevices === 0;
    }
  }
  return {
    setPasskeysState,
    resetPasskeysState,
    loadWebAuthnDevices,
    deleteWebAuthnDevice,
    __shouldShowPromptPasskeys
  };
});