import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"];
import { deepResetState } from '../../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setSessionsPolicyState = state => {
    Object.assign(store.auth.sessionsPolicyState, state);
  };
  const resetSessionsPolicyState = () => {
    deepResetState(store, ['auth', 'sessionsPolicyState'], initialState);
  };
  const loadSessionsPolicy = async () => {
    setSessionsPolicyState({
      loading: true,
      error: null
    });
    try {
      const sessionsPolicy = await api.auth.getSessionConfigurations();
      setSessionsPolicyState({
        configurations: sessionsPolicy,
        loading: false
      });
    } catch (e) {
      actions.setSessionsState({
        error: e,
        loading: false
      });
    }
  };
  const createOrUpdateSessionsPolicy = async payload => {
    const {
        callback
      } = payload,
      body = _objectWithoutPropertiesLoose(payload, _excluded);
    try {
      setSessionsPolicyState({
        loading: true,
        error: null
      });
      await api.auth.createOrUpdateSessionConfigrations(body);
      await actions.loadSessionsPolicy();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSessionsPolicyState({
        error: e,
        loading: false
      });
    }
  };
  return {
    setSessionsPolicyState,
    resetSessionsPolicyState,
    loadSessionsPolicy,
    createOrUpdateSessionsPolicy
  };
});