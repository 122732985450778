import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback", "samlVendor"];
import { SamlVendors } from '../interfaces';
import { deepResetState, errorHandler, omitProps, readFileAsText } from '../../../helpers';
import { initialState } from '../state';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /**
   * @private
   */
  const __getAuthorizationRoles = async () => {
    try {
      // @ts-ignore // TODO: fix type in rest-api
      const data = await api.auth.getSamlRoles();
      const groupsData = await api.auth.getSamlRolesGroups();
      setSSOState({
        authorizationRoles: data.roleIds,
        rolesGroups: groupsData,
        error: undefined
      });
    } catch (e) {
      setSSOState({
        error: errorHandler(e)
      });
    }
  };

  /**
   * @private
   */
  const __updateRolesGroups = async groups => {
    try {
      await Promise.all(groups.map(group => api.auth.updateSamlGroup({
        id: group.id,
        roleIds: group.roleIds,
        group: group.group
      })));
    } catch (e) {
      setSSOState({
        error: errorHandler(e)
      });
    }
  };
  const setSSOState = state => {
    Object.assign(store.auth.ssoState, state);
  };
  const resetSSOState = () => {
    deepResetState(store, ['auth', 'ssoState'], initialState);
  };
  const setSSOLoader = payload => {
    Object.assign(store.auth.ssoState, {
      loaders: _extends({}, store.auth.ssoState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setSSOError = payload => {
    Object.assign(store.auth.ssoState, {
      errors: _extends({}, store.auth.ssoState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.ssoState.loaders, {
        [payload.key]: false
      })
    });
  };
  const loadSSOConfigurations = async () => {
    try {
      var _samlMetadata$configu, _samlMetadata$configu2;
      setSSOState({
        loading: true
      });
      const samlConfiguration = await api.auth.getSamlConfiguration();
      const oidcConfiguration = await api.auth.getOidcConfiguration();
      const samlMetadata = await api.metadata.getSamlMetadata();
      samlConfiguration.acsUrl = samlMetadata == null ? void 0 : (_samlMetadata$configu = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu.acsUrl;
      samlConfiguration.spEntityId = samlMetadata == null ? void 0 : (_samlMetadata$configu2 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu2.spEntityId;
      samlConfiguration.isSamlActive = samlMetadata == null ? void 0 : samlMetadata.isActive;
      setSSOState({
        samlConfiguration,
        oidcConfiguration,
        loading: false,
        firstLoad: false
      });
    } catch (e) {
      setSSOState({
        error: errorHandler(e),
        loading: false
      });
    }
  };
  const loadSSOAuthorizationRoles = async () => {
    try {
      // @ts-ignore // TODO: fix type in rest-api
      const data = await api.auth.getSamlRoles();
      const groupsData = await api.auth.getSamlRolesGroups();
      setSSOState({
        authorizationRoles: data.roleIds,
        rolesGroups: groupsData,
        error: undefined
      });
    } catch (e) {
      setSSOState({
        error: errorHandler(e)
      });
    }
  };
  const saveSSOConfigurations = async payload => {
    const {
        callback,
        samlVendor
      } = payload,
      newSamlConfiguration = _objectWithoutPropertiesLoose(payload, _excluded);
    const oldSamlConfiguration = store.auth.ssoState.samlConfiguration;
    const samlConfiguration = _extends({}, oldSamlConfiguration, newSamlConfiguration);
    let loaderKey = 'saving';
    if ((samlConfiguration == null ? void 0 : samlConfiguration.enabled) !== (oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.enabled)) {
      loaderKey = 'loading';
    }
    try {
      const firstTimeConfigure = !(samlConfiguration != null && samlConfiguration.domain);
      if (firstTimeConfigure) {
        setSSOState({
          samlConfiguration: _extends({}, oldSamlConfiguration, samlConfiguration)
        });
        return;
      } else {
        setSSOState({
          error: undefined,
          [loaderKey]: true
        });
      }
      if (payload.type === SamlVendors.Saml) {
        var _samlMetadata$configu3, _samlMetadata$configu4;
        const samlMetadata = await api.metadata.getSamlMetadata();
        samlConfiguration.acsUrl = samlMetadata == null ? void 0 : (_samlMetadata$configu3 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu3.acsUrl;
        samlConfiguration.spEntityId = samlMetadata == null ? void 0 : (_samlMetadata$configu4 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu4.spEntityId;
      }
      const updateSamlConfiguration = omitProps(samlConfiguration, ['validated', 'generatedVerification', 'createdAt', 'updatedAt']);
      if ((oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.domain) !== (updateSamlConfiguration == null ? void 0 : updateSamlConfiguration.domain)) {
        updateSamlConfiguration.ssoEndpoint = '';
        updateSamlConfiguration.publicCertificate = '';
        updateSamlConfiguration.signRequest = false;
      }
      updateSamlConfiguration.type = samlVendor == null ? void 0 : samlVendor.toLowerCase();
      const newSamlConfiguration = await api.auth.updateSamlConfiguration(updateSamlConfiguration);
      newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
      setSSOState({
        samlConfiguration: newSamlConfiguration,
        error: undefined,
        [loaderKey]: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        samlConfiguration: oldSamlConfiguration,
        error: errorHandler(e),
        [loaderKey]: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const saveSSOConfigurationsFile = async configFile => {
    const oldSamlConfiguration = store.auth.ssoState.samlConfiguration;
    const loaderKey = 'saving';
    setSSOState({
      error: undefined,
      [loaderKey]: true
    });
    try {
      const metadata = await readFileAsText(configFile[0]);
      const newSamlConfiguration = await api.auth.updateSamlVendorMetadata({
        metadata
      });
      newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
      setSSOState({
        samlConfiguration: newSamlConfiguration,
        error: undefined,
        [loaderKey]: false
      });
    } catch (e) {
      setSSOState({
        samlConfiguration: oldSamlConfiguration,
        error: errorHandler(e),
        [loaderKey]: false
      });
    }
  };
  const saveSSOConfigurationsFileWithCallback = async payload => {
    const {
      configFile,
      callback
    } = payload;
    const oldSamlConfiguration = store.auth.ssoState.samlConfiguration;
    const loaderKey = 'saving';
    setSSOState({
      error: undefined,
      [loaderKey]: true
    });
    try {
      const metadata = await readFileAsText(configFile);
      const newSamlConfiguration = await api.auth.updateSamlVendorMetadata({
        metadata
      });
      newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
      setSSOState({
        samlConfiguration: newSamlConfiguration,
        error: undefined,
        [loaderKey]: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        samlConfiguration: oldSamlConfiguration,
        error: errorHandler(e),
        [loaderKey]: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const validateSSODomain = async payload => {
    const {
      callback
    } = payload != null ? payload : {};
    const samlConfiguration = store.auth.ssoState.samlConfiguration;
    if (!samlConfiguration) {
      const error = new Error('IllegalState: samlConfiguration is not defined');
      console.error(error);
      callback == null ? void 0 : callback(null, error);
      return;
    }
    try {
      setSSOState({
        error: undefined,
        saving: true
      });
      await api.auth.validateSamlDomain();
      setSSOState({
        samlConfiguration: _extends({}, samlConfiguration, {
          validated: true
        }),
        error: undefined,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        samlConfiguration: _extends({}, samlConfiguration, {
          validated: false
        }),
        error: errorHandler(e),
        saving: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const updateSSOAuthorizationRoles = async payload => {
    const {
      callback,
      authorizationRoles,
      groups
    } = payload;
    try {
      setSSOState({
        error: undefined,
        saving: true
      });
      await api.auth.updateSamlRoles({
        roleIds: authorizationRoles
      });
      if (groups) {
        await __updateRolesGroups(groups);
      }
      await __getAuthorizationRoles();
      setSSOState({
        error: undefined,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        error: errorHandler(e),
        saving: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const deleteSamlGroup = async payload => {
    const {
      id,
      callback
    } = payload;
    try {
      setSSOState({
        error: undefined,
        saving: true
      });
      await api.auth.deleteSamlGroup({
        id
      });
      await __getAuthorizationRoles();
      setSSOState({
        error: undefined,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        error: errorHandler(e),
        saving: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const createSamlGroup = async payload => {
    const {
      group,
      roleIds,
      callback
    } = payload;
    try {
      setSSOState({
        error: undefined,
        saving: true
      });
      await api.auth.createSamlGroup({
        group,
        roleIds
      });
      await __getAuthorizationRoles();
      setSSOState({
        error: undefined,
        saving: false
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setSSOState({
        error: errorHandler(e),
        saving: false
      });
      callback == null ? void 0 : callback(null, e);
    }
  };
  const oidcPostlogin = async payload => {
    const data = {
      code: payload.code,
      redirectUri: payload.redirectUri,
      RelayState: payload.state
    };
    try {
      setSSOState({
        loading: true
      });
      await api.auth.oidcPostLoginV2(data);
      await actions.__refreshToken();
      setSSOState({
        loading: false
      });
    } catch (e) {
      setSSOState({
        loading: false,
        error: errorHandler(e, 'Failed to authenticate'),
        firstLoad: false
      });
    }
  };
  return {
    //reducers,
    setSSOState,
    resetSSOState,
    setSSOLoader,
    setSSOError,
    //actions.v1
    loadSSOConfigurations,
    saveSSOConfigurations,
    saveSSOConfigurationsFile,
    saveSSOConfigurationsFileWithCallback,
    validateSSODomain,
    loadSSOAuthorizationRoles,
    updateSSOAuthorizationRoles,
    deleteSamlGroup,
    createSamlGroup,
    oidcPostlogin
  };
});