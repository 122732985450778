import { sessionsMock } from '../dummy';
import { buildSessionsActions } from '../../auth/SessionsState';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildSessionsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setSessionsState', 'resetSessionsState']);
  mockedActions.loadUserSessions = async () => {
    mockedActions.setSessionsState({
      loading: true,
      error: null
    });
    mockedActions.setSessionsState({
      sessions: sessionsMock,
      loading: false
    });
  };
  mockedActions.deleteUserSession = async payload => {
    const {
      id
    } = payload;
    mockedActions.setSessionsState({
      loading: true,
      error: null
    });
    const currentSessions = store.auth.sessionsState.sessions;
    const newSessions = currentSessions.filter(s => s.id !== id);
    mockedActions.setSessionsState({
      sessions: newSessions,
      loading: false
    });
  };
  mockedActions.deleteAllUserSessions = async () => {
    mockedActions.setSessionsState({
      loading: true,
      error: null
    });
    const newSessions = sessionsMock.filter(s => s.current);
    mockedActions.setSessionsState({
      sessions: newSessions,
      loading: false
    });
  };
  return mockedActions;
});