import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class InvoicesApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getSubscriptionInvoices = async () => {
      return this.get(urls.subscriptions.billing.invoices.v1);
    };

    this.getSubscriptionInvoice = async invoiceId => {
      return this.get(`${urls.subscriptions.billing.invoices.v1}/${invoiceId}`);
    };

    this.getSubscriptionInvoicePdf = async (invoiceId, outputFileName) => {
      return this.get(`${urls.subscriptions.billing.invoices.v1}/${invoiceId}/pdf`, {
        outputFileName
      }, {
        responseType: 'blob'
      });
    };
  }

}
;