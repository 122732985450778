export const fronteggHeaders = {
  frameWork: 'x-frontegg-framework',
  fronteggSdkVersion: 'x-frontegg-sdk',
  contentType: 'Content-Type',
  source: 'frontegg-source',
  scopedTenant: 'frontegg-scoped-tenant'
};
export let RequestSource;

(function (RequestSource) {
  RequestSource["AdminPortal"] = "admin-portal";
  RequestSource["LoginBox"] = "login-box";
})(RequestSource || (RequestSource = {}));

export let FronteggFrameworks;

(function (FronteggFrameworks) {
  FronteggFrameworks["Nextjs"] = "nextjs";
  FronteggFrameworks["React"] = "react";
  FronteggFrameworks["Angular"] = "angular";
  FronteggFrameworks["Vuejs"] = "vuejs";
  FronteggFrameworks["Flutter"] = "flutter";
  FronteggFrameworks["Ios"] = "ios";
  FronteggFrameworks["Android"] = "android";
  FronteggFrameworks["Vanillajs"] = "vanillajs";
})(FronteggFrameworks || (FronteggFrameworks = {}));

export let PaginationOrderEnum;

(function (PaginationOrderEnum) {
  PaginationOrderEnum["ASC"] = "ASC";
  PaginationOrderEnum["DESC"] = "DESC";
})(PaginationOrderEnum || (PaginationOrderEnum = {}));