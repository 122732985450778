import _extends from "@babel/runtime/helpers/esm/extends";
import { deepResetState, errorHandler } from '../../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setBillingPaymentMethodState = state => {
    Object.assign(store.subscriptions.billing.paymentMethod, state);
  };
  const resetBillingPaymentMethodState = () => {
    deepResetState(store, ['subscriptions', 'billing', 'paymentMethod'], initialState);
  };

  /** @private */
  const __setLoading = loading => {
    setBillingPaymentMethodState({
      loading
    });
  };

  /** @private */
  const __setError = error => {
    setBillingPaymentMethodState({
      error,
      loading: false,
      fetching: false
    });
  };
  const loadPaymentMethod = async () => {
    __setLoading(true);
    try {
      const paymentMethods = await api.subscriptions.getPaymentMethods();
      const paymentMethod = paymentMethods[0];
      setBillingPaymentMethodState({
        paymentMethod,
        loading: false,
        fetching: false
      });
    } catch (e) {
      __setError(errorHandler(e));
    }
  };
  const updatePaymentMethodBillingDetails = async payload => {
    __setLoading(true);
    const {
      id,
      email,
      address,
      callback
    } = payload;
    try {
      await api.subscriptions.updatePaymentMethodBillingDetails(id, _extends({
        email
      }, address));
      await loadPaymentMethod();
      callback == null ? void 0 : callback(true);
    } catch (e) {
      __setError(errorHandler(e));
      callback == null ? void 0 : callback(false, e);
    } finally {
      __setLoading(false);
    }
  };

  // TODO: refactor to remove redundant code
  const submitPaymentMethod = async () => {
    __setLoading(true);
  };
  // TODO: refactor to remove redundant code
  const submitPaymentMethodError = async error => {
    __setError(error);
  };
  // TODO: refactor to remove redundant code
  const submitPaymentMethodSuccess = async () => {
    return await loadPaymentMethod();
  };
  return {
    setBillingPaymentMethodState,
    resetBillingPaymentMethodState,
    loadPaymentMethod,
    submitPaymentMethod,
    submitPaymentMethodError,
    submitPaymentMethodSuccess,
    updatePaymentMethodBillingDetails
  };
});