import _extends from "@babel/runtime/helpers/esm/extends";
import { deepResetState } from '../../../helpers';
import { initialState } from './state';
export default ((store, api, sharedActions) => {
  const setAllAccountDialogsState = payload => {
    Object.assign(store.auth.allAccountsDialogsState, payload);
  };
  const resetAllAccountDialogsState = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState'], initialState);
  };

  // Create new account dialog
  const openCreateSubAccountDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.createSubAccountDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeCreateSubAccountDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'createSubAccountDialog'], initialState.createSubAccountDialog);
  };

  // Update account dialog
  const openUpdateSubAccountDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.updateSubAccountDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeUpdateSubAccountDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'updateSubAccountDialog'], initialState.updateSubAccountDialog);
  };

  // Enable sub account management dialog
  const openEnableSubAccountManagementDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.enableSubAccountManagementDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeEnableSubAccountManagementDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'enableSubAccountManagementDialog'], initialState.enableSubAccountManagementDialog);
  };

  // Enable sub account management dialog
  const openUpdateSubAccountHierarchySettingsDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.updateSubAccountHierarchySettingsDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeUpdateSubAccountHierarchySettingsDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'updateSubAccountHierarchySettingsDialog'], initialState.updateSubAccountHierarchySettingsDialog);
  };

  // Disable sub account management dialog
  const openDisableSubAccountManagementDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.disableSubAccountManagementDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeDisableSubAccountManagementDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'disableSubAccountManagementDialog'], initialState.disableSubAccountManagementDialog);
  };

  // Delete account dialog
  const openDeleteSubAccountDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.deleteSubAccountDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeDeleteSubAccountDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'deleteSubAccountDialog'], initialState.deleteSubAccountDialog);
  };

  // Add users to account dialog
  const openAddUsersToAccountDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.addUsersToAccountDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeAddUsersToAccountDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'addUsersToAccountDialog'], initialState.addUsersToAccountDialog);
  };

  // Delete user from account dialog
  const openDeleteUserFromAccountDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.deleteUserFromAccountDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeDeleteUserFromAccountDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'deleteUserFromAccountDialog'], initialState.deleteUserFromAccountDialog);
  };

  // Edit user roles dialog
  const openEditUserRolesDialog = payload => {
    Object.assign(store.auth.allAccountsDialogsState.editUserRolesDialog, _extends({}, payload, {
      open: true
    }));
  };
  const closeEditUserRolesDialog = () => {
    deepResetState(store, ['auth', 'allAccountsDialogsState', 'editUserRolesDialog'], initialState.editUserRolesDialog);
  };
  return {
    setAllAccountDialogsState,
    resetAllAccountDialogsState,
    openCreateSubAccountDialog,
    closeCreateSubAccountDialog,
    openUpdateSubAccountDialog,
    closeUpdateSubAccountDialog,
    openEnableSubAccountManagementDialog,
    closeEnableSubAccountManagementDialog,
    openUpdateSubAccountHierarchySettingsDialog,
    closeUpdateSubAccountHierarchySettingsDialog,
    openDisableSubAccountManagementDialog,
    closeDisableSubAccountManagementDialog,
    openDeleteSubAccountDialog,
    closeDeleteSubAccountDialog,
    openAddUsersToAccountDialog,
    closeAddUsersToAccountDialog,
    openDeleteUserFromAccountDialog,
    closeDeleteUserFromAccountDialog,
    openEditUserRolesDialog,
    closeEditUserRolesDialog
  };
});