import { FetchClient } from './FetchClient';
export class BaseApiClient {
  constructor(appName) {
    this.appName = void 0;
    this.fetchClient = void 0;

    this.get = async (url, params, opts) => {
      return this.fetchClient.get(url, params, opts);
    };

    this.post = async (url, body, opts) => {
      return this.fetchClient.post(url, body, opts);
    };

    this.patch = async (url, body, opts) => {
      return this.fetchClient.patch(url, body, opts);
    };

    this.put = async (url, body, opts) => {
      return this.fetchClient.put(url, body, opts);
    };

    this.delete = async (url, body, opts) => {
      return this.fetchClient.delete(url, body, opts);
    };

    this.postDownload = async (url, body, params, opts) => {
      return this.fetchClient.postDownload(url, body, params, opts);
    };

    this.extractHeadersFromOptions = (options = {}) => {
      return this.fetchClient.extractHeadersFromOptions(options);
    };

    this.appName = appName;
    this.fetchClient = new FetchClient(appName);
  }

}