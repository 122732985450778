export const getFlattenAppIds = listWithAppIds => {
  var _listWithAppIds$map$r;
  return (_listWithAppIds$map$r = listWithAppIds.map(({
    appIds
  }) => appIds).reduce((allAppIds, currentAppIds) => {
    currentAppIds == null ? void 0 : currentAppIds.forEach(item => {
      if (allAppIds && !allAppIds.includes(item)) {
        allAppIds.push(item);
      }
    });
    return allAppIds;
  }, [])) != null ? _listWithAppIds$map$r : [];
};