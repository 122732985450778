import { ContextHolder } from '../ContextHolder';
import { USE_CURRENT_USER_TENANTS_V1_ENDPOINT_FF } from './constants';
import { FeatureFlags } from '../feature-flags';
import { UsersApi } from '../users';
import { FRONTEGG_SEPARATE_TABS_BY_TENANT } from './constants';
;
export async function executeConditionalPromise({
  shouldLoad,
  action
}) {
  if (!shouldLoad) return;
  return await action();
}
export function setTabTenantInSessionStorage(tenantId) {
  if (!tenantId) {
    return;
  }

  sessionStorage.setItem(FRONTEGG_SEPARATE_TABS_BY_TENANT, tenantId);
}
export function removeTabTenantFromSessionStorage() {
  sessionStorage.removeItem(FRONTEGG_SEPARATE_TABS_BY_TENANT);
}
export function getTabTenantFromSessionStorage(appName) {
  if (!ContextHolder.for(appName).isSessionPerTenantEnabled()) {
    return null;
  }

  return sessionStorage.getItem(FRONTEGG_SEPARATE_TABS_BY_TENANT);
}
export function getCurrentUserTenantsFunction(appName) {
  const usersApi = new UsersApi(appName);
  const [useCurrentUserTenantsV1] = FeatureFlags.getFeatureFlags([USE_CURRENT_USER_TENANTS_V1_ENDPOINT_FF], appName);

  if (ContextHolder.for(appName).isSessionPerTenantEnabled()) {
    return usersApi.getCurrentUserTenantsV1;
  }

  return useCurrentUserTenantsV1 ? usersApi.getCurrentUserTenantsV1 : usersApi.getCurrentUserTenantsV3;
}