import { AcceptInvitationStep } from './interfaces';
import { initialState } from './state';
import { deepResetState, errorHandler } from '../../helpers';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setAcceptInvitationState = state => {
    Object.assign(store.auth.acceptInvitationState, state);
  };
  const resetAcceptInvitationState = () => {
    deepResetState(store, ['auth', 'acceptInvitationState'], initialState);
  };
  const acceptInvitation = async payload => {
    if (!payload.token || !payload.userId) {
      setAcceptInvitationState({
        error: undefined,
        step: AcceptInvitationStep.invalid
      });
      return;
    }
    try {
      setAcceptInvitationState({
        error: undefined,
        step: AcceptInvitationStep.pending
      });
      await api.auth.acceptInvitation(payload);
      setAcceptInvitationState({
        step: AcceptInvitationStep.success
      });
    } catch (e) {
      setAcceptInvitationState({
        step: AcceptInvitationStep.failed,
        error: errorHandler(e)
      });
    }
  };
  const acceptInvitationWithOTC = async payload => {
    if (!payload.token || !payload.userId || !payload.code) {
      setAcceptInvitationState({
        error: undefined,
        step: AcceptInvitationStep.invalid
      });
      return;
    }
    try {
      setAcceptInvitationState({
        error: undefined,
        step: AcceptInvitationStep.pending
      });
      await api.auth.acceptInvitationWithOTC(payload);
      setAcceptInvitationState({
        step: AcceptInvitationStep.success
      });
    } catch (e) {
      setAcceptInvitationState({
        step: AcceptInvitationStep.validate,
        error: errorHandler(e)
      });
    }
  };
  return {
    setAcceptInvitationState,
    resetAcceptInvitationState,
    acceptInvitation,
    acceptInvitationWithOTC
  };
});