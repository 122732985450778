import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["enabled", "name"];
import { snapshot, subscribe } from '../valtio';
const DEVTOOLS = Symbol();
/**
 * devtools
 *
 * This is to connect with [Redux DevTools Extension](https://github.com/reduxjs/redux-devtools).
 * Limitation: Only plain objects/values are supported.
 *
 * @example
 * import { devtools } from 'valtio/utils'
 * const state = proxy({ count: 0, text: 'hello' })
 * const unsub = devtools(state, { name: 'state name', enabled: true })
 */
export function devtools(proxyObject, options) {
  if (typeof options === 'string') {
    // console.warn('string name option is deprecated, use { name }. https://github.com/pmndrs/valtio/pull/400');
    options = {
      name: options
    };
  }
  const _ref = options || {},
    {
      enabled,
      name = ''
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);

  // @ts-ignore
  let extension;
  try {
    // @ts-ignore
    extension = enabled && window.__REDUX_DEVTOOLS_EXTENSION__;
  } catch {
    // ignored
  }
  if (!extension) {
    // @ts-ignore
    // TODO: console.warn('[Warning] Please install/enable Redux devtools extension');
    return;
  }
  let isTimeTraveling = false;
  const devtools = extension.connect(_extends({
    name
  }, rest));
  const unsub1 = subscribe(proxyObject, ops => {
    const action = ops.filter(([_, path]) => path[0] !== DEVTOOLS).map(([op, path]) => `${op}:${path.map(String).join('.')}`).join(', ');
    if (!action) {
      return;
    }
    if (isTimeTraveling) {
      isTimeTraveling = false;
    } else {
      const snapWithoutDevtools = Object.assign({}, snapshot(proxyObject));
      delete snapWithoutDevtools[DEVTOOLS];
      devtools.send({
        type: action,
        updatedAt: new Date().toLocaleString()
      }, snapWithoutDevtools);
    }
  });
  const unsub2 = devtools.subscribe(message => {
    var _message$payload3, _message$payload4;
    if (message.type === 'ACTION' && message.payload) {
      try {
        Object.assign(proxyObject, JSON.parse(message.payload));
      } catch (e) {
        console.error('please dispatch a serializable value that JSON.parse() and proxy() support\n', e);
      }
    }
    if (message.type === 'DISPATCH' && message.state) {
      var _message$payload, _message$payload2;
      if (((_message$payload = message.payload) == null ? void 0 : _message$payload.type) === 'JUMP_TO_ACTION' || ((_message$payload2 = message.payload) == null ? void 0 : _message$payload2.type) === 'JUMP_TO_STATE') {
        isTimeTraveling = true;
        const state = JSON.parse(message.state);
        Object.assign(proxyObject, state);
      }
      proxyObject[DEVTOOLS] = message;
    } else if (message.type === 'DISPATCH' && ((_message$payload3 = message.payload) == null ? void 0 : _message$payload3.type) === 'COMMIT') {
      devtools.init(snapshot(proxyObject));
    } else if (message.type === 'DISPATCH' && ((_message$payload4 = message.payload) == null ? void 0 : _message$payload4.type) === 'IMPORT_STATE') {
      var _message$payload$next, _message$payload$next2;
      const actions = (_message$payload$next = message.payload.nextLiftedState) == null ? void 0 : _message$payload$next.actionsById;
      const computedStates = ((_message$payload$next2 = message.payload.nextLiftedState) == null ? void 0 : _message$payload$next2.computedStates) || [];
      isTimeTraveling = true;
      computedStates.forEach(({
        state
      }, index) => {
        const action = actions[index] || 'No action found';
        Object.assign(proxyObject, state);
        if (index === 0) {
          devtools.init(snapshot(proxyObject));
        } else {
          devtools.send(action, snapshot(proxyObject));
        }
      });
    }
  });
  devtools.init(snapshot(proxyObject));
  return () => {
    unsub1();
    unsub2 == null ? void 0 : unsub2();
  };
}