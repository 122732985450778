import _extends from "@babel/runtime/helpers/esm/extends";
import { errorHandler } from '../../../helpers';
import { TeamStateKeys } from '../interfaces';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;

  /** @private */
  const __getInvitationLinkConfig = async () => {
    actions.setTeamError({
      key: TeamStateKeys.CONFIG_TOKEN_LINK,
      value: false
    });
    try {
      const invitationLinkConfig = await api.teams.getInviteLinkConfiguration();
      actions.setTeamState({
        inviteTokenState: _extends({}, invitationLinkConfig)
      });
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.CONFIG_TOKEN_LINK,
        value: errorHandler(e)
      });
    }
  };
  const getInvitationLink = async () => {
    actions.setTeamError({
      key: TeamStateKeys.GET_TOKEN_LINK,
      value: false
    });
    try {
      await __getInvitationLinkConfig();
      const data = await api.teams.getInviteUserLink();
      const {
        inviteTokenState
      } = store.auth.teamState;
      actions.setTeamState({
        inviteTokenState: _extends({}, inviteTokenState, data)
      });
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.GET_TOKEN_LINK,
        value: errorHandler(e)
      });
    }
  };
  const createInvitationLink = async payload => {
    const {
      callback
    } = payload;
    actions.setTeamError({
      key: TeamStateKeys.CREATE_TOKEN_LINK,
      value: false
    });
    const {
      inviteTokenState
    } = store.auth.teamState;
    try {
      const data = await api.teams.createInviteUserLink({
        expiresInMinutes: 43200
      });
      actions.setTeamState({
        inviteTokenState: _extends({}, inviteTokenState, data)
      });
      callback == null ? void 0 : callback(data.token);
    } catch (e) {
      callback == null ? void 0 : callback(null, e);
      actions.setTeamError({
        key: TeamStateKeys.CREATE_TOKEN_LINK,
        value: errorHandler(e)
      });
    }
  };
  const updateInvitationLink = async payload => {
    const {
      callback,
      expiresInMinutes,
      shouldSendEmail
    } = payload;
    actions.setTeamError({
      key: TeamStateKeys.UPDATE_TOKEN_LINK,
      value: false
    });
    try {
      const {
        inviteTokenState
      } = store.auth.teamState;
      const data = await api.teams.updateInviteUserLink({
        expiresInMinutes,
        shouldSendEmail
      });
      actions.setTeamState({
        inviteTokenState: _extends({}, inviteTokenState, data)
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      callback == null ? void 0 : callback(null, e);
      actions.setTeamError({
        key: TeamStateKeys.UPDATE_TOKEN_LINK,
        value: errorHandler(e)
      });
    }
  };
  const deleteInvitationLink = async payload => {
    const {
      callback
    } = payload != null ? payload : {};
    actions.setTeamError({
      key: TeamStateKeys.DELETE_TOKEN_LINK,
      value: false
    });
    try {
      await api.teams.deleteInviteUserLink();
      actions.setTeamState({
        inviteTokenState: undefined
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      actions.setTeamError({
        key: TeamStateKeys.DELETE_TOKEN_LINK,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false, e);
    }
  };
  return {
    getInvitationLink,
    createInvitationLink,
    updateInvitationLink,
    deleteInvitationLink
  };
});