import _extends from "@babel/runtime/helpers/esm/extends";
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class TeamsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getProfile = async params => {
      return this.get(`${urls.identity.users.v2}/me`, params != null ? params : {
        addRoles: true
      });
    };

    this.updateProfile = async body => {
      return this.put(`${urls.identity.users.v2}/me`, body);
    };

    this.updateProfileImage = async body => {
      return this.put(`${urls.team.profile.v1}/me/image/v1`, body, {
        responseType: 'plain',
        contentType: undefined
      });
    };

    this.changePassword = async body => {
      return this.post(`${urls.identity.users.v1}/passwords/change`, body);
    };

    this.loadUsers = async params => {
      var _params$sort, _params$sort$0$id, _params$sort2, _params$sort2$, _params$sort3, _params$sort3$;

      const filters = (params.filter || []).reduce((p, n) => _extends({}, p, {
        [n.id]: n.value
      }), {});
      const sorts = (_params$sort = params.sort) != null && _params$sort.length ? {
        sortBy: (_params$sort$0$id = (_params$sort2 = params.sort) == null ? void 0 : (_params$sort2$ = _params$sort2[0]) == null ? void 0 : _params$sort2$.id) != null ? _params$sort$0$id : 'name',
        sortDirection: (_params$sort3 = params.sort) != null && (_params$sort3$ = _params$sort3[0]) != null && _params$sort3$.desc ? 'desc' : 'asc'
      } : null;
      return this.get(urls.team.members.v1, _extends({
        pageOffset: params.pageOffset,
        pageSize: params.pageSize
      }, filters, sorts));
    };

    this.addUser = async body => {
      return this.post(urls.identity.users.v2, _extends({}, body, {
        provider: 'local'
      }));
    };

    this.deleteUser = async body => {
      return this.delete(`${urls.team.members.v1}/${body.userId}`);
    };

    this.updateUser = async body => {
      return this.put(urls.team.members.v1, body);
    };

    this.updateUserExpirationTime = async body => {
      const {
        userId,
        expirationInSeconds
      } = body;
      return this.put(`${urls.identity.users.temporary.v1}/${userId}`, {
        expirationInSeconds
      });
    };

    this.setPermanentUser = async userId => {
      return this.delete(`${urls.identity.users.temporary.v1}/${userId}`);
    };

    this.getTemporaryUserConfiguration = async () => {
      return this.get(urls.identity.users.temporary.configuration.v1);
    };

    this.createInviteUserLink = async body => {
      return this.post(urls.identity.tenants.invites.user.v1, _extends({}, body));
    };

    this.updateInviteUserLink = async body => {
      return this.patch(urls.identity.tenants.invites.user.v1, _extends({}, body));
    };

    this.deleteInviteUserLink = async () => {
      return this.delete(urls.identity.tenants.invites.user.v1);
    };

    this.getInviteUserLink = async () => {
      return this.get(urls.identity.tenants.invites.user.v1);
    };

    this.getInviteLinkConfiguration = async () => {
      return this.get(urls.identity.tenants.invites.configuration.v1);
    };

    this.loadAvailableRoles = async () => {
      return this.get(urls.team.roles.v1);
    };

    this.loadAvailablePermissions = async () => {
      return this.get(urls.identity.permissions.v1);
    };

    this.loadStats = async () => {
      return this.get(`${urls.team.stats.v1}/members`);
    };

    this.resendActivationLink = async body => {
      return this.post(`${urls.team.members.v1}/${body.userId}/resendActivationEmail`, {});
    };

    this.resendInvitationLink = async body => {
      return this.post(urls.identity.users.v2, _extends({}, body, {
        provider: 'local'
      }));
    };

    this.resendInvitationLinkToAllTenants = async body => {
      return this.post(urls.identity.users.invitation.resendAll.v1, _extends({}, body));
    };

    this.disableUser = async userId => {
      return this.post(`${urls.identity.tenants.users.v1}/${userId}/disable`);
    };

    this.enableUser = async userId => {
      return this.post(`${urls.identity.tenants.users.v1}/${userId}/enable`);
    };
  }

}
export default new TeamsApi('default');