import _extends from "@babel/runtime/helpers/esm/extends";
import { AllAccountsStateKeys } from './interfaces';
import { deepResetState, errorHandler } from '../../helpers';
import { initialState } from './state';
import { updateNodeSubAccountAccessType, appendChildrenToNode, getAccountsWithUsersCount, removeNodeFromTree, updateNodeName } from './helpers';
import { updateNodeIsReseller } from './helpers/updateNodeIsReseller';
export default ((store, api, sharedActions) => {
  const setAllAccountsLoader = payload => {
    Object.assign(store.auth.allAccountsState, {
      loaders: _extends({}, store.auth.allAccountsState.loaders, {
        [payload.key]: payload.value
      })
    });
  };
  const setAllAccountsError = payload => {
    Object.assign(store.auth.allAccountsState, {
      errors: _extends({}, store.auth.allAccountsState.errors, {
        [payload.key]: payload.value
      }),
      loaders: _extends({}, store.auth.allAccountsState.loaders, {
        [payload.key]: false
      })
    });
  };
  const setAllAccountsState = state => {
    Object.assign(store.auth.allAccountsState, state);
  };
  const setSelectedAccountState = payload => {
    Object.assign(store.auth.allAccountsState.selectedAccount, payload);
  };
  const resetAllAccountsState = () => {
    deepResetState(store, ['auth', 'allAccountsState'], initialState);
  };
  const clearSelectedAccount = () => {
    deepResetState(store, ['auth', 'allAccountsState', 'selectedAccount'], initialState.selectedAccount);
  };
  const selectAllAccountsState = () => {
    return store.auth.allAccountsState;
  };
  const loadAccounts = async payload => {
    const key = AllAccountsStateKeys.GET_ACCOUNTS;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      accountsQueryParams
    } = selectAllAccountsState();
    const {
      jwt,
      _limit,
      _offset
    } = payload;
    const searchSubTenantsQueryParams = {
      _offset: _offset != null ? _offset : accountsQueryParams._offset,
      _limit: _limit != null ? _limit : accountsQueryParams._limit,
      _subTenantId: payload == null ? void 0 : payload._subTenantId
    };
    try {
      const isSelectedAccountChange = !!jwt;
      if (isSelectedAccountChange) {
        var _payload$_subTenantId;
        const {
          selectedAccount
        } = selectAllAccountsState();
        const {
          items,
          _links
        } = await api.tenants.searchSubTenants(searchSubTenantsQueryParams, {
          jwt
        });
        const tenantsUsersCountArray = await api.tenants.getTenantsUsersCount({
          tenantIds: items.map(tenant => tenant.tenantId)
        }, {
          jwt
        });
        const accountsWithUsersCount = getAccountsWithUsersCount({
          items
        }, tenantsUsersCountArray);
        const updatedSingleAccountViewAccounts = appendChildrenToNode(selectedAccount.accounts ? selectedAccount.accounts : selectedAccount, (_payload$_subTenantId = payload == null ? void 0 : payload._subTenantId) != null ? _payload$_subTenantId : '', accountsWithUsersCount.map(account => ({
          name: account.name,
          tenantId: account.tenantId,
          numberOfUsers: account.numberOfUsers,
          createdAt: account.createdAt,
          metadata: account.metadata,
          children: [],
          loadMoreLink: _links == null ? void 0 : _links.next,
          isReseller: account.isReseller
        })));
        setSelectedAccountState({
          accounts: updatedSingleAccountViewAccounts
        });
      } else {
        var _payload$_subTenantId2;
        const {
          accounts
        } = selectAllAccountsState();
        const {
          items,
          _links
        } = await api.tenants.searchSubTenants(searchSubTenantsQueryParams);
        const tenantsUsersCountArray = await api.tenants.getTenantsUsersCount({
          tenantIds: items.map(i => i.tenantId)
        });
        const accountsWithUsersCount = getAccountsWithUsersCount({
          items
        }, tenantsUsersCountArray);
        const updatedAccounts = appendChildrenToNode(accounts, (_payload$_subTenantId2 = payload == null ? void 0 : payload._subTenantId) != null ? _payload$_subTenantId2 : '', accountsWithUsersCount.map(account => ({
          name: account.name,
          tenantId: account.tenantId,
          numberOfUsers: account.numberOfUsers,
          createdAt: account.createdAt,
          metadata: account.metadata,
          children: [],
          loadMoreLink: _links == null ? void 0 : _links.next,
          isReseller: account.isReseller
        })));
        setAllAccountsState({
          accounts: updatedAccounts
        });
      }
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const searchAccounts = async payload => {
    const {
      jwt
    } = payload;
    const key = AllAccountsStateKeys.SEARCH_ACCOUNTS;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      rootAccount
    } = selectAllAccountsState();
    try {
      if (payload != null && payload._subTenantId) {
        var _payload$_filter;
        const {
          items
        } = await api.tenants.searchSubTenants({
          _offset: 0,
          _limit: 50,
          _filter: (_payload$_filter = payload == null ? void 0 : payload._filter) != null ? _payload$_filter : '',
          _subTenantId: payload._subTenantId
        }, {
          jwt
        });
        setAllAccountsState({
          searchAccountsData: [...items, {
            name: rootAccount.name,
            tenantId: rootAccount.tenantId
          }]
        });
      }
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const updateSubAccountAccessForUser = async payload => {
    const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_ACCESS_FOR_USER;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      selectedAccount
    } = selectAllAccountsState();
    const {
      userId,
      allowAccess,
      jwt,
      callback
    } = payload;
    try {
      var _selectedAccount$user;
      await api.subTenants.updateSubAccountAccess(userId, {
        allowAccess
      }, {
        jwt
      });
      setSelectedAccountState({
        users: selectedAccount == null ? void 0 : (_selectedAccount$user = selectedAccount.users) == null ? void 0 : _selectedAccount$user.map(user => {
          if (user.id === userId) {
            return _extends({}, user, {
              subAccountAccessAllowed: allowAccess
            });
          }
          return user;
        })
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const createSubAccount = async payload => {
    const key = AllAccountsStateKeys.CREATE_SUB_ACCOUNT;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      name,
      parentTenantId,
      tenantId,
      jwt,
      callback
    } = payload;
    try {
      const isSelectedAccountChange = !!jwt;
      if (isSelectedAccountChange) {
        const {
          accounts,
          selectedAccount
        } = selectAllAccountsState();
        const createdSubTenant = await api.subTenants.createSubTenant({
          name,
          parentTenantId,
          tenantId
        }, {
          jwt
        });
        const updatedAllAccountPageAccounts = appendChildrenToNode(accounts, parentTenantId != null ? parentTenantId : '', [{
          name: createdSubTenant.name,
          tenantId: createdSubTenant.tenantId,
          numberOfUsers: 0,
          createdAt: new Date()
        }]);
        const updatedSingleAccountViewAccounts = appendChildrenToNode(_extends({
          name: '',
          tenantId: ''
        }, selectedAccount == null ? void 0 : selectedAccount.accounts), parentTenantId != null ? parentTenantId : '', [{
          name: createdSubTenant.name,
          tenantId: createdSubTenant.tenantId,
          numberOfUsers: 0,
          createdAt: new Date()
        }]);
        setAllAccountsState({
          accounts: updatedAllAccountPageAccounts
        });
        setSelectedAccountState({
          accounts: updatedSingleAccountViewAccounts
        });
      } else {
        const {
          accounts
        } = selectAllAccountsState();
        const createdSubTenant = await api.subTenants.createSubTenant({
          name,
          tenantId,
          parentTenantId
        });
        const updatedAccounts = appendChildrenToNode(accounts, parentTenantId != null ? parentTenantId : '', [{
          name: createdSubTenant.name,
          tenantId: createdSubTenant.tenantId,
          numberOfUsers: 0,
          createdAt: new Date()
        }]);
        setAllAccountsState({
          accounts: updatedAccounts
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const updateSubAccount = async payload => {
    // TODO: not implemented in @frontegg/redux-store
  };
  const deleteSubAccount = async payload => {
    const key = AllAccountsStateKeys.DELETE_SUB_ACCOUNT;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      jwt,
      tenantId,
      callback
    } = payload;
    try {
      const isSelectedAccountChange = !!jwt;
      if (isSelectedAccountChange) {
        const {
          selectedAccount,
          accounts
        } = selectAllAccountsState();
        await api.subTenants.deleteSubTenant(tenantId, {
          jwt
        });
        const udpatedAccounts = removeNodeFromTree(accounts, tenantId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const updatedAccountsForSelectedAccount = removeNodeFromTree(selectedAccount.accounts, tenantId);
        setAllAccountsState({
          accounts: udpatedAccounts
        });
        setSelectedAccountState({
          accounts: updatedAccountsForSelectedAccount
        });
      } else {
        const {
          accounts
        } = selectAllAccountsState();
        await api.subTenants.deleteSubTenant(tenantId);
        const udpatedAccounts = removeNodeFromTree(accounts, tenantId);
        setAllAccountsState({
          accounts: udpatedAccounts
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const updateSubAccountHierarchySettings = async payload => {
    const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_HIERARCHY_SETTINGS;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      jwt,
      callback,
      tenantId,
      subAccountAccessType
    } = payload;
    if (!subAccountAccessType) {
      return;
    }
    try {
      const isSelectedAccountChange = !!jwt;
      if (isSelectedAccountChange) {
        const {
          selectedAccount,
          accounts
        } = selectAllAccountsState();
        await api.subTenants.updateSubTenantHierarchySettings({
          tenantId
        }, {
          subAccountAccessType
        }, {
          jwt
        });
        const updatedAccounts = updateNodeSubAccountAccessType(selectedAccount.accounts, tenantId, subAccountAccessType);
        setSelectedAccountState({
          subAccountAccessType,
          accounts: updatedAccounts
        });
        const updatedAccountsForAllAccountsPage = updateNodeSubAccountAccessType(accounts, tenantId, subAccountAccessType);
        setAllAccountsState({
          accounts: updatedAccountsForAllAccountsPage
        });
      } else {
        const {
          accounts
        } = selectAllAccountsState();
        await api.subTenants.updateSubTenantHierarchySettings({
          tenantId
        }, {
          subAccountAccessType
        });
        const updatedAccounts = updateNodeSubAccountAccessType(accounts, tenantId, subAccountAccessType);
        setAllAccountsState({
          accounts: updatedAccounts
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const updateSubAccountSettings = async payload => {
    const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_HIERARCHY_SETTINGS;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      jwt,
      name,
      callback,
      tenantId
    } = payload;
    try {
      const isSelectedAccountChange = !!jwt;
      if (isSelectedAccountChange) {
        const {
          selectedAccount,
          accounts
        } = selectAllAccountsState();
        await api.subTenants.updateSubTenant({
          tenantId
        }, {
          name
        }, {
          jwt
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const udpatedAccs = updateNodeName(selectedAccount.accounts, tenantId, name);
        setSelectedAccountState({
          name,
          accounts: udpatedAccs
        });
        const udpatedAccsForAllAccountsPage = updateNodeName(accounts, tenantId, name);
        setAllAccountsState({
          accounts: udpatedAccsForAllAccountsPage
        });
      } else {
        const {
          accounts
        } = selectAllAccountsState();
        await api.subTenants.updateSubTenant({
          tenantId
        }, {
          name
        });
        const udpatedAccs = updateNodeName(accounts, tenantId, name);
        setAllAccountsState({
          accounts: udpatedAccs
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const updateSubAccountManagement = async payload => {
    const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_MANAGEMENT;
    sharedActions.setAllAccountsLoader({
      key,
      value: true
    });
    const {
      jwt,
      isReseller,
      callback,
      tenantId,
      shouldUpdateSelectedAccount
    } = payload;
    const isChildUpdated = !!jwt;
    try {
      if (shouldUpdateSelectedAccount) {
        const {
          selectedAccount,
          accounts
        } = store.auth.allAccountsState;
        await api.subTenants.updateSubTenantManagement({
          tenantId
        }, {
          isReseller
        }, jwt ? {
          jwt
        } : undefined);
        const updatedAccs = updateNodeIsReseller(selectedAccount.accounts, tenantId, isReseller != null ? isReseller : false);
        setSelectedAccountState({
          accounts: updatedAccs
        });
        if (!isChildUpdated) {
          setSelectedAccountState({
            isReseller
          });
        }
        const udpatedAccsForAllAccountsPage = updateNodeIsReseller(accounts, tenantId, isReseller != null ? isReseller : false);
        setAllAccountsState({
          accounts: udpatedAccsForAllAccountsPage
        });
      } else {
        const {
          accounts
        } = store.auth.allAccountsState;
        api.subTenants.updateSubTenantManagement({
          tenantId
        }, {
          isReseller
        }, jwt ? {
          jwt
        } : undefined);
        const udpatedAccs = updateNodeIsReseller(accounts, tenantId, isReseller != null ? isReseller : false);
        setAllAccountsState({
          accounts: udpatedAccs
        });
      }
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const addUsersToAccount = async payload => {
    const {
      users,
      tenantId,
      jwt,
      callback,
      allowSubAccountAccess = false,
      roleIds
    } = payload;
    const key = AllAccountsStateKeys.ADD_USERS_TO_ACCOUNT;
    try {
      setAllAccountsLoader({
        key,
        value: true
      });
      await api.subTenants.addUsersToSubTenant({
        users,
        tenantId,
        allowSubAccountAccess,
        roleIds
      }, {
        jwt
      });
      await getAccountUsers({
        jwt,
        _tenantId: tenantId
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const getAccountUsers = async payload => {
    var _ref, _payload$_offset, _allAccountsState$sel, _allAccountsState$sel2, _ref2, _payload$_limit, _allAccountsState$sel3, _allAccountsState$sel4;
    const {
      jwt,
      _tenantId,
      _email
    } = payload;
    const key = AllAccountsStateKeys.GET_ACCOUNT_USERS;
    setAllAccountsLoader({
      key,
      value: true
    });
    const allAccountsState = selectAllAccountsState();
    const _offset = (_ref = (_payload$_offset = payload == null ? void 0 : payload._offset) != null ? _payload$_offset : allAccountsState == null ? void 0 : (_allAccountsState$sel = allAccountsState.selectedAccount) == null ? void 0 : (_allAccountsState$sel2 = _allAccountsState$sel.usersQueryParams) == null ? void 0 : _allAccountsState$sel2._offset) != null ? _ref : 0;
    const _limit = (_ref2 = (_payload$_limit = payload == null ? void 0 : payload._limit) != null ? _payload$_limit : allAccountsState == null ? void 0 : (_allAccountsState$sel3 = allAccountsState.selectedAccount) == null ? void 0 : (_allAccountsState$sel4 = _allAccountsState$sel3.usersQueryParams) == null ? void 0 : _allAccountsState$sel4._limit) != null ? _ref2 : 10;
    const getAccountUsersQueryParams = _extends({
      _offset,
      _limit,
      _tenantId,
      _includeSubTenants: false
    }, !!_email && {
      _email
    });
    try {
      const {
        items: usersItems,
        _metadata: {
          totalItems,
          totalPages
        }
      } = await api.users.getUsersV3(getAccountUsersQueryParams, {
        jwt
      });
      const selectedAccountStateObj = {
        totalUsersItems: totalItems,
        totalUsersPages: totalPages,
        usersQueryParams: _extends({
          _offset: _offset != null ? _offset : 0,
          _limit: _limit != null ? _limit : 10
        }, !!_email && {
          _email
        })
      };
      if (usersItems.length) {
        const usersIds = usersItems.map(user => user.id);
        const allRoles = await api.roles.getRoles();
        const usersRoles = await api.users.getUsersRoles({
          ids: usersIds
        }, {
          jwt
        });
        const usersWithRoles = usersItems.map(user => {
          var _usersRoles$find, _ref3;
          const userRolesIds = usersRoles == null ? void 0 : (_usersRoles$find = usersRoles.find(role => role.userId === user.id)) == null ? void 0 : _usersRoles$find.roleIds;
          return _extends({}, user, {
            roles: (_ref3 = userRolesIds == null ? void 0 : userRolesIds.map(roleId => {
              var _allRoles$find;
              return (_allRoles$find = allRoles == null ? void 0 : allRoles.find(role => role.id === roleId)) != null ? _allRoles$find : [];
            })) != null ? _ref3 : []
          });
        });
        setSelectedAccountState(_extends({
          users: usersWithRoles
        }, selectedAccountStateObj));
      } else {
        setSelectedAccountState(_extends({
          users: []
        }, selectedAccountStateObj));
      }
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const deleteUsersFromAccount = async payload => {
    const key = AllAccountsStateKeys.DELETE_USERS_FROM_ACCOUNT;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      userId,
      subTenants,
      jwt,
      callback
    } = payload;
    try {
      await api.subTenants.removeUserFromTenantAndSubTenants({
        userId,
        subTenants
      }, {
        jwt
      });
      await getAccountUsers({
        jwt,
        _tenantId: subTenants[0].tenantId
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const setUserRolesForSubAccount = async payload => {
    const {
      userId,
      subTenantsRoles,
      jwt,
      callback
    } = payload;
    const key = AllAccountsStateKeys.SET_USER_ROLES_FOR_SUB_ACCOUNT;
    try {
      setAllAccountsLoader({
        key,
        value: true
      });
      const {
        selectedAccount
      } = selectAllAccountsState();
      await api.subTenants.setUserRolesForSubTenants(userId, {
        subTenantsRoles
      }, {
        jwt
      });
      await getAccountUsers({
        jwt,
        _tenantId: selectedAccount.tenantId
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const getUserJwtForSubAccount = async payload => {
    const {
      tenantId,
      callback
    } = payload;
    const key = AllAccountsStateKeys.GET_USER_JWT;
    try {
      setAllAccountsLoader({
        key,
        value: true
      });
      const userJwt = await api.users.GetUserJwt({
        tenantId
      });
      setSelectedAccountState({
        userJwt,
        accessPermission: true
      });
      callback == null ? void 0 : callback(true);
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
      setSelectedAccountState({
        accessPermission: false
      });
      callback == null ? void 0 : callback(false);
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };

  /**
   * Used to set root account data.
   */
  const setRootAccountData = async () => {
    const key = AllAccountsStateKeys.SET_ROOT_ACCOUNT_DATA;
    setAllAccountsLoader({
      key,
      value: true
    });
    const {
      activeTenant
    } = store.auth.tenantsState;
    const {
      accounts
    } = selectAllAccountsState();
    if (!activeTenant) return;
    try {
      var _tenantUsersCount$;
      const tenantUsersCount = await api.tenants.getTenantsUsersCount({
        tenantIds: [activeTenant == null ? void 0 : activeTenant.tenantId]
      });
      const rootAccount = {
        name: activeTenant == null ? void 0 : activeTenant.name,
        tenantId: activeTenant == null ? void 0 : activeTenant.tenantId,
        numberOfUsers: (_tenantUsersCount$ = tenantUsersCount[0]) == null ? void 0 : _tenantUsersCount$.totalUsers,
        createdAt: activeTenant == null ? void 0 : activeTenant.createdAt
      };
      setAllAccountsState({
        rootAccount,
        accounts: _extends({}, accounts, rootAccount)
      });
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };

  /**
   * Used to set selected account data.
   * name, tenantId, users, parentsAccounts, numberOfUsers, subAccountsAmount.
   * @param payload
   */
  const setSelectedSubAccountData = async payload => {
    const {
      jwt
    } = payload;
    const key = AllAccountsStateKeys.SET_SELECTED_SUB_ACCOUNT_DATA;
    try {
      var _accountSettings$tena, _accountSettings$tena2, _accountSettings$tena3, _subAccountsAmount$, _accountSettings$tena4, _numberOfUsersArray$, _accountSettings$tena5;
      setAllAccountsLoader({
        key,
        value: true
      });

      // @ts-ignore // TODO: fix type in rest-api
      const accountSettings = await api.accountSettings.getSettings({
        jwt
      });
      const [numberOfUsersArray, parentsAccounts, subAccountsAmount] = await Promise.all([api.tenants.getTenantsUsersCount({
        tenantIds: [(_accountSettings$tena = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena : '']
      }, {
        jwt
      }), api.tenants.getParentTenants({
        jwt
      }), api.tenants.getHierarchyMetadata({
        _tenantIds: [(_accountSettings$tena2 = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena2 : '']
      }, {
        jwt
      })]);
      setSelectedAccountState({
        tenantId: (_accountSettings$tena3 = accountSettings.tenantId) != null ? _accountSettings$tena3 : '',
        name: accountSettings.name,
        numberOfUsersObject: numberOfUsersArray[0],
        parentsAccounts,
        numberOfSubAccounts: (_subAccountsAmount$ = subAccountsAmount[0]) == null ? void 0 : _subAccountsAmount$.totalSubTenants,
        accountSettings,
        accounts: {
          name: accountSettings.name,
          tenantId: (_accountSettings$tena4 = accountSettings.tenantId) != null ? _accountSettings$tena4 : '',
          numberOfUsers: (_numberOfUsersArray$ = numberOfUsersArray[0]) == null ? void 0 : _numberOfUsersArray$.totalUsers,
          //Not implemented on backend yet
          createdAt: undefined,
          children: []
        }
      });
      await getAccountUsers({
        jwt,
        _tenantId: (_accountSettings$tena5 = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena5 : ''
      });
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  const loadNumberOfUsersForSelectedSubAccount = async payload => {
    const {
      tenantId,
      jwt
    } = payload;
    const key = AllAccountsStateKeys.LOAD_NUMBER_OF_USERS_FOR_ACCOUNT;
    try {
      setAllAccountsLoader({
        key,
        value: true
      });
      const numberOfUsersArray = await api.tenants.getTenantsUsersCount({
        tenantIds: [tenantId]
      }, {
        jwt
      });
      setSelectedAccountState({
        numberOfUsersObject: numberOfUsersArray[0]
      });
    } catch (e) {
      setAllAccountsError({
        key,
        value: errorHandler(e)
      });
    } finally {
      setAllAccountsLoader({
        key,
        value: false
      });
    }
  };
  return {
    // reducers
    setAllAccountsLoader,
    setAllAccountsError,
    setAllAccountsState,
    setSelectedAccountState,
    resetAllAccountsState,
    clearSelectedAccount,
    // actions
    loadAccounts,
    searchAccounts,
    updateSubAccountAccessForUser,
    createSubAccount,
    updateSubAccount,
    deleteSubAccount,
    updateSubAccountSettings,
    updateSubAccountManagement,
    updateSubAccountHierarchySettings,
    addUsersToAccount,
    getAccountUsers,
    deleteUsersFromAccount,
    setUserRolesForSubAccount,
    getUserJwtForSubAccount,
    setRootAccountData,
    setSelectedSubAccountData,
    loadNumberOfUsersForSelectedSubAccount
  };
});