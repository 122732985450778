import _extends from "@babel/runtime/helpers/esm/extends";
import { mockActionsExpect } from '../../helpers';
import { buildInformationActions } from '../../../subscriptions/Billing/Information';
import { planResponseMock, subscriptionResponseMock } from '../dummy';
import { ProviderType } from '@frontegg/rest-api';
import { delay } from '../../../helpers';
export default ((store, api, actions) => {
  const originalActions = buildInformationActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setBillingInformationState', 'resetBillingInformationState']);
  mockedActions.loadBillingInformation = async () => {
    var _planResponseMock$0$p, _planResponseMock$0$p2;
    mockedActions.setBillingInformationState({
      loading: true
    });
    await delay(500);
    mockedActions.setBillingInformationState({
      loading: false,
      fetching: false,
      summary: {
        subscriptionId: subscriptionResponseMock.id,
        paymentMethodId: 'mockPaymentMethodId',
        tenantConfigurationId: 'mockTenantConfigurationId',
        providerType: ProviderType.Stripe,
        externallyManaged: false,
        currentPlanId: planResponseMock[0].id,
        defaultPlanId: planResponseMock[0].id
      },
      plan: _extends({}, planResponseMock[0], {
        price: (_planResponseMock$0$p = planResponseMock[0].price) == null ? void 0 : _planResponseMock$0$p.amount,
        currency: (_planResponseMock$0$p2 = planResponseMock[0].price) == null ? void 0 : _planResponseMock$0$p2.currency,
        recurringInterval: 'month'
      })
    });
  };
  return mockedActions;
});