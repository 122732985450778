import _extends from "@babel/runtime/helpers/esm/extends";
import buildConfigActions from './configActions.mocks';
import buildBillingActions from './billingActions-mocks';
import buildPlansActions from './plansActions.mocks';
import buildCheckoutActions from './checkoutActions.mocks';
import buildStripeActions from './stripeActions.mocks';
import buildVendorPublicConfigActions from './vendorPublicConfigActions.mocks';
export const buildSubscriptionsActions = (store, api, actions) => {
  const configActions = buildConfigActions(store, api, actions);
  const [billingActions, billingStateActions] = buildBillingActions(store, api, actions);
  const plansActions = buildPlansActions(store, api, actions);
  const checkoutActions = buildCheckoutActions(store, api, actions);
  const stripeActions = buildStripeActions(store, api, actions);
  const vendorPublicConfigActions = buildVendorPublicConfigActions(store, api, actions);
  return [_extends({}, configActions, billingActions, plansActions, checkoutActions, stripeActions, vendorPublicConfigActions), {
    config: configActions,
    billing: billingStateActions,
    plans: plansActions,
    checkout: checkoutActions,
    stripe: stripeActions,
    vendorPublicConfig: vendorPublicConfigActions
  }];
};