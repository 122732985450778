import _extends from "@babel/runtime/helpers/esm/extends";
// update node is reseller state - recursive function to update the isReseller state of a node in the tree
export const updateNodeIsReseller = (treeNode, id, isReseller) => {
  var _treeNode$children$ma, _treeNode$children;
  if (!treeNode) return {};
  if (treeNode.tenantId === id) {
    return _extends({}, treeNode, {
      isReseller: isReseller
    });
  }
  return _extends({}, treeNode, {
    children: (_treeNode$children$ma = treeNode == null ? void 0 : (_treeNode$children = treeNode.children) == null ? void 0 : _treeNode$children.map(child => updateNodeIsReseller(child, id, isReseller))) != null ? _treeNode$children$ma : []
  });
};