import { initialState } from './state';
import { deepResetState, errorHandler } from '../../../helpers';
import { ISubscriptionCancellationPolicy, ISubscriptionStatus } from '@frontegg/rest-api';
export default ((store, api, sharedActions) => {
  const actions = sharedActions;
  const setBillingSubscriptionState = state => {
    Object.assign(store.subscriptions.billing.subscription, state);
  };
  const resetBillingSubscriptionState = () => {
    deepResetState(store, ['subscriptions', 'billing', 'subscription'], initialState);
  };
  const __setError = error => {
    setBillingSubscriptionState({
      error,
      loading: false,
      fetching: false
    });
  };
  const __setLoading = loading => {
    setBillingSubscriptionState({
      loading
    });
  };
  const setCancellationLoading = loading => {
    Object.assign(store.subscriptions.billing.subscription.cancellation, {
      loading
    });
  };
  const setCancellationError = error => {
    Object.assign(store.subscriptions.billing.subscription.cancellation, {
      error
    });
  };
  const setRenewalLoading = loading => {
    Object.assign(store.subscriptions.billing.subscription.renewal, {
      loading
    });
  };
  const setRenewalError = error => {
    Object.assign(store.subscriptions.billing.subscription.renewal, {
      error
    });
  };
  const loadSubscription = async () => {
    __setLoading(true);
    try {
      const [subscription] = await api.subscriptions.getManagedSubscriptions();
      setBillingSubscriptionState({
        subscription,
        fetching: false,
        loading: false,
        error: null
      });
    } catch (e) {
      __setError(errorHandler(e));
    }
  };
  const loadSubscriptionTenant = async () => {
    var _store$auth$user;
    const tenantId = (_store$auth$user = store.auth.user) == null ? void 0 : _store$auth$user.tenantId;
    if (!tenantId) {
      __setError('TenantId is not defined');
      return;
    }
    await actions.loadSummaries(tenantId);
  };
  const cancelSubscription = async () => {
    const {
      subscription
    } = store.subscriptions.billing.subscription;
    if (!subscription) {
      setCancellationError('Subscription not found.');
      return;
    }
    if (subscription != null && subscription.externallyManaged) {
      setCancellationError('Billing is externally managed.');
      return;
    }
    const {
      id: subscriptionId,
      cancellation,
      status
    } = subscription || {};
    const isCancellable = !cancellation && status === ISubscriptionStatus.ACTIVE;
    if (isCancellable) {
      try {
        setCancellationLoading(true);
        await api.subscriptions.cancelManagedSubscription(subscriptionId);
        await loadSubscription();
        setCancellationLoading(false);
      } catch (e) {
        setCancellationError(errorHandler(e));
      }
    }
  };
  const renewSubscription = async () => {
    const {
      subscription
    } = store.subscriptions.billing.subscription;
    if (!subscription) {
      setRenewalError('Subscription not found.');
      return;
    }
    if (subscription != null && subscription.externallyManaged) {
      setRenewalError('Billing is externally managed');
      return;
    }
    const {
      id: subscriptionId,
      cancellation
    } = subscription || {};
    const renewable = (cancellation == null ? void 0 : cancellation.policy) === ISubscriptionCancellationPolicy.AT_PERIOD_END;
    if (renewable) {
      try {
        setRenewalLoading(true);
        await api.subscriptions.renewManagedSubscription(subscriptionId);
        await loadSubscription();
        setRenewalLoading(false);
      } catch (e) {
        setRenewalError(errorHandler(e));
      }
    }
  };
  return {
    // reducers
    setBillingSubscriptionState,
    resetBillingSubscriptionState,
    setCancellationLoading,
    setCancellationError,
    setRenewalLoading,
    setRenewalError,
    // actions
    loadSubscription,
    loadSubscriptionTenant,
    cancelSubscription,
    renewSubscription
  };
});