import { buildSecurityCenterActions } from '../../../auth/Security/SecurityCenterState';
import { SecurityCenterStateKeys } from '../../../auth';
import { delay } from '../../../helpers';
import { securityCenterBreachedPasswordUsersMock, securityCenterInactivityPasswordUsersMock, securityCenterInsightsMock, securityCenterRecommendationsMock, securityCenterUnenrolledMfaUsersMock } from '../../dummy';
import { mockActionsExpect } from '../../helpers';
export default ((store, api, actions) => {
  const originalActions = buildSecurityCenterActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setSecurityCenterState', 'setSecurityCenterStateError', 'setSecurityCenterStateLoader', 'resetSecurityCenterState']);
  mockedActions.loadRecommendations = async () => {
    const key = SecurityCenterStateKeys.RECOMMENDATIONS;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    mockedActions.setSecurityCenterState({
      recommendations: securityCenterRecommendationsMock.recommendations.items,
      score: securityCenterRecommendationsMock.scoring.score
    });
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  mockedActions.loadInsights = async () => {
    const key = SecurityCenterStateKeys.INSIGHTS;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    mockedActions.setSecurityCenterState({
      insights: securityCenterInsightsMock.insights.items
    });
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  mockedActions.loadBreachedPasswordUsers = async () => {
    const key = SecurityCenterStateKeys.BREACHED_PASSWORD_USERS_TABLE;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    mockedActions.setSecurityCenterState({
      breachedPasswordUsersTable: securityCenterBreachedPasswordUsersMock
    });
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  mockedActions.loadUnenrolledMfaUsers = async () => {
    const key = SecurityCenterStateKeys.UNENROLLED_MFA_USERS_TABLE;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    mockedActions.setSecurityCenterState({
      unenrolledMfaUsersTable: securityCenterUnenrolledMfaUsersMock
    });
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  mockedActions.loadInactiveUsers = async () => {
    const key = SecurityCenterStateKeys.INACTIVE_USERS_TABLE;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    mockedActions.setSecurityCenterState({
      inactiveUsersTable: securityCenterInactivityPasswordUsersMock
    });
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  mockedActions.sendResetBreachedPasswordEmail = async payload => {
    const {
      callback
    } = payload;
    const key = SecurityCenterStateKeys.SEND_BREACHED_PASSWORD_EMAIL;
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: true
    });
    await delay();
    callback == null ? void 0 : callback(true);
    mockedActions.setSecurityCenterStateLoader({
      key,
      value: false
    });
  };
  return mockedActions;
});