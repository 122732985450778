import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class SummariesApi extends BaseApiClient {
  constructor(...args) {
    super(...args);

    this.getSubscriptionSummaries = async tenantId => {
      return this.get(`${urls.subscriptions.billing.summaries.v1}/${tenantId}`);
    };
  }

}