import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["endpoint", "headerProps"];
import { urls } from '../constants';
import { BaseApiClient } from '../BaseApiClient';
export class AuditsApi extends BaseApiClient {
  constructor(appName) {
    super(appName);

    this.getAudits = async params => {
      return this.get(urls.audits.v1, params);
    };

    this.getAuditsStats = async params => {
      return this.get(`${urls.audits.v1}/stats`, params);
    };

    this.exportAudits = async params => {
      const {
        endpoint,
        headerProps
      } = params,
            restParams = _objectWithoutPropertiesLoose(params, _excluded);

      return this.postDownload(`${urls.audits.v1}/export/${endpoint}`, {
        properties: headerProps
      }, restParams);
    };
  }

}
export default new AuditsApi('default');