import _extends from "@babel/runtime/helpers/esm/extends";
import buildInformationActions from './informationActions.mocks';
import buildPaymentMethodActions from './paymentMethodActions.mocks';
import buildInvoicesActions from './invoicesActions.mocks';
import buildSubscriptionActions from './subscriptionActions.mocks';
export default ((store, api, actions) => {
  const informationActions = buildInformationActions(store, api, actions);
  const paymentMethodActions = buildPaymentMethodActions(store, api, actions);
  const invoicesActions = buildInvoicesActions(store, api, actions);
  const subscriptionActions = buildSubscriptionActions(store, api, actions);
  return [_extends({}, informationActions, paymentMethodActions, invoicesActions, subscriptionActions), {
    information: informationActions,
    invoices: invoicesActions,
    paymentMethod: paymentMethodActions,
    subscription: subscriptionActions
  }];
});