import _extends from "@babel/runtime/helpers/esm/extends";
import { buildAccountSettingsActions } from '../../auth/AccountSettingsState';
import { delay } from '../../helpers';
import { mockActionsExpect } from '../helpers';
export default ((store, api, actions) => {
  const originalActions = buildAccountSettingsActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setAccountSettingsState', 'resetAccountSettingsState']);
  mockedActions.saveAccountSettings = async payload => {
    var _payload$callback;
    mockedActions.setAccountSettingsState({
      loading: true
    });
    await delay();
    const accountSettingsState = store.auth.accountSettingsState;
    const {
      address,
      timezone,
      dateFormat,
      timeFormat,
      currency,
      logo
    } = accountSettingsState;
    const body = _extends({
      address,
      timezone,
      dateFormat,
      timeFormat,
      currency,
      logo
    }, payload);
    mockedActions.setAccountSettingsState(_extends({}, body, {
      loading: false
    }));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, body);
  };
  mockedActions.loadAccountSettings = async payload => {
    mockedActions.setAccountSettingsState({
      loading: !(payload != null && payload.silentLoading),
      error: null
    });
    await delay();
    mockedActions.setAccountSettingsState(_extends({
      address: 'Tel-aviv',
      timezone: 'Asia/Jerusalem',
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'HH:mm',
      currency: 'USD',
      loading: false
    }, payload));
  };
  return mockedActions;
});