export let SortByEnum;

(function (SortByEnum) {
  SortByEnum["createdAt"] = "createdAt";
  SortByEnum["name"] = "name";
  SortByEnum["email"] = "email";
  SortByEnum["id"] = "id";
  SortByEnum["verified"] = "verified";
  SortByEnum["isLocked"] = "isLocked";
  SortByEnum["provider"] = "provider";
  SortByEnum["tenantId"] = "tenantId";
})(SortByEnum || (SortByEnum = {}));

export let GetUsersFilterPreset;

(function (GetUsersFilterPreset) {
  GetUsersFilterPreset["MFA_UNENROLLED"] = "mfa-unenrolled";
  GetUsersFilterPreset["BREACHED_PASSWORDS"] = "breached-passwords";
  GetUsersFilterPreset["INACTIVE"] = "inactive";
})(GetUsersFilterPreset || (GetUsersFilterPreset = {}));

export const providersArray = ['local', 'saml', 'google', 'github', 'facebook', 'microsoft', 'scim2', 'slack'];