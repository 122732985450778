import { mockActionsExpect } from '../../helpers';
import { paymentMethodsMock } from '../dummy';
import { delay } from '../../../helpers';
import { buildPaymentMethodActions } from '../../../subscriptions/Billing/PaymentMethod';
export default ((store, api, actions) => {
  const originalActions = buildPaymentMethodActions(store, api, actions);
  const mockedActions = mockActionsExpect(originalActions, ['setBillingPaymentMethodState', 'resetBillingPaymentMethodState']);
  mockedActions.loadPaymentMethod = async () => {
    mockedActions.setBillingPaymentMethodState({
      loading: true
    });
    await delay(500);
    mockedActions.setBillingPaymentMethodState({
      paymentMethod: paymentMethodsMock[0],
      loading: false,
      fetching: false
    });
  };
  return mockedActions;
});